import { useQuery } from '@tanstack/react-query'

import { request } from './axios'

/** ************** MANAGER QUERIES *************** */

export const iterationQuery = id => ({
  queryKey: ['iteration', id],
  queryFn: async () => request({ url: `/manager/itrs/${id}`, method: 'get' }),
  staleTime: Infinity,
})

export const statusQuery = id => ({
  queryKey: ['status', id],
  queryFn: async () => request({ url: `/manager/itrs/${id}/fetch_status`, method: 'get' }),
  refetchInterval: data => (data.status === 'running-algo' ? 1000 : 0),
})

export const getOutputsQuery = id => ({
  queryKey: ['outputs', id],
  queryFn: async () => request({ url: `/manager/itrs/${id}/fetch_shift_output`, method: 'get' }),
})

export const postExecChecksStatusQuery = id => ({
  queryKey: ['postExecStatus', id],
  queryFn: async () => request({ url: `/manager/itrs/${id}/fetch_post_exec_status`, method: 'get' }),
  refetchInterval: data => ((data.task_status === 'running' || data.task_status === 'enqueued') ? 1000 : 0),
})

export const getPersonnelQuery = () => ({
  queryKey: ['personnel'],
  queryFn: async () => request({ url: '/manager/personnel/', method: 'get' }),
})

export const getActiveChainsQuery = () => ({
  queryKey: ['chains', 'active'],
  queryFn: async () => request({ url: '/manager/itrs/active', method: 'get' }),
})

export const getChainsQuery = () => ({
  queryKey: ['chains', 'all'],
  queryFn: async () => request({ url: '/manager/itrs/', method: 'get' }),
})

export const managerShiftExchangeQuery = () => ({
  queryKey: ['managerExchangeDashboard'],
  queryFn: async () => request({ url: 'manager/shift_exchange/fetch_approvals', method: 'get' }),
})

export const slotPrefsQuery = id => ({
  queryKey: ['slotPrefs', id],
  queryFn: async () => request({ url: `/manager/itrs/${id}/fetch_slot_prefs`, method: 'get' }),
  staleTime: Infinity,
})

export const crossShiftReqsQuery = id => ({
  queryKey: ['shiftClusters', id],
  queryFn: async () => request({ url: `/manager/itrs/${id}/fetch_shift_clusters`, method: 'get' }),
  staleTime: Infinity,
})

export const incompatibilitiesQuery = id => ({
  queryKey: ['incompatibilities', id],
  queryFn: async () => request({ url: `/manager/itrs/${id}/fetch_incomp`, method: 'get' }),
  staleTime: Infinity,
})

export const participantsQuery = id => ({
  queryKey: ['participants', id],
  queryFn: async () => request({ url: `/manager/itrs/${id}/fetch_participants`, method: 'get' }),
})

export const itrRolesQuery = id => ({
  queryKey: ['itrRoles', id],
  queryFn: async () => request({ url: `/manager/itrs/${id}/fetch_itr_roles`, method: 'get' }),
})

export const shiftsQuery = id => ({
  queryKey: ['shifts', id],
  queryFn: async () => request({ url: `/manager/itrs/${id}/fetch_shifts`, method: 'get' }),
  staleTime: Infinity,
})

export const slotSubsetsQuery = id => ({
  queryKey: ['slotSubsets', id],
  queryFn: async () => request({ url: `/manager/itrs/${id}/fetch_slot_subsets`, method: 'get' }),
  staleTime: Infinity,
})

export const getStatsManagerQuery = id => ({
  queryKey: ['stats', id],
  queryFn: async () => request({ url: `manager/personnel/user/${id}/fetch_stats`, method: 'get' }),
})

/** ************** USER QUERIES *************** */

export const allResultsQuery = id => ({
  queryKey: ['allResults', id],
  queryFn: async () => request({ url: `user/${id}/fetch_all_shift_outputs`, method: 'get' }),
})

export const userIterationsQuery = () => ({
  queryKey: ['itrs'],
  queryFn: async () => request({ url: '/user/itrs/active', method: 'get' }),
})

export const userPrefsQuery = id => ({
  queryKey: ['userPrefs', id],
  queryFn: async () => request({ url: `/user/${id}/fetch_user_prefs`, method: 'get' }),
})

export const getStatsQuery = () => ({
  queryKey: ['stats'],
  queryFn: async () => request({ url: '/user/fetch_stats', method: 'get' }),
})

export const resultsQuery = id => ({
  queryKey: ['results', id],
  queryFn: async () => request({ url: `user/${id}/fetch_shift_output`, method: 'get' }),
})

export const getIterationsHistoryQuery = () => ({
  queryKey: ['chains', 'all', 'user'],
  queryFn: async () => request({ url: '/user/itrs', method: 'get' }),
})

export const getNotificationsQuery = () => ({
  queryKey: ['notifications'],
  queryFn: async () => request({ url: '/fetch_notifications', method: 'get' }),
})

export const userExchangeDashboardQuery = () => ({
  queryKey: ['userExchangeDashboard'],
  queryFn: async () => request({ url: 'user/shift_exchange/fetch_user_exchange_dashboard', method: 'get' }),
})

/** ************** SHARED QUERIES *************** */

export const profileQuery = () => ({
  queryKey: ['profile'],
  queryFn: async () => request({ url: '/load_user', method: 'get' }),
})

export const loadUserQuery = () => ({
  queryKey: ['user'],
  queryFn: async () => request({ url: '/load_user', method: 'get' }),
  staleTime: Infinity,
})

/** ************** ADMIN QUERIES *************** */

export const adminQuery = () => ({
  queryKey: ['admin'],
  queryFn: async () => request({ url: '/admin/fetch_manager_main_dashboard', method: 'get' }),
})

export const adminGroupQuery = id => ({
  queryKey: ['group', id],
  queryFn: async () => request({ url: `/admin/group/${id}/fetch_group`, method: 'get' }),
})

export const adminStatsQuery = () => ({
  queryKey: ['adminStats'],
  queryFn: async () => request({ url: '/admin/fetch_summary_stats', method: 'get' }),
})

export const getOrgsQuery = () => ({
  queryKey: ['orgs'],
  queryFn: async () => request({ url: '/orgs', method: 'get' }),
})

export const getUsersQuery = () => ({
  queryKey: ['users'],
  queryFn: async () => request({ url: 'admin/users', method: 'get' }),
})

export const useFetchGroups = () => useQuery({
  queryKey: ['admin'],
  queryFn: () => request({ url: '/admin/fetch_manager_main_dashboard', method: 'get' }),
  select: data => data.orgs.flatMap(org => org.groups.map(group => ({
    id: group.id_group,
    name: `${group.name} - ${org.name}`,
  }))),
})
