import React from 'react'
import { NumericFormat } from 'react-number-format'

function InputField({
  value, dispatch, property, roleId, disabled, subsetId, subProperty,
}) {
  const dispatchUpdate = newPoints => {
    dispatch({
      type: 'EDIT_ROLE',
      payload: {
        id: roleId,
        property,
        value: newPoints,
        subsetId, // The ID of the subset within slot_subset_reqs to update
        subProperty, // max_req or min_req of a subsetSlot
      },
    })
  }

  const changeFieldPoints = increment => {
    let newPoints = Number(value)
    if (increment) {
      newPoints += 1
    } else {
      newPoints = Math.max(newPoints - 1, 0)
    }
    dispatchUpdate(newPoints)
  }

  return (
    <div className="flex items-center justify-center">
      <NumericFormat
        className="w-14 rounded text-center border-blue-500"
        value={value}
        isAllowed={values => {
          const { value } = values
          return (value >= 0)
        }}
        onValueChange={values => {
          dispatchUpdate(Number(values.value))
        }}
        disabled={disabled}
      />
      <div className="flex flex-col ml-2">
        <button
          className="text-gray font-bold py-0 px-0"
          onClick={() => changeFieldPoints(true)}
          disabled={disabled}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4 bg-blue-300 rounded "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 15.75l7.5-7.5 7.5 7.5"
            />
          </svg>
        </button>
        <button
          className="text-gray font-bold py-0 px-0 rounded mt-1"
          onClick={() => changeFieldPoints(false)}
          disabled={disabled || value <= 0}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-4 h-4 bg-blue-300 rounded"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}

export default InputField
