/* eslint-disable max-len */
import { useState } from 'react'

import CookiesBanner from '../../components/Banners/CookiesBanner'
import {
  CTA, FAQ, Features, Hero,
  InterestPopup,
  Press,
  PrimaryFeatures,
  Stats,
  Testimonials,
} from '../../components/Landing/index'

export default function Landing() {
  const [open, setOpen] = useState(false)
  const [cookiesAccepted, setCookiesAccepted] = useState(localStorage.getItem('cookiesAccepted') === 'true')

  const handleAcceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true')
    setCookiesAccepted(true)
  }

  const handleRejectCookies = () => {
    localStorage.setItem('cookiesAccepted', 'false')
    setCookiesAccepted(false)
  }

  return (
    <div style={{ scrollBehavior: 'smooth' }}>
      {!cookiesAccepted && <CookiesBanner onAccept={handleAcceptCookies} onReject={handleRejectCookies} />}
      <InterestPopup open={open} setOpen={setOpen} />
      <Hero />
      <div id="caracteristicas">
        <PrimaryFeatures open={open} setOpen={setOpen} />
        <Features />
      </div>
      <div id="stats">
        <Stats />
      </div>
      <div id="testimonials">
        <Testimonials />
      </div>
      <CTA />
      <div id="equipo">
        {/* <Team /> */}
      </div>
      <div id="press">
        <Press />
      </div>
      <div id="faq">
        <FAQ />
      </div>
    </div>
  )
}
