import { Navigate, useLoaderData } from 'react-router-dom'

import { statusQuery } from '../utils/queries'

export const statusLoader = queryClient => async ({ params }) => {
  const query = statusQuery(params.id)
  return queryClient.getQueryData(query.queryKey)
    ?? (await queryClient.fetchQuery(query))
}

function IterationStatus() {
  const { status } = useLoaderData()

  // prevent people from going to any route

  if (status === 'configuration') {
    return (
      <Navigate to="configuration" />
    )
  }
  if (status === 'running-algo') {
    return (
      <Navigate to="running" />
    )
  }
  if (status === 'failed-algo') {
    return (
      <Navigate to="failed" />
    )
  }
  if (status === 'verification' || status === 'published' || status === 'archived') {
    return (
      <Navigate to="verification" />
    )
  }
}

export default IterationStatus
