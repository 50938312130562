import { Disclosure } from '@headlessui/react'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, Outlet } from 'react-router-dom'

import { requireApproved, requireManager } from '../utils/checks'
import { getPersonnelQuery } from '../utils/queries'

export const personnelLoader = queryClient => async () => {
  const user = await requireManager(queryClient)
  await requireApproved(user)
  const query = getPersonnelQuery()
  return queryClient.getQueryData(query.queryKey)
    ?? (await queryClient.fetchQuery(query))
}

export default function TeamLayout() {
  const { data: personnel } = useQuery(getPersonnelQuery())

  const { t } = useTranslation()

  const tabs = [
    { name: t('manager.personnel'), href: 'personnel' },
    { name: t('manager.roles'), href: 'roles' },
  ]

  return (
    <Disclosure as="header" className="bg-white">
      {({ open }) => (
        <div className="lg:mt-16 px-2 sm:px-4">
          <div className="relative flex h-16 justify-between">
            <h3 className="text-2xl font-semibold leading-6 text-gray-900 my-4">{t('manager.team.teamManaging')}</h3>
          </div>

          <nav className="space-x-8 py-2" aria-label="Global">
            {tabs.map(tab => (
              <NavLink
                to={tab.href}
                key={tab.name}
                className="[&.active]:border-blue-500 [&.active]:text-blue-600 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
          <div><Outlet context={[personnel]} /></div>
        </div>
      )}
    </Disclosure>

  )
}
