import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { CalendarDaysIcon, MapPinIcon, UserIcon } from '@heroicons/react/24/outline'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AcceptIcon from '../../assets/AcceptIcon'
import ExchangeIcon from '../../assets/ExchangeIcon'
import Alert from '../../components/FormFeedback/Alert'
import { request } from '../../utils/axios'
import { managerShiftExchangeQuery } from '../../utils/queries'

export const managerShiftExchangeLoader = queryClient => async () => {
  const query = managerShiftExchangeQuery()
  return (
    queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
  )
}

function ManagerShiftExchange() {
  const { data } = useQuery(managerShiftExchangeQuery())
  const { completed, ongoing } = data.exchange_requests
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const decideResponse = useMutation({
    mutationFn: ({ id_response, approved }) => request({
      url: `/manager/shift_exchange/${id_response}/approve_manager`,
      method: 'patch',
      data: { approved },
    }),
    onSuccess: () => {
      queryClient.invalidateQueries(['managerExchangeDashboard'])
      setSuccess(t('manager.shiftExchange.successfulApproval'))
      setError(false)
    },
    onError: () => {
      setError(t('manager.shiftExchange.errorApproval'))
      setSuccess(false)
    },
  })

  return (
    <div className="space-y-6 md:mt-16 px-2 sm:px-4">
      <div className="flex flex-row justify-between">
        <h3 className="text-2xl font-semibold leading-6 text-gray-900 my-4">{t('generic.shiftExchange.title')}</h3>
      </div>
      {error ? <Alert text={error} /> : null}
      {success ? <Alert text={success} success /> : null}
      {/* ONGOING */}
      <Disclosure defaultOpen>
        {({ open }) => (
          <div>
            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-500 px-4 py-2 text-left text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
              <span>{t('generic.shiftExchange.ongoing')}</span>
              <ChevronUpIcon
                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-white`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-2 pb-2 text-sm text-gray-500">
              <div className="flex flex-row justify-around text-xl mt-5 text-black">
                <h3>{t('manager.shiftExchange.requests')}</h3>
                <h3>{t('manager.shiftExchange.responses')}</h3>
              </div>
              {
                ongoing.map(({
                  candidate_responses, reason, status, id_shift, user,
                  shift_name, start_time, id_request, ...rest_request
                }) => {
                  const startTime = new Date(start_time)
                  return (
                    <div className="flex flex-row mt-5 items-center pb-4 border-b border-gray-500">
                      <div className="border border-slate-200 shadow-md rounded-lg py-4 px-6 w-[45%] mr-4">
                        <div className="flex flex-row justify-between items-center">
                          <div>
                            <div className="flex flex-row items-center gap-1">
                              <CalendarDaysIcon className="h-4" />
                              <p>
                                {startTime.toLocaleString(undefined, {
                                  weekday: 'long',
                                  day: '2-digit',
                                  month: 'long',
                                  timeZone: 'GMT',
                                })}
                              </p>
                            </div>
                            <div className="flex flex-row items-center gap-1">
                              <MapPinIcon className="h-4" />
                              <p>
                                {' '}
                                {shift_name}
                              </p>
                            </div>
                          </div>
                        </div>
                        <p className="my-3">
                          <span className="font-semibold">{t('generic.justification')}</span>
                          {' '}
                          {reason}
                        </p>
                        <div className="flex flex-row items-center gap-1">
                          <UserIcon className="h-4" />
                          <p className="my-3">
                            {user.firstname}
                            {' '}
                            {user.lastname}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col gap-3">
                        {/* map over responses for each request */}
                        {candidate_responses.map(({
                          id_shift_trade, type, status, id_request, id_response,
                          user, shift_name, start_time, ...rest_response
                        }) => {
                          const startTime = new Date(start_time)
                          return (
                            <div className="flex flex-row items-center">
                              {
                                type === 'one_for_one' ? (
                                  <ExchangeIcon status={status} />
                                ) : (
                                  (status === 'rejected_doctor') || (status === 'rejected_manager')
                                    ? (<AcceptIcon status="rejected_right" />)
                                    : (<AcceptIcon status="pending_right" />)
                                )
                              }
                              <div className="border border-slate-200 shadow-md rounded-lg py-4 px-6 ml-4 my-auto">
                                <div className="flex flex-row justify-between items-center gap-20">
                                  {type === 'one_for_one' ? (
                                    <div className="flex flex-col justify-between items-start">
                                      <div className="flex flex-row items-center gap-1">
                                        <CalendarDaysIcon className="h-4" />
                                        <p>
                                          {startTime.toLocaleString(undefined, {
                                            weekday: 'long',
                                            day: '2-digit',
                                            month: 'long',
                                            timeZone: 'GMT',
                                          })}
                                        </p>
                                      </div>
                                      <div className="flex flex-row items-center gap-1">
                                        <MapPinIcon className="h-4" />
                                        <p>
                                          {' '}
                                          {shift_name}
                                        </p>
                                      </div>
                                      <div className="flex flex-row items-center gap-1">
                                        <UserIcon className="h-4" />
                                        <p className="my-3">
                                          {user.firstname}
                                          {' '}
                                          {user.lastname}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    null
                                  )}
                                  {type === 'one_for_zero' ? (
                                    <div className="flex flex-col justify-between items-start">
                                      <div className="flex flex-row items-center gap-1">
                                        <UserIcon className="h-4" />
                                        <p className="my-3">
                                          {user.firstname}
                                          {' '}
                                          {user.lastname}
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    null
                                  )}
                                  {status === 'pending_manager' ? (
                                    <div className="flex flex-col gap-2">
                                      <button
                                        type="button"
                                        className="border border-teal-400 inline-flex w-full my-auto justify-center items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-teal-400/50 sm:ml-3 sm:w-auto"
                                        onClick={() => decideResponse.mutate({ id_response, approved: true })}
                                      >
                                        {t('generic.approve')}
                                      </button>
                                      <button
                                        type="button"
                                        className="border border-red-400 inline-flex w-full my-auto justify-center items-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm hover:bg-red-400/50 sm:ml-3 sm:w-auto"
                                        onClick={() => decideResponse.mutate({ id_response, approved: false })}
                                      >
                                        {t('generic.reject')}
                                      </button>
                                    </div>
                                  ) : null}
                                </div>
                                {status === 'pending_doctor' ? (
                                  <p className="text-xs text-orange-400">{t('generic.shiftExchange.pendingDoctor')}</p>
                                ) : status === 'rejected_manager' ? (
                                  <p className="text-xs text-red-400">{t('generic.shiftExchange.rejectedManager')}</p>
                                ) : status === 'rejected_doctor' ? (
                                  <p className="text-xs text-red-400">{t('generic.shiftExchange.rejectedDoctor')}</p>
                                ) : status === 'canceled_system' ? (
                                  <p className="text-xs text-red-400">{t('generic.shiftExchange.canceledSystem')}</p>
                                ) : null}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  )
                })
              }
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      {/* Completed */}
      <Disclosure>
        {({ open }) => (
          <div>
            <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-500 px-4 py-2 text-left text-sm font-medium text-white hover:bg-blue-500 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
              <span>{t('generic.shiftExchange.completed')}</span>
              <ChevronUpIcon
                className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-white`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-2 pb-2 text-sm text-gray-500">
              <div className="flex flex-row justify-around text-xl mt-5 text-black">
                <h3>{t('manager.shiftExchange.requestorsNewShifts')}</h3>
                <h3>{t('manager.shiftExchange.respondersNewShifts')}</h3>
              </div>
              {/* User's completed requests */}
              {completed.map(({
                approved_response, id_shift, start_time, shift_name, user, ...rest
              }) => (
                approved_response.type == 'one_for_one'
                  ? (
                    <div className="justify-between mt-5 items-center grid grid-cols-[2fr_1fr_2fr]">
                      <div className="border border-slate-200 shadow-md rounded-lg py-3 px-4 mr-4">
                        <div className="flex flex-col justify-between items-start">
                          <div className="flex flex-row items-center gap-1">
                            <CalendarDaysIcon className="h-4" />
                            <p>
                              {new Date(approved_response.start_time).toLocaleString(undefined, {
                                weekday: 'long',
                                day: '2-digit',
                                month: 'long',
                                timeZone: 'GMT',
                              })}
                            </p>
                          </div>
                          <div className="flex flex-row items-center gap-1">
                            <MapPinIcon className="h-4" />
                            <p>
                              {' '}
                              {approved_response.shift_name}
                            </p>
                          </div>
                          <div className="flex flex-row items-center gap-1">
                            <UserIcon className="h-4" />
                            <p className="">
                              {user.firstname}
                              {' '}
                              {user.lastname}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mx-auto">
                        <ExchangeIcon status="approved" />
                      </div>
                      <div className="border border-slate-200 shadow-md rounded-lg py-3 px-4 mr-4">
                        <div className="flex flex-col justify-between items-start">
                          <div className="flex flex-row items-center gap-1">
                            <CalendarDaysIcon className="h-4" />
                            <p>
                              {new Date(start_time).toLocaleString(undefined, {
                                weekday: 'long',
                                day: '2-digit',
                                month: 'long',
                                timeZone: 'GMT',
                              })}
                            </p>
                          </div>
                          <div className="flex flex-row items-center gap-1">
                            <MapPinIcon className="h-4" />
                            <p>
                              {' '}
                              {shift_name}
                            </p>
                          </div>
                          <div className="flex flex-row items-center gap-1">
                            <UserIcon className="h-4" />
                            <p className="">
                              {approved_response.user.firstname}
                              {' '}
                              {approved_response.user.lastname}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="justify-between mt-5 items-center grid grid-cols-[2fr_1fr_2fr]">
                      <div className="border border-slate-400 border-dashed shadow-md rounded-lg py-3 px-4 mr-4 h-full flex items-center">
                        <div className="flex flex-row items-center gap-1">
                          <UserIcon className="h-4" />
                          <p className="">
                            {user.firstname}
                            {' '}
                            {user.lastname}
                          </p>
                        </div>
                      </div>
                      <div className="mx-auto">
                        <AcceptIcon status="completed_right" />
                      </div>
                      {/* shift that I gave away */}
                      <div className="border border-slate-200 shadow-md rounded-lg py-3 px-4 mr-4">
                        <div className="flex flex-col justify-between items-start">
                          <div className="flex flex-row items-center gap-1">
                            <CalendarDaysIcon className="h-4" />
                            <p>
                              {new Date(start_time).toLocaleString(undefined, {
                                weekday: 'long',
                                day: '2-digit',
                                month: 'long',
                                timeZone: 'GMT',
                              })}
                            </p>
                          </div>
                          <div className="flex flex-row items-center gap-1">
                            <MapPinIcon className="h-4" />
                            <p>
                              {' '}
                              {shift_name}
                            </p>
                          </div>
                          <div className="flex flex-row items-center gap-1">
                            <UserIcon className="h-4" />
                            <p className="">
                              {approved_response.user.firstname}
                              {' '}
                              {approved_response.user.lastname}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
              ))}
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </div>
  )
}

export default ManagerShiftExchange
