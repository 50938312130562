import { XMarkIcon } from '@heroicons/react/24/outline'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { request } from '../utils/axios'

// Utility function to calculate time ago
function timeAgo(dateStr, t) {
  const date = new Date(dateStr)
  const now = new Date()
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
  const yesterday = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)
  const oneWeekAgo = new Date(today)
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7)

  const diffTime = now - date
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  const hour = date.getHours()
  const minutes = date.getMinutes()
  const formattedHour = `${hour < 10 ? `0${hour}` : hour}:${minutes < 10 ? `0${minutes}` : minutes}`

  if (date >= today) {
    return t('user.notifications.todayAt', { time: formattedHour })
  } if (date >= yesterday) {
    return t('user.notifications.yesterdayAt', { time: formattedHour })
  } if (date >= oneWeekAgo) {
    return t('user.notifications.daysAgo', { days: diffDays })
  }
  const monthName = date.toLocaleString('default', { month: 'long' })
  return monthName
}

// Function to get user exchange
function getUserExchange(feed, id) {
  for (const feedItem of Object.values(feed)) {
    const request = feedItem.valid_requests.find(valid_request => valid_request.request_dict.id_request === id)
    if (request) {
      return request.request_dict.user.firstname
    }
  }
  return null
}

function NotificationContent({ setViewed, message, date }) {
  return (
    <>
      <div className="justify-between items-start col-span-1">
        <button onClick={setViewed} className="pt-2 pl-2">
          <XMarkIcon className="w-4 h-4" />
        </button>
      </div>
      <div className="flex-grow w-full px-2 pb-2 ml-2 pr-4 col-span-11">
        <p className="text-sm">{message}</p>
        <p className="text-xs text-gray-500">{date}</p>
      </div>
    </>
  )
}

export default function Notification({
  id_notification, type, hour, context, feed, refetchNotifications, itrData,
}) {
  const { t, i18n } = useTranslation()
  const currentLocale = i18n.language

  const userName = useMemo(() => (type === 'shift_exchange_request_created' ? getUserExchange(feed, context.id_request) : null), [type, feed, context.id_request])

  const itr_id = context?.itr?.id_itr ?? null

  const entry = useMemo(() => Object.entries(itrData.itrs).find(([key, value]) => value.id_itr === itr_id)?.[0] || null, [itrData, itr_id])

  const notifStatus = entry ? itrData.itrs[entry].status : null
  const exists = !(((notifStatus === 'configuration' && type === 'published_itr_results') || (notifStatus === 'published' && type === 'itr_unlocked')))

  const routesnotif = useMemo(() => ({
    published_itr_results: `../${itr_id}/results`,
    itr_unlocked: `../${itr_id}/preferences`,
    shift_exchange_request_created: '../exchange/feed',
    shift_exchange_response_receiv: '../exchange/requests',
    shift_exchange_exchange_approv: '../exchange/requests',
    shift_exchange_exchange_reject: '../exchange/requests',
    shift_reassignment_added: './',
    shift_reassignment_removed: './',
  }), [itr_id])

  const getMessage = useMemo(() => ({
    published_itr_results: t('user.notifications.publishedItrResults'),
    itr_unlocked: t('user.notifications.itrUnlocked'),
    shift_exchange_request_created: userName ? t('user.notifications.shiftExchangeRequestCreated') : t('user.notifications.shiftExchangeRequestCreatedNoUser'),
    shift_exchange_response_receiv: t('user.notifications.shiftExchangeResponseReceived'),
    shift_exchange_exchange_approv: t('user.notifications.shiftExchangeApproved'),
    shift_exchange_exchange_reject: t('user.notifications.shiftExchangeRejected'),
    shift_reassignment_added: t('user.notifications.shiftReassignmentAdded', { date: new Date(context?.start).toLocaleString(currentLocale, { month: 'long', day: 'numeric' }) }),
    shift_reassignment_removed: t('user.notifications.shiftReassignmentRemoved', { date: new Date(context?.start).toLocaleString(currentLocale, { month: 'long', day: 'numeric' }) }),
  }), [t, userName, context, currentLocale])

  async function setViewed(e) {
    e.preventDefault()
    const url = `/update_notification/${id_notification}`
    try {
      await request({ url, method: 'post' })
      refetchNotifications()
    } catch (err) {
      console.error('Failed to set notification as viewed:', err)
    }
  }

  const date = useMemo(() => timeAgo(hour, t), [hour, t])
  const pathnotif = routesnotif[type]
  const message = getMessage[type]
  const linkState = itrData.itrs[entry] && (type === 'itr_unlocked' || type === 'published_itr_results') ? {
    itr_type: itrData.itrs[entry].itr_type,
    start_day: itrData.itrs[entry].start_day,
    end_day: itrData.itrs[entry].end_day,
  } : null

  const showAlert = () => {
    // alert('Esta notificación ha expirado o ya ha sido atendida. Por favor, revisa tus últimas notificaciones para mantenerte al día.')
  }

  return (
    exists ? (
      <Link
        to={pathnotif}
        className="w-11/12"
        state={linkState}
      >
        <div className="notification-card bg-teal-100 rounded-lg shadow-lg my-2 p-2 space-y-2 grid grid-cols-12">
          <NotificationContent setViewed={setViewed} message={message} date={date} />
        </div>
      </Link>
    ) : (
      <div onClick={showAlert} className="notification-card bg-teal-100 rounded-lg shadow-lg my-2 p-2 space-y-2 grid grid-cols-12 cursor-pointer">
        <NotificationContent setViewed={setViewed} message={message} date={date} />
      </div>
    )
  )
}
