import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { SingleUserStats } from '../../components/Stats/SingleUserStats'
import { request } from '../../utils/axios'
import { requireApproved, requireLoggedIn } from '../../utils/checks'
import NoData from '../Manager/NoData'

export const getStatsQuery = () => ({
  queryKey: ['stats'],
  queryFn: async () => request({ url: '/user/fetch_stats', method: 'get' }),
})
export const userStatsLoader = queryClient => async () => {
  const user = await requireLoggedIn(queryClient)
  await requireApproved(user)
  const query = getStatsQuery()
  return queryClient.getQueryData(query.queryKey)
    ?? (await queryClient.fetchQuery(query))
}

export default function UserStats() {
  const { data: stats } = useQuery(getStatsQuery())
  const { t } = useTranslation()

  if (!Object.keys(stats).length) return <NoData text={t('user.stats.noDataText')} description={t('user.stats.noDataDescription')} />

  return (
    <SingleUserStats stats={stats} />
  )
}
