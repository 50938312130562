import { useTranslation } from 'react-i18next'
import {
  NavLink, Outlet, useOutletContext,
} from 'react-router-dom'

import { classNames } from '../utils/helpers'

export default function AdvancedItrConfig() {
  const [status, locked] = useOutletContext()

  const { t } = useTranslation()

  const tabs = [
    { name: t('manager.incompatibilities'), href: 'incompatibilities', current: true },
    { name: t('manager.crossShiftReqs'), href: 'clusters', current: false },
  ]

  return (
    <>
      <div className="block mt-3 max-w-xl mx-auto">
        <nav className="isolate flex divide-x rounded-lg shadow" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <NavLink
              key={tab.name}
              to={tab.href}
              id={tab.href}
              className={classNames(
                '[&.active]:text-gray-900 [&.active]:border-b-2 [&.active]:border-solid [&.active]:border-b-blue-500 text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10',
              )}
            >
              <span>{tab.name}</span>
            </NavLink>
          ))}
        </nav>
      </div>
      <Outlet context={[locked]} />
    </>
  )
}
