// USER_SHIFT_EXCHANGE = "user_shift_exchange"
// USER_PREFERENCES_RECORDING = "user_preferences_recorcing"
// MANAGER_ADD_ROLES_AND_USERS = "manager_add_roles_and_users"
// MANAGER_ITERATION_CONFIGURATION = "manager_chain_iteration_configuration"
// MANAGER_ITERATION_RESULTS_VERIFICATION = "manager_iteration_results_verification"
// MANAGER_SHIFT_EXCHANGE = "manager_shift_exchange"

const tutorials = {
  user_shift_exchange: [
    {
      content: 'intro',
      placement: 'center',
      target: '#shiftExchange',
    },
    {
      target: '#calendar',
      content: 'calendar',
      placement: 'auto',
    },
    {
      target: '#feed',
      content: 'feed',
      placement: 'auto',
    },
    {
      target: '#requests',
      content: 'requests',
      route: '/user/exchange/requests',
      placement: 'auto',
    },
    {
      target: '#myExchanges-requests',
      content: 'myExchangesRequests',
      placement: 'auto',
    },
    {
      target: '#myExchanges-responses',
      content: 'myExchangesResponses',
      placement: 'right-start',
    },
    {
      target: '#myExchanges-completed',
      content: 'myExchangesCompleted',
      placement: 'auto',
    },
  ],
  manager_iteration_configuration: [
    // 0
    {
      content: 'welcome',
      placement: 'center',
      target: 'body',
      id: 'welcome',
    },
    // 1
    {
      content: 'configureRolesAndUsers',
      placement: 'auto',
      target: '#rolesScreen',
      id: 'rolesScreen',
    },
    // 2
    {
      content: 'activateRoleConfigurePoints',
      placement: 'auto',
      target: '#rolesTable',
      id: 'rolesTable',
    },
    // 3
    {
      content: {
        message: 'configureServiceSections',
        customProps: 'hideBack',
      },
      placement: 'auto',
      target: '#services',
      route: 'services',
      id: 'services',
    },
    // 4
    {
      content: {
        message: 'createFirstSection',
        customProps: 'hideNext hideBack',
      },
      beacon: true,
      target: '#createFirstShift',
      spotlightClicks: true,
      id: 'createFirstShift',
    },
    // 5
    {
      content: {
        message: 'nameDescriptionAcronymSelectRoles',
        customProps: 'hideNext hideBack',
      },
      placement: 'auto',
      target: '#createShift',
      spotlightClicks: true,
      id: 'createShift',
    },
    // 6
    {
      content: {
        message: 'configureDaysOff',
        customProps: 'hideNext hideBack',
      },
      placement: 'auto',
      target: '#daysOff',
      spotlightClicks: true,
      id: 'daysOff',
    },
    // 7
    {
      content: {
        message: 'defineDaysOff',
        customProps: 'hideNext hideBack',
      },
      placement: 'auto',
      target: '#daysOffSetUp',
      spotlightClicks: true,
      id: 'daysOffSetUp',
    },
    // 8
    {
      content: {
        message: 'defineShiftNeeds',
        customProps: 'hideBack',
      },
      placement: 'auto',
      target: '#needs',
      route: 'slot-subset',
      id: 'needs',
    },
    // 9
    {
      content: 'configureMaxMinShifts',
      placement: 'auto',
      target: '#reqs',
      id: 'reqs',
    },
    // 10
    {
      content: {
        message: 'defineSpecialDaysGroups',
        customProps: 'hideBack',
      },
      placement: 'auto',
      target: '#slot-subset',
      id: 'slot-subset',
    },
    // 11
    {
      content: {
        message: 'createFirstSpecialDaysGroup',
        customProps: 'hideNext hideBack',
      },
      placement: 'auto',
      target: '#createFirstSubsetSlot',
      spotlightClicks: true,
      id: 'createFirstSubsetSlot',
      // setOpen?
    },
    // 12
    {
      content: {
        message: 'nameExampleWeekends',
        customProps: 'hideNext hideBack',
      },
      placement: 'bottom',
      target: '#createSlotSubset',
      spotlightClicks: true,
      id: 'createSlotSubset',
    },
    // 13
    {
      content: {
        message: 'selectCreatedGroup',
        customProps: 'hideBack',
      },
      placement: 'auto',
      target: '#slotSubsets',
      spotlightClicks: true,
      id: 'slotSubsets',
    },
    // 14
    {
      content: 'selectDaysForGroup',
      placement: 'auto',
      target: '#calendar',
      spotlightClicks: true,
      id: 'calendar',
    },
    // 15
    {
      content: 'defineShiftsForSpecialDays',
      placement: 'auto',
      target: '#subsetSlotReqs',
      spotlightClicks: true,
      id: 'subsetSlotReqs',
      route: 'incompatibilities',
    },
    // 16
    {
      content: 'saveChangesReminder',
      placement: 'auto',
      target: '#saveChanges',
      spotlightClicks: true,
      id: 'saveChangesSubsetSlots',
      route: 'recorded-data',
    },
    {
      content: 'incompatibilities',
      placement: 'auto',
      target: '#incompatibilities',
      id: 'incompatibilities',
    },
    {
      content: 'recorded-data',
      placement: 'auto',
      target: '#recorded-data',
      id: 'recorded-data',
    },
    // 17
    {
      content: 'unlockIteration',
      placement: 'auto',
      target: '#toggleItrLock',
      id: 'toggleItrLock',
    },
  ],
}

export default tutorials
