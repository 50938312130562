import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function CookiesBanner({ onAccept, onReject }) {
  const { t } = useTranslation()
  return (
    <div className="pointer-events-none fixed inset-x-0 bottom-0 px-6 pb-6">
      <div className="pointer-events-auto ml-auto max-w-xl rounded-xl bg-white p-6 shadow-lg ring-1 ring-gray-900/10">
        <p className="text-sm leading-6 text-gray-900">
          {t('landing.cookiesBanner.text')}
          {' '}
          {' '}
          <Link to="/cookies" className="font-semibold text-blue-600">
            {t('landing.cookiesBanner.link')}
          </Link>
          .
        </p>
        <div className="mt-4 flex items-center gap-x-5">
          <button
            type="button"
            className="rounded-md bg-gray-900 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
            onClick={onAccept}
          >
            {t('landing.cookiesBanner.acceptAll')}
          </button>
          <button type="button" className="text-sm font-semibold leading-6 text-gray-900" onClick={onReject}>
            {t('landing.cookiesBanner.rejectAll')}
          </button>
        </div>
      </div>
    </div>
  )
}
