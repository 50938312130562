import { Popover } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale, PointElement,
  Title, Tooltip,
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import React, { useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import { bgColorRGBA, textColorRGBA } from '../../utils/constants'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin)

function UserStatsChart({ itrIdToName, shiftsData }) {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#4B5563', // A nice gray tone
          font: {
            size: 12,
            style: 'italic',
          },
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 20,
          boxWidth: 10,
        },
      },
      tooltip: {
        enabled: true,
      },
      // annotation: {
      //   annotations: {
      //     line1: {
      //       type: 'line',
      //       yMin: 4,
      //       yMax: 4,
      //       borderColor: 'rgb(75, 192, 192)',
      //       borderWidth: 1,
      //       borderDash: [6, 6],
      //       label: {
      //         content: 'average for you',
      //         enabled: true,
      //         position: 'end',
      //       },
      //     },
      //     line2: {
      //       type: 'line',
      //       yMin: 3,
      //       yMax: 3,
      //       borderColor: 'rgb(75, 192, 192)',
      //       borderWidth: 1,
      //       borderDash: [6, 6],
      //       label: {
      //         content: 'average for your role for selected shifts',
      //         enabled: true,
      //         position: 'end',
      //       },
      //     },
      //   },
      // },
    },
    scales: {
      x: {
        beginAtZero: true,
        title: {
          display: true,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  }

  const [selectedShifts, setSelectedShifts] = useState(
    new Set(Object.values(shiftsData).map(shift => shift.idShift)),
  )
  const [showGraph, setShowGraph] = useState(true)

  const { t } = useTranslation()

  const toggleShiftSelection = shiftId => {
    const newSelectedShifts = new Set(selectedShifts)
    if (newSelectedShifts.has(shiftId)) {
      newSelectedShifts.delete(shiftId)
    } else {
      newSelectedShifts.add(shiftId)
    }
    setSelectedShifts(newSelectedShifts)
  }

  const data = useMemo(() => {
    const labels = Object.values(itrIdToName)
    const datasets = []

    const textColors = {}
    const bgColors = {}

    Object.values(shiftsData).forEach(shift => {
      const randomIndex = Math.floor(Math.random() * textColorRGBA.length)
      textColors[shift.idShift] = textColorRGBA[randomIndex]
      bgColors[shift.idShift] = bgColorRGBA[randomIndex]
    })

    shiftsData.filter(shift => selectedShifts.has(shift.idShift)).forEach(shift => {
      const dataPoints = Object.keys(itrIdToName).map(label => shift.perItr[label] || 0)
      datasets.push({
        label: shift.name,
        data: dataPoints,
        borderColor: textColors[shift.idShift],
        backgroundColor: bgColors[shift.idShift],
        pointRadius: 5,
        pointHoverRadius: 5,
        pointHitRadius: 10,
        pointBorderWidth: 2,
        pointStyle: 'rounded',
      })
    })

    return { labels, datasets }
  }, [itrIdToName, selectedShifts, shiftsData])

  return (
    <>
      <div className="flex flex-row justify-between items-center mt-4">
        <button
          onClick={() => setShowGraph(!showGraph)}
          type="button"
          className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900"
        >
          <span className="text-sm font-medium text-gray-700">{showGraph ? t('user.stats.hideGraph') : t('user.stats.showGraph')}</span>
        </button>
        {
          showGraph && (
            <Popover as="div" className="relative inline-block text-left">
              <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                <span>{t('user.stats.selectShifts')}</span>
                <ChevronDownIcon className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
              </Popover.Button>
              <Popover.Panel className="absolute z-10 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <form className="space-y-4 p-4">
                  {shiftsData.map(shift => (
                    <div key={shift.idShift} className="flex items-center">
                      <input
                        id={`filter-${shift.idShift}`}
                        name="shifts"
                        type="checkbox"
                        checked={selectedShifts.has(shift.idShift)}
                        onChange={() => toggleShiftSelection(shift.idShift)}
                        className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                      />
                      <label htmlFor={`filter-${shift.idShift}`} className="ml-3 text-sm font-medium text-gray-900">
                        {shift.name}
                      </label>
                    </div>
                  ))}
                </form>
              </Popover.Panel>
            </Popover>
          )
        }
      </div>
      {showGraph ? (
        <Line data={data} options={options} />
      ) : null}
    </>
  )
}

export default UserStatsChart
