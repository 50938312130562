import React, {
  createContext, useContext, useState, useEffect, useMemo,
} from 'react'

import { request } from './axios'

// Create a context for app options
const AppOptionsContext = createContext()

export const useAppOptions = () => useContext(AppOptionsContext)

export function AppOptionsProvider({ children }) {
  const [appOptions, setAppOptions] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  useEffect(() => {
    // Fetch the app options from the backend when the component mounts
    const fetchAppOptions = async () => {
      try {
        const data = await request({ url: '/load_app_options', method: 'GET' })
        setAppOptions(data)
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }
    fetchAppOptions()
  }, [])

  return useMemo(() => (
    <AppOptionsContext.Provider value={{ appOptions, loading, error }}>
      {children}
    </AppOptionsContext.Provider>
  ), [appOptions, loading, error, children])
}
