/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
import { useQuery } from '@tanstack/react-query'
import {
  useEffect, useReducer,
  useRef, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useLoaderData, useOutletContext, useParams } from 'react-router-dom'

import { requireApproved, requireManager } from '../../../../utils/checks'
import { itrRolesQuery } from '../../../../utils/queries'
import RolesTable from './RolesTable'

export const itrRolesLoader = queryClient => async ({ params }) => {
  const user = await requireManager(queryClient)
  await requireApproved(user)
  const rolesQuery = itrRolesQuery(params.id)
  return (
    { user, query: queryClient.getQueryData(rolesQuery.queryKey) ?? (await queryClient.fetchQuery(rolesQuery)) }
  )
}

export default function RolesConfig() {
  const checkbox = useRef()

  const context = useOutletContext()
  const locked = context ? context[0] : false

  const { user } = useLoaderData()
  const managedGroups = user?.managed_groups?.map(group => group.id_group) || []

  const { t } = useTranslation()

  const { id } = useParams()
  const { data, isLoading } = useQuery(itrRolesQuery(id))
  const itr_roles = data?.itr_roles
  const roles = data?.roles
  const participants = data?.itr_prefs

  const [selectedRoles, setSelectedRoles] = useState(Object.keys(itr_roles))
  const [selectedParticipants, setSelectedParticipants] = useState(participants.reduce((acc, item) => {
    acc[item.id_user] = item.partaker
    return acc
  }, {}))

  useEffect(() => {
    setSelectedParticipants(participants.reduce((acc, item) => {
      acc[item.id_user] = item.partaker
      return acc
    }, {}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants])

  const parsedRoles = roles.map(role => {
    const itrRole = Object.keys(itr_roles).includes(String(role.id)) ? itr_roles[role.id] : null
    return {
      id: role.id,
      role: role.name,
      num_users: itrRole ? itrRole.num_users : 0,
      base_points: itrRole ? itrRole.base_points : 0,
      jokers: itrRole ? itrRole.jokers : 0,
      id_group: role.group.id_group,
    }
  })

  const participantsGroupedByRole = participants.reduce((acc, item) => {
    // Construct the user's full name and prepare the user object
    const userDetail = {
      id: item.user.id,
      name: `${item.user.firstname} ${item.user.lastname}`,
    }

    // Check if the role ID already exists in the accumulator
    // If it does, push the new user detail into the existing array
    // If not, create a new array for this role ID and add the user detail
    if (acc[item.user.role.id]) {
      acc[item.user.role.id].push(userDetail)
    } else {
      acc[item.user.role.id] = [userDetail]
    }

    return acc
  }, {})

  // initial state of roles
  const [roles_itr, setRoles_itr] = useState(parsedRoles)

  // roles filtered by the search bar
  const [filteredRoles, setFilteredRoles] = useState(parsedRoles)

  const rolesReducer = (state, action) => {
    switch (action.type) {
      case 'SET_ROLES':
        return action.payload
      case 'EDIT_ROLE':
        return state.map(role => {
          return role.id === action.payload.id ? {
            ...role,
            [action.payload.property]: action.payload.value,
          } : role
        })
      case 'RESET_PROPERTIES':
        return state.map(role => {
          if (role.id === action.payload) {
            return {
              ...role,
              base_points: 0,
              jokers: 0,
            }
          }
          return role
        })
      case 'RESET_ROLES':
        // Reset the roles to the initial state
        return roles_itr
      default:
        return state
    }
  }

  const [rolesState, dispatch] = useReducer(rolesReducer, parsedRoles)

  useEffect(() => {
    setFilteredRoles(rolesState)
  }, [rolesState])

  useEffect(() => {
    setRoles_itr(parsedRoles)
    setFilteredRoles(parsedRoles)
    dispatch({ type: 'SET_ROLES', payload: parsedRoles })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data])

  // const {
  //   startTutorial,
  // } = useTutorial()

  // const { data: shifts } = useQuery(shiftsQuery(id))
  // const { data: slotSubsets } = useQuery(slotSubsetsQuery(id))

  // useEffect(() => {
  //   const params = { existingSections: Boolean(shifts?.shifts?.length), existingSlotSubsets: Boolean(Object.keys(slotSubsets?.slot_subsets || {})?.length) }
  //   startTutorial('manager_iteration_configuration', params)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  if (!itr_roles || !roles) {
    // Return loading state or placeholder until data is available
    return <p>{t('manager.rolesConfig.loading')}</p>
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8" id="rolesScreen">
      <div className="sm:flex sm:items-center" />
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
            <div className="relative">
              {roles.length > 0 ? (
                <RolesTable
                  checkbox={checkbox}
                  dispatch={dispatch}
                  filteredRoles={filteredRoles}
                  locked={locked}
                  participants={participantsGroupedByRole}
                  roles_itr={roles_itr}
                  roles={rolesState}
                  selectedParticipants={selectedParticipants}
                  selectedRoles={selectedRoles}
                  setFilteredRoles={setFilteredRoles}
                  setRoles_itr={setRoles_itr}
                  setSelectedParticipants={setSelectedParticipants}
                  setSelectedRoles={setSelectedRoles}
                  managedGroups={managedGroups}
                />
              ) : (
                <p>{t('manager.rolesConfig.noRoles')}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
