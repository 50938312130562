import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { useSpring } from 'react-spring'

import AnimatedDiv from './AnimatedDiv'

export default function Hero() {
  const div1Animation = useSpring({
    from: { opacity: 0, transform: 'translateX(-100%)' },
    to: { opacity: 1, transform: 'translateX(0%)' },
    config: { duration: 1000 },
  })

  const div2Animation = useSpring({
    from: { opacity: 0, transform: 'translateX(100%)' },
    to: { opacity: 1, transform: 'translateX(0%)' },
    config: { duration: 1000 },
  })

  const { t, i18n } = useTranslation()
  const locale = i18n.language

  return (
    <div className="relative isolate overflow-hidden bg-white">
      <div className="mx-auto max-w-7xl px-6 pt-10 pb-24 sm:pb-32 lg:flex lg:py-40 lg:px-8">
        {/* DIV 1 */}
        <AnimatedDiv
          className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl lg:flex-shrink-0 lg:pt-8"
          style={div1Animation}
        >
          <h1 className="mt-24 text-5xl lg:mt-2 font-bold tracking-tight text-gray-900 sm:text-6xl">
            {t('landing.hero.title')}
            {' '}
            <span className="text-teal-600">{t('landing.hero.title2')}</span>
            .
          </h1>
          <p className="mt-6 text-lg leading-8 text-black-600 font-bold">
            {t('landing.hero.subtitle1')}
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {t('landing.hero.subtitle2')}
          </p>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {t('landing.hero.subtitle3')}
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            <Link
              to="/signup"
              className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              {t('landing.hero.startUsingYouShift')}
            </Link>
            <Link to="/contact" className="text-sm font-semibold leading-6 text-gray-900">
              {t('generic.contactUs')}
              {' '}
              <span aria-hidden="true">→</span>
            </Link>
          </div>
        </AnimatedDiv>
        {/* DIV 2 */}
        <AnimatedDiv
          className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mt-0 lg:mr-0 lg:max-w-none lg:flex-none xl:ml-32"
          style={div2Animation}
        >
          <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
            <div className="-m-2 rounded-xl bg-gray-900/5 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
              <img
                src={`https://youshift-docs.s3.eu-west-3.amazonaws.com/hero-${locale}.png`}
                alt="App screenshot"
                width={2432}
                height={1442}
                className="w-[66rem] rounded-md shadow-2xl ring-1 ring-gray-900/10"
              />
            </div>
          </div>
        </AnimatedDiv>
      </div>
    </div>
  )
}
