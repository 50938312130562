import {
  Dialog, Transition,
} from '@headlessui/react'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { Fragment, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ClustersCounter from '../../../../components/Calendars/ClustersCounter'
import Alert from '../../../../components/FormFeedback/Alert'

export default function AddCluster({
  roles,
  shifts,
  cluster,
  open,
  setOpen,
  addShiftCluster,
  editShiftCluster,
  deleteShiftCluster,
  setSuccess,
  error,
  setError,
  roleToShiftMap,
}) {
  const cancelButtonRef = useRef(null)
  const { t } = useTranslation()

  const [checkedRole, setCheckedRole] = useState(cluster?.id_user_role || roles[0]?.id)
  const [checkedShifts, setCheckedShifts] = useState(cluster?.cluster_elements.map(element => element.id_shift) || [])
  const [minReq, setMinReq] = useState(cluster?.min_req || 0)
  const [maxReq, setMaxReq] = useState(cluster?.max_req || 0)

  const handleCheckboxChangeSections = shiftId => {
    if (checkedShifts.includes(shiftId)) {
      setCheckedShifts(prevState => prevState.filter(id => id !== shiftId))
    } else {
      setCheckedShifts(prevState => [...prevState, shiftId])
    }
  }

  const saveChanges = async () => {
    if (minReq < 0 || maxReq < 1) {
      setError(t('manager.clusters.zeroReq'))
      setSuccess(null)
      return
    }

    if (minReq > maxReq) {
      setError(t('manager.clusters.invalidReqs'))
      setSuccess(null)
      return
    }

    if (checkedShifts.length < 2) {
      setError(t('manager.clusters.emptySections'))
      setSuccess(null)
      return
    }

    if (checkedRole === null) {
      setError(t('manager.clusters.emptyRole'))
      setSuccess(null)
      return
    }

    try {
      const data = {
        id_user_role: checkedRole,
        shift_list: checkedShifts,
        min_req: minReq,
        max_req: maxReq,
      }
      if (cluster) {
        data.id_shift_cluster_requirement = cluster.id_shift_cluster_requirement
        editShiftCluster.mutate(data)
      } else {
        addShiftCluster.mutate(data)
      }
    } catch (error) {
      // this try-catch doesn't really work so we might as well delete it?
      setSuccess(null)
    }
  }

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilSquareIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div className="my-4 text-center sm:mt-0 sm:ml-4 sm:text-left w-full mx-auto">
                    <Dialog.Title as="h3" className="text-lg mb-5 mt-2 text-blue-600 font-semibold leading-6 ">
                      {cluster ? t('manager.clusters.edit') : t('manager.clusters.create')}
                    </Dialog.Title>
                    <fieldset className="mt-4 max-h-[50vh] overflow-scroll">
                      <legend className="text-base font-semibold text-gray-900 my-2">
                        {t('manager.clusters.chooseRoles')}
                      </legend>
                      <div className="mt-2 pb-3 px-3 overflow-scroll">
                        {roles.map(role => (
                          <div key={role.id} className="flex items-center">
                            <input
                              id={role.id}
                              name="notification-method"
                              type="radio"
                              value={role.id}
                              checked={checkedRole === role.id}
                              onChange={() => setCheckedRole(role.id)}
                              className="h-4 w-4 border-gray-300 text-blue-600 focus:ring-blue-600"
                            />
                            <label htmlFor={role.id} className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                              {role.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    </fieldset>
                    <div className="mt-2">
                      <div className="isolate -space-y-px rounded-md">
                        {/* <div className="relative rounded-md rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label htmlFor="name" className="block text-xs font-medium text-gray-900">
                            {t('generic.firstName')}
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder={t('generic.firstName')}
                            value={name}
                            onChange={e => setName(e.target.value)}
                          />
                        </div> */}
                        <p className="text-base font-semibold text-gray-900 my-2">{t('manager.clusters.minMax')}</p>
                        <ClustersCounter
                          minReq={minReq}
                          maxReq={maxReq}
                          setMinReq={setMinReq}
                          setMaxReq={setMaxReq}
                        />
                        {/* <div className="relative rounded-md rounded-t-none rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label htmlFor="job-title" className="block text-xs font-medium text-gray-900">
                            {t('manager.clusters.minReq')}
                          </label>
                          <input
                            type="number"
                            name="description"
                            id="description"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="#"
                            min="0"
                            value={minReq}
                            onChange={e => setMinReq(e.target.value)}
                          />
                        </div>
                        <div className="relative rounded-md rounded-t-none rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label htmlFor="job-title" className="block text-xs font-medium text-gray-900">
                            {t('manager.clusters.maxReq')}
                          </label>
                          <input
                            type="number"
                            name="description"
                            id="description"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder="#"
                            min="0"
                            value={maxReq}
                            onChange={e => setMaxReq(e.target.value)}
                          />
                        </div> */}
                      </div>
                      <fieldset className="mt-1 max-h-[50vh] overflow-scroll">
                        <legend className="text-base font-semibold text-gray-900 my-2">
                          {t('manager.clusters.chooseSections')}
                        </legend>
                        <div className="my-2 pb-3 px-3 overflow-scroll">
                          {shifts.filter(shift => roleToShiftMap[checkedRole]?.includes(shift.id_shift)).map(shift => (
                            <div key={shift.id_shift}>
                              <div className="flex flex-row items-center">
                                <input
                                  id={shift.id_shift}
                                  name={shift.id_shift}
                                  type="checkbox"
                                  checked={checkedShifts.includes(shift.id_shift)}
                                  onChange={() => handleCheckboxChangeSections(shift.id_shift)}
                                  className="h-4 w-4 mr-1 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                />
                                <h3 className=" font-medium text-gray-900">{shift.name}</h3>
                              </div>
                            </div>
                          ))}
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
                {error ? <Alert success={false} text={error} /> : null}
                <div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4 justify-between">
                  <div>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                      onClick={() => { saveChanges() }}
                    >
                      {t('generic.save')}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      {t('generic.cancel')}
                    </button>
                  </div>
                  {cluster ? (
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto ml-6"
                      onClick={() => {
                        deleteShiftCluster.mutate({
                          id_shift_cluster_requirement: cluster.id_shift_cluster_requirement,
                        })
                      }}
                    >
                      {t('generic.eliminate')}
                    </button>
                  ) : null}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
