/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-len */
import { CheckBadgeIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useQuery } from '@tanstack/react-query'
import React, { useMemo, useState } from 'react'

import { request } from '../../utils/axios'
import { classNames } from '../../utils/helpers'
import { getUsersQuery } from '../../utils/queries'

export const usersLoader = queryClient => async () => {
  const query = getUsersQuery()
  return queryClient.getQueryData(query.queryKey)
    ?? (await queryClient.fetchQuery(query))
}

const deepClone = obj => JSON.parse(JSON.stringify(obj))

export default function AllUsers() {
  const { data } = useQuery(getUsersQuery())

  const [selections, setSelections] = useState({})
  const [managerFilter, setManagerFilter] = useState('all') // 'all', 'isManager', 'notManager'
  const [groupSizeFilter, setGroupSizeFilter] = useState({ min: '', max: '' })
  const [selectAll, setSelectAll] = useState(false)

  // Selection change handlers
  const handleOrgSelectionChange = (orgName, isSelected) => {
    const newSelections = deepClone(selections)
    newSelections[orgName] = { isSelected, groups: {} }
    setSelections(newSelections)
  }

  const handleGroupSelectionChange = (orgName, groupName, isSelected) => {
    const newSelections = deepClone(selections)
    if (!newSelections[orgName]) newSelections[orgName] = { groups: {} }
    newSelections[orgName].groups[groupName] = isSelected
    setSelections(newSelections)
  }

  const handleUserSelectionChange = (orgName, groupName, userEmail, isSelected) => {
    const newSelections = deepClone(selections)
    if (!newSelections[orgName]) newSelections[orgName] = { groups: {} }
    if (!newSelections[orgName].groups[groupName]) newSelections[orgName].groups[groupName] = {}
    newSelections[orgName].groups[groupName][userEmail] = isSelected
    setSelections(newSelections)
  }

  const filteredData = useMemo(() => {
    if (!data) return []

    return data.reduce((filteredOrgs, org) => {
      const filteredGroups = org.groups.reduce((filteredGrps, group) => {
        // Filter users based on manager status first
        const usersFilteredByManagerStatus = group.users.filter(user => managerFilter === 'all'
          || (managerFilter === 'isManager' && user.is_manager)
          || (managerFilter === 'notManager' && !user.is_manager))

        // Now, with users filtered by manager status, apply group size filter
        const minSize = groupSizeFilter.min !== '' ? parseInt(groupSizeFilter.min, 10) : -Infinity
        const maxSize = groupSizeFilter.max !== '' ? parseInt(groupSizeFilter.max, 10) : Infinity

        if (group.users.length >= minSize && group.users.length <= maxSize) {
          filteredGrps.push({ ...group, users: usersFilteredByManagerStatus })
        }

        return filteredGrps
      }, [])

      if (filteredGroups.length > 0) {
        filteredOrgs.push({ ...org, groups: filteredGroups })
      }

      return filteredOrgs
    }, [])
  }, [data, managerFilter, groupSizeFilter])

  const handleSelectAllChange = isSelected => {
    setSelectAll(isSelected)
    const newSelections = {}

    // If selecting all, update every org, group, and user to selected
    if (isSelected) {
      filteredData.forEach(org => {
        newSelections[org.org_name] = { isSelected: true, groups: {} }
        org.groups.forEach(group => {
          newSelections[org.org_name].groups[group.group_name] = {}
          group.users.forEach(user => {
            newSelections[org.org_name].groups[group.group_name][user.email] = true
          })
        })
      })
    }

    setSelections(newSelections)
  }

  // Export emails function
  const exportEmails = () => {
    let emails = []
    // Iterate over the filtered data to check selections
    filteredData.forEach(org => {
      if (selections[org.org_name]?.isSelected) {
        // If the entire org is selected, add all users' emails
        org.groups.forEach(group => {
          group.users.forEach(user => {
            emails.push(user.email)
          })
        })
      } else {
        // Check selections for groups and users
        org.groups.forEach(group => {
          if (selections[org.org_name]?.groups[group.group_name]) {
            // If the entire group is selected, add all users' emails from the group
            group.users.forEach(user => {
              emails.push(user.email)
            })
          } else {
            // Add selected users' emails
            group.users.forEach(user => {
              if (selections[org.org_name]?.groups[group.group_name]?.[user.email]) {
                emails.push(user.email)
              }
            })
          }
        })
      }
    })

    // Remove duplicates (if any)
    emails = [...new Set(emails)]

    // Convert array to string and copy to clipboard
    const emailsString = emails.join('; ')
    navigator.clipboard.writeText(emailsString)
      .then(() => {
        // Success handler
        // alert('Emails copied to clipboard!')
      })
      .catch(err => {
        // Error handler
        // console.error('Failed to copy emails to clipboard', err)
        // alert('Failed to copy emails to clipboard. Please try again.')
      })
  }

  const impersonateUser = async id => {
    const userConfirmed = window.confirm('Are you sure you want to impersonate this user?')

    if (userConfirmed) {
      const user = await request({ url: `admin/user/${id}/fetch_token`, method: 'get' })
      localStorage.setItem('token', user.token)
      window.location.href = '/login'
    }
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mb-4 bg-white sticky top-0 -ml-5 py-2">
        <select
          value={managerFilter}
          onChange={e => setManagerFilter(e.target.value)}
          className="mr-2 rounded-xl border-gray-400"
        >
          <option value="all">All Users</option>
          <option value="isManager">Managers Only</option>
          <option value="notManager">Non-Managers Only</option>
        </select>
        <input
          type="number"
          placeholder="Min group size"
          value={groupSizeFilter.min}
          onChange={e => setGroupSizeFilter(prev => ({ ...prev, min: e.target.value }))}
          className="mr-2 rounded-xl border-gray-400"
        />
        <input
          type="number"
          placeholder="Max group size"
          value={groupSizeFilter.max}
          onChange={e => setGroupSizeFilter(prev => ({ ...prev, max: e.target.value }))}
          className="mr-2 rounded-xl border-gray-400"
        />
        <button onClick={exportEmails} className="px-4 py-2 bg-blue-500 hover:bg-blue-400 text-white rounded">
          Export Emails
        </button>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full border-separate border-spacing-0">
              <thead>
                <tr className="sticky top-[50px] bg-white">
                  <th scope="col" className="border-b border-gray-300 py-3.5 text-left text-sm font-semibold px-6 py-2">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={e => handleSelectAllChange(e.target.checked)}
                      className="rounded-md border border-blue-600"
                    />
                  </th>
                  <th scope="col" className="border-b border-gray-300 bg-white py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8">Name</th>
                  <th scope="col" className="border-b border-gray-300 bg-white px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Email</th>
                  <th scope="col" className="border-b border-gray-300 bg-white px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Manager</th>
                  <th scope="col" className="border-b border-gray-300 bg-white px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Mail Verified</th>
                  <th scope="col" className="border-b border-gray-300 bg-white px-3 py-3.5 text-left text-sm font-semibold text-gray-900">User Verified</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((org, orgIndex) => (
                  <React.Fragment key={org.org_name}>
                    <tr className="bg-blue-200 sticky top-24">
                      <td className="px-6 py-2 whitespace-nowrap">
                        <input
                          type="checkbox"
                          checked={!!selections[org.org_name]?.isSelected}
                          onChange={e => handleOrgSelectionChange(org.org_name, e.target.checked)}
                          className="rounded-md border border-blue-600"
                        />
                      </td>
                      <td colSpan="7" className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                        {org.org_name}
                      </td>
                    </tr>
                    {org.groups.map((group, groupIndex) => (
                      <React.Fragment key={group.group_name}>
                        <tr className="bg-blue-50 sticky top-36">
                          <td className="px-6 py-2 whitespace-nowrap">
                            <input
                              type="checkbox"
                              checked={!!selections[org.org_name]?.groups[group.group_name]}
                              onChange={e => handleGroupSelectionChange(org.org_name, group.group_name, e.target.checked)}
                              className="rounded-md border border-blue-600"
                            />
                          </td>
                          <td colSpan="7" className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                            {group.group_name}
                          </td>
                        </tr>
                        {group.users.map((user, userIndex) => (
                          <tr key={user.email}>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <input
                                type="checkbox"
                                checked={!!selections[org.org_name]?.groups[group.group_name]?.[user.email]}
                                onChange={e => handleUserSelectionChange(org.org_name, group.group_name, user.email, e.target.checked)}
                                className="rounded-md border border-blue-600"
                              />
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                              <button type="button" onClick={() => impersonateUser(user.id)} className="w-6 h-6 align-middle text-teal-600">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-6">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59" />
                                </svg>
                              </button>
                              {`${user.firstname} ${user.lastname}`}
                            </td>
                            <td className={classNames('whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>{user.email}</td>
                            <td className={classNames('whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>{user.is_manager ? <CheckBadgeIcon className="h-8 text-green-600" /> : <XMarkIcon className="h-8 text-red-600" />}</td>
                            <td className={classNames('whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>{user.mail_is_verified ? <CheckBadgeIcon className="h-8 text-green-600" /> : <XMarkIcon className="h-8 text-red-600" />}</td>
                            <td className={classNames('whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>{user.user_is_verified ? <CheckBadgeIcon className="h-8 text-green-600" /> : <XMarkIcon className="h-8 text-red-600" />}</td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
