import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { request } from './axios'
import translationES from './locales/es/translation.json'
import translationEN from './locales/en/translation.json'
import translationPT from './locales/pt/translation.json'
import translationCA from './locales/ca/translation.json'
import translationFR from './locales/fr/translation.json'

const resources = {
  es: {
    translation: translationES,
  },
  en: {
    translation: translationEN,
  },
  ca: {
    translation: translationCA,
  },
  pt: {
    translation: translationPT,
  },
  fr: {
    translation: translationFR,
  }
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'es',
    resources,
  })

// Function to change the language
export const changeLanguage = newLanguage => {
  i18n.changeLanguage(newLanguage, (err, t) => {
    if (err) return err

    // Update your UI or reload your app here to reflect the new language
  })
}

// Example of a language selector component
export function LanguageSelector({ landing }) {
  const queryClient = useQueryClient()

  const changeLangMutation = useMutation({
    mutationFn: language => request({ url: '/change_user_language', method: 'post', data: { language } }),
    onSuccess: () => {
      queryClient.invalidateQueries(['profile'])
      // setSuccess("Grupo aceptado")
      // setError(null)
    },
    onError: () => {
      // console.error('Error fetching data:', error)
      // setSuccess(null)
    },
  })

  const handleLanguageChange = event => {
    const newLanguage = event.target.value

    // Store the user's preference (for example, in local storage)
    localStorage.setItem('i18nextLng', newLanguage)

    // Call the function to change the language in i18next
    changeLanguage(newLanguage)

    // Update user prefered language in the database
    if (!landing) {
      changeLangMutation.mutate(newLanguage)
    }
  }

  return (
    <select onChange={handleLanguageChange} value={i18n.language} className="rounded-md border border-gray-300">
      <option value="es">Español</option>
      <option value="en">English</option>
      <option value="ca">Català</option>
      <option value="pt">Português</option>
      <option value="fr">Français</option>
    </select>
  )
}

export default i18n
