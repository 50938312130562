import { Dialog, Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { request } from '../../utils/axios'
import { classNames } from '../../utils/helpers'
import Alert from '../FormFeedback/Alert'
import EventSquare from './EventSquare'

const type = ['P', 'S', 'E', 'V']

export default function EditPreferenceByManager({
  preference, open, setOpen, shifts,
}) {
  const { t } = useTranslation()
  const { id } = useParams()

  const [event, setEvent] = useState(preference.event)
  const [justification, setJustification] = useState(preference.justification)
  const [idShift, setIdShift] = useState(preference.idShift)

  const [error, setError] = useState(false)

  const {
    points, idSlot, idUser,
  } = preference

  const queryClient = useQueryClient()

  const editUserRequirementsMutation = useMutation({
    mutationFn: () => request({
      url: `manager/itrs/${id}/user/${idUser}/edit_user_requirements`,
      method: 'patch',
      data: {
        slot_prefs: [{
          id_slot: idSlot,
          preference: event,
          justification,
          other_shift_id: idShift,
        }],
      },
    }),
    onSuccess: () => {
      queryClient.setQueryData(['slotPrefs', id], previousData => {
        const updatedData = {
          ...previousData,
          slot_prefs: previousData.slot_prefs.map(slotPref => {
            if (slotPref.id_slot === idSlot) {
              return {
                ...slotPref,
                slot_prefs: {
                  ...slotPref.slot_prefs,
                  [idUser]: {
                    ...slotPref.slot_prefs[idUser],
                    points: 0,
                    preference: event,
                    justification,
                    other_shift_id: idShift,
                  },
                },
              }
            }
            return slotPref
          }),
        }
        return updatedData
      })

      setOpen(false)
    },
    onError: e => {
      if (e.response.data.type === 'ITR_UNLOCKED') {
        setError(t('manager.editPreference.itrUnlocked'))
      } else {
        setError(t('generic.error'))
      }
    },
  })

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <Dialog.Title as="h3" className="text-lg mb-5 font-semibold leading-6 text-gray-900">{t('generic.edit')}</Dialog.Title>
                <div className="flex flex-row items-center my-6">
                  <Listbox value={event} onChange={e => setEvent(e)}>
                    {({ open }) => (
                      <div className="relative mr-2">
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
                          <span className="flex items-center">
                            <span className="ml-3 block truncate">{event}</span>
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {type.map((event, index) => (
                              <Listbox.Option
                                key={index}
                                className={({ active }) => classNames(
                                  active ? 'bg-blue-600 text-white' : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9',
                                )}
                                value={event}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <div className="flex items-center">
                                      <span
                                        className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                      >
                                        {event}
                                      </span>
                                    </div>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active ? 'text-white' : 'text-blue-600',
                                          'absolute inset-y-0 right-0 flex items-center pr-4',
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    )}
                  </Listbox>
                  <EventSquare
                    e={event.toUpperCase()}
                    points={points}
                    justPoints
                    noMargin
                  />
                </div>

                {
                  event === 'E'
                    ? (
                      <>
                        <label htmlFor="name" className="block text-sm font-semibold text-gray-900 mb-2">{t('manager.editPreference.justifyLabel')}</label>
                        <input
                          type="name"
                          name="name"
                          id="name"
                          className="block w-full rounded-md border-0 py-1.5 pl-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          placeholder={t('manager.editPreference.placeholder')}
                          value={justification}
                          onChange={e => setJustification(e.target.value)}
                        />
                      </>
                    )
                    : null
                }

                {
                  event === 'S'
                    ? (
                      <>
                        <label htmlFor="name" className="block text-sm font-semibold text-gray-900 mb-2">{t('manager.editPreference.selectSection')}</label>
                        <div className="flex flex-row items-start gap-3 mb-3">
                          <div className="flex flex-col gap-2">
                            <Listbox value={idShift} onChange={e => setIdShift(e)}>
                              <Listbox.Button className="relative cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                                <span className="block truncate">{shifts.find(s => s.id_shift === idShift)?.name || t('generic.noSelect')}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                  <ChevronUpDownIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="relative max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                  {shifts.map(shift => (
                                    <Listbox.Option
                                      key={shift.id_shift}
                                      className={({ active }) => `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-blue-100 text-blue-900' : 'text-gray-900'}`}
                                      value={shift.id_shift}
                                    >
                                      {({ selected }) => (
                                        <>
                                          <span
                                            className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
                                          >
                                            {shift.name}
                                          </span>
                                          {selected ? (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </Listbox>
                          </div>
                        </div>
                      </>
                    )
                    : null
                }

                {error ? <Alert text={error} /> : null}

                <button
                  type="button"
                  className="inline-flex w-full mt-4 justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                  onClick={() => editUserRequirementsMutation.mutate()}
                  disabled={(event === 'E' && !justification) || (event === 'S' && !idShift)}
                >
                  {t('generic.save')}
                </button>
                <button
                  type="button"
                  className="inline-flex w-full mt-4 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                  onClick={() => setOpen(false)}
                >
                  {t('generic.cancel')}
                </button>
                <p className="text-xs m-3 ml-0 text-gray-400">
                  {t('manager.editPreference.instructions1')}
                  <br />
                  {t('manager.editPreference.instructions2')}
                </p>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
