import { Disclosure } from '@headlessui/react'
import { ChevronDoubleDownIcon } from '@heroicons/react/20/solid'
import { ArrowPathRoundedSquareIcon, CalendarIcon, UserGroupIcon } from '@heroicons/react/24/outline'
import {
  Autocomplete,
  TextField,
} from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import DeleteGroup from '../../components/Admin/DeleteGroup'
import Alert from '../../components/FormFeedback/Alert'
import { request } from '../../utils/axios'
import { requireAdmin } from '../../utils/checks'
import { adminGroupQuery } from '../../utils/queries'

export const adminGroupLoader = queryClient => async ({ params }) => {
  await requireAdmin(queryClient)
  const query = adminGroupQuery(params.id)
  return queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
}

export default function AdminDashboard() {
  const { id } = useParams()
  const { data } = useQuery(adminGroupQuery(id))
  const { t } = useTranslation()

  const [users, setUsers] = useState([])
  const [group, setGroup] = useState({ id_group: id, name: data.group.name, org: data.group.org })
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const [displayDialog, setDisplayDialog] = useState(false)

  const queryClient = useQueryClient()

  const managers = data.users.filter(user => data.group_managers.indexOf(user.id) !== -1)
  const userList = data.users.filter(user => data.group_managers.indexOf(user.id) === -1)
  const userOptions = userList.map(user => ({
    label: `${user.firstname} ${user.lastname}`,
    id: user.id,
  }))

  const summary = [{
    id: 0, name: 'Usuarios', value: data.users.length, icon: UserGroupIcon,
  }, {
    id: 1, name: 'Cadenas', value: data.chains.length, icon: ArrowPathRoundedSquareIcon,
  }, {
    id: 2, name: 'Fecha creación', value: data.group.created, icon: CalendarIcon,
  }]

  // Endpoint: admin/group/<int:id_group>/user/<int:id_user>/revoke_manager_access
  const deleteUserFromManagerMutation = useMutation({
    mutationFn: user => request({ url: `/admin/group/${id}/user/${user}/revoke_manager_access`, method: 'post' }),
    onSuccess: () => {
      setError(null)
      queryClient.invalidateQueries({ queryKey: ['group', id] })
    },
    onError: e => {
      setError(e.response.data.error)
    },
  })

  const resendVerificationEmail = useMutation({
    mutationFn: email => request({ url: '/manager/resend_verification_email', method: 'post', data: email }),
    onSuccess: () => {
      setSuccess('Enviado!')
    },
    onError: e => {
      setError(e.response.data.error)
    },
  })

  // Endpoint: admin/group/<int:id_group>/user/<int:id_user>/grant_manager_access
  const addUserAsManagerMutation = useMutation({
    mutationFn: user => request({ url: `/admin/group/${id}/user/${user}/grant_manager_access`, method: 'post' }),
    onSuccess: () => {
      setError(null)
      queryClient.invalidateQueries({ queryKey: ['group', id] })
    },
    onError: e => {
      setError(e.response.data.error)
    },
  })

  const deleteUserFromManager = async user_id => {
    deleteUserFromManagerMutation.mutate(user_id)
  }

  const addUsersAsManager = () => {
    users.map(user => (
      addUserAsManagerMutation.mutate(user.id)
    ))
    setUsers([])
  }

  const handleAutocomplete = (event, value) => {
    setUsers(value)
  }

  return (
    <div className="mt-6 px-4 sm:px-6 lg:px-8">
      <h1 className="font-bold text-3xl text-center">
        {`${group.name} - ${group.org}`}
      </h1>
      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
        {summary.map(item => (
          <div
            key={item.id}
            className="relative overflow-hidden rounded-lg bg-white px-4 pb-3 pt-5 shadow sm:px-6 sm:pt-6"
          >
            <dt>
              <div className="absolute rounded-md bg-blue-600 p-3">
                <item.icon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <p className="ml-16 truncate text-sm font-medium text-gray-500">{item.name}</p>
            </dt>
            <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">{item.value}</p>
            </dd>
          </div>
        ))}
      </dl>

      {error ? <Alert success={false} text={error} /> : null}
      {success ? <Alert success text={success} /> : null}
      <div className="mt-8 flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="font-bold text-xl leading-6 text-gray-900">Managers</h1>
              </div>
            </div>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                        >
                          {t('generic.name')}
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          {t('generic.email')}
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Edit
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Resend verification email
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {(managers.length > 0
                        ? managers.map(manager => (
                          <tr key={manager.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                              {manager.firstname}
                              {' '}
                              {manager.lastname}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                              {manager.email}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                              <button
                                type="button"
                                className="rounded bg-green-100 px-2 py-1 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-200"
                                onClick={() => deleteUserFromManager(manager.id)}
                              >
                                Remove from manager
                              </button>
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                              <button
                                type="button"
                                className="rounded bg-blue-100 px-2 py-1 text-sm font-semibold text-blue-600 shadow-sm hover:bg-blue-200"
                                onClick={() => resendVerificationEmail.mutate(manager.email)}
                              >
                                Resend
                              </button>
                            </td>
                          </tr>
                        ))
                        : null
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-10 grid grid-cols-2 gap-x-6 gap-y-5 sm:grid-cols-2 items-center">
        <Autocomplete
          disablePortal
          id="users"
          options={userOptions}
          multiple
          value={users}
          sx={{
            width: '100%', display: 'block', borderRadius: '0.375rem', borderWidth: '0px', paddingTop: '0.375rem', paddingBottom: '0.375rem',
          }}
          renderInput={params => <TextField {...params} label="Users" />}
          onChange={handleAutocomplete}
        />
        <button
          type="button"
          className="block rounded-md bg-blue-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 h-12"
          onClick={addUsersAsManager}
        >
          {t('admin.addManager')}
        </button>
      </div>

      <div className="mt-8 flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <Disclosure as="div" className="px-4 sm:px-6 lg:px-8">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                  <span>Lista de usuarios</span>
                  <ChevronDoubleDownIcon
                    className={`${open ? 'transform rotate-180' : ''} w-5 h-5 text-blue-500`}
                  />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                  <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 lg:pl-8"
                            >
                              {t('generic.name')}
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              {t('generic.email')}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {(userList.length > 0
                            ? userList.map(user => (
                              <tr key={user.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                                  {user.firstname}
                                  {' '}
                                  {user.lastname}
                                </td>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8">
                                  {user.email}
                                </td>
                              </tr>
                            ))
                            : null
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <div className="mt-8 flex justify-end">
            <button
              type="button"
              className="block rounded-md bg-red-600 py-3 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              onClick={() => { setDisplayDialog(true) }}
            >
              Delete Group
            </button>
          </div>
        </div>
        <DeleteGroup
          open={displayDialog}
          setOpen={setDisplayDialog}
          setSuccess={setSuccess}
          group={group}
          setGroup={setGroup}
        />
      </div>
    </div>
  )
}
