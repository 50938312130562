import { redirect } from 'react-router-dom'

import { request } from './axios'
import { changeLanguage } from './i18n'
import { loadUserQuery } from './queries'

export const requireLoggedIn = async queryClient => {
  if (!localStorage.getItem('token')) {
    throw redirect('/login')
  } else {
    const query = loadUserQuery()
    const user = (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    )
    return user
  }
}

export const requireManager = async queryClient => {
  if (!localStorage.getItem('token')) {
    throw redirect('/login')
  } else {
    const query = loadUserQuery()
    const user = (
      queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
    )
    if (user.is_manager <= 0) {
      throw redirect('/')
    }
    return user
  }
}

export const requireApproved = async user => {
  if (user.is_manager > 0 && user.is_approved <= 0) {
    throw redirect('/manager/waitlist')
  } else if (user.is_manager == 0 && user.is_approved <= 0) {
    throw redirect('/user/waitlist')
  }
}

export const requireAdmin = async queryClient => {
  const query = loadUserQuery()
  const user = (
    queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
  )
  if (user.is_manager < 90) {
    throw redirect('/')
  }
  return user
}

export const canSkipAuth = async () => {
  const token = localStorage.getItem('token')
  if (token) {
    const user = await request({ url: 'load_user', method: 'get' })

    changeLanguage(user.language)
    if ((user.is_manager > 0) && (user.is_manager < 90)) {
      throw redirect('/manager/dashboard')
    } else if (user.is_manager >= 90) {
      throw redirect('/admin')
    } else {
      throw redirect('/user')
    }
  }
  return null
}
