import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline'
import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { requireApproved, requireManager } from '../../utils/checks'
import { getActiveChainsQuery, getPersonnelQuery } from '../../utils/queries'
import EditChain from './EditChain'
import ItrStates from './ItrStates'

export const activeChainsLoader = queryClient => async () => {
  const user = await requireManager(queryClient)
  await requireApproved(user)
  const query = getActiveChainsQuery()
  return queryClient.getQueryData(query.queryKey)
    ?? (await queryClient.fetchQuery(query))
}

export default function Chains() {
  const { data: chains } = useQuery(getActiveChainsQuery())
  const { data: personnel } = useQuery(getPersonnelQuery())

  const rolesAreCreated = personnel?.personnel?.length > 1

  const [editChainOpen, setEditChainOpen] = useState(false)
  const [chainToEdit, setChainToEdit] = useState({})
  const [success, setSuccess] = useState(false)

  const { t } = useTranslation()

  return (
    <>
      {
        editChainOpen && (
          <EditChain
            open={editChainOpen}
            setOpen={setEditChainOpen}
            chain={chainToEdit}
            setSuccess={setSuccess}
          />
        )
      }
      <div className="space-y-12 sm:space-y-16 md:mt-16 px-2 sm:px-4">
        <div className="flex flex-row justify-between">
          <h3 className="text-2xl font-semibold leading-6 text-gray-900 my-4">{t('manager.chains.activeItrs')}</h3>
          <div className="flex items-center">
            <Link
              to="history"
              className="ml-6 rounded-md  bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            >
              {t('generic.seeHistory')}
            </Link>
          </div>
        </div>
        <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">
          {chains.map(chain => {
            const {
              chain_name,
              active_itrs,
            } = chain
            return (
              <li key={chain.id_chain} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                <div className="flex w-full items-center justify-between space-x-6 p-6">
                  <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                      <h3 className="truncate text-md font-medium text-gray-900">{chain_name}</h3>
                    </div>
                  </div>
                  <button
                    type="button"
                    className="block rounded-md bg-teal-500/25 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-teal-400/50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                    onClick={() => { setEditChainOpen(true); setChainToEdit(chain) }}
                  >
                    <AdjustmentsHorizontalIcon className="h-6 w-6 text-gray-600" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="flex w-0 flex-1">
                      {active_itrs ? <ItrStates activeItrs={active_itrs} /> : null}
                    </div>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
        {!rolesAreCreated && !chains.length ? (
          (
            <Link to="../team/roles" className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
              <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 33 32" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z" />
              </svg>
              <span className="mt-2 block text-sm font-semibold text-gray-900">{t('manager.chains.createRolesFirst')}</span>
            </Link>
          )
        ) : (!chains.length ? (
          <Link to="new" className="relative block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
            <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 33 32" aria-hidden="true">
              <path d="M6.55157 2V4.25M16.7427 2V4.25M2.91187 17.75V6.5C2.91187 5.90326 3.14195 5.33097 3.55149 4.90901C3.96104 4.48705 4.5165 4.25 5.09569 4.25H18.1986C18.7778 4.25 19.3333 4.48705 19.7428 4.90901C20.1524 5.33097 20.3825 5.90326 20.3825 6.5V17.75M2.91187 17.75C2.91187 18.3467 3.14195 18.919 3.55149 19.341C3.96104 19.7629 4.5165 20 5.09569 20H18.1986C18.7778 20 19.3333 19.7629 19.7428 19.341C20.1524 18.919 20.3825 18.3467 20.3825 17.75M2.91187 17.75V10.25C2.91187 9.65326 3.14195 9.08097 3.55149 8.65901C3.96104 8.23705 4.5165 8 5.09569 8H18.1986C18.7778 8 19.3333 8.23705 19.7428 8.65901C20.1524 9.08097 20.3825 9.65326 20.3825 10.25V17.75M11.6472 11.75H11.6549V11.758H11.6472V11.75ZM11.6472 14H11.6549V14.008H11.6472V14ZM11.6472 16.25H11.6549V16.258H11.6472V16.25ZM9.46334 14H9.4711V14.008H9.46334V14ZM9.46334 16.25H9.4711V16.258H9.46334V16.25ZM7.27951 14H7.28728V14.008H7.27951V14ZM7.27951 16.25H7.28728V16.258H7.27951V16.25ZM13.831 11.75H13.8387V11.758H13.831V11.75ZM13.831 14H13.8387V14.008H13.831V14ZM13.831 16.25H13.8387V16.258H13.831V16.25ZM16.0148 11.75H16.0226V11.758H16.0148V11.75ZM16.0148 14H16.0226V14.008H16.0148V14Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M24.2647 18V30M30.0882 24H18.4412" stroke="#60a5fa" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span className="mt-2 block text-sm font-semibold text-gray-900">{t('manager.chains.createChain')}</span>
          </Link>
        ) : null)}
      </div>
    </>
  )
}
