/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable max-len */
import { Disclosure } from '@headlessui/react'
import {
  ChevronUpIcon,
} from '@heroicons/react/20/solid'
import {
  ArrowPathIcon, ArrowUpTrayIcon, CalendarDaysIcon, MapPinIcon, UserIcon,
} from '@heroicons/react/24/outline'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

import AcceptIcon from '../../../assets/AcceptIcon'
import ExchangeIcon from '../../../assets/ExchangeIcon'
import Alert from '../../../components/FormFeedback/Alert'
import { request } from '../../../utils/axios'
import ConfirmReject from './ConfirmReject'

function UserShiftExchangeMyRequests() {
  const [my_requests, feed, shiftOutputs, allShifts] = useOutletContext()
  const { completed, requests, responses } = my_requests
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const queryClient = useQueryClient()

  const [confirmRejectOpen, setConfirmRejectOpen] = useState(false)
  const [idRequest, setIdRequest] = useState(null)
  const [idResponse, setIdResponse] = useState(null)

  const { t } = useTranslation()

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const statusOrder = {
    pending_doctor: 1,
    pending_manager: 2,
    rejected_doctor: 3,
    rejected_manager: 4,
    canceled_system: 5,
  }

  const cancelRequest = useMutation({
    mutationFn: id_request => request({ url: `/user/shift_exchange/request/${id_request}/cancel_request`, method: 'patch' }),
    onSuccess: () => {
      queryClient.invalidateQueries(['userExchangeDashboard'])
      setSuccess(t('user.shiftExchange.successCancelRequest'))
      setError(false)
    },
    onError: () => {
      setError(t('user.shiftExchange.errorCancelRequest'))
      setSuccess(false)
    },
  })

  const rejectResponse = useMutation({
    mutationFn: ({ id_request, id_response }) => request({ url: `/user/shift_exchange/request/${id_request}/response/${id_response}/requestor_reject_response`, method: 'patch' }),
    onSuccess: () => {
      queryClient.invalidateQueries(['userExchangeDashboard'])
      setSuccess(t('user.shiftExchange.successRejectResponse'))
      setError(false)
      setConfirmRejectOpen(false)
    },
    onError: () => {
      setError(t('user.shiftExchange.errorRejectResponse'))
      setSuccess(false)
    },
  })

  const acceptResponse = useMutation({
    mutationFn: ({ id_request, id_response }) => request({ url: `/user/shift_exchange/request/${id_request}/response/${id_response}/requestor_accept_response`, method: 'patch' }),
    onSuccess: () => {
      queryClient.invalidateQueries(['userExchangeDashboard'])
      setSuccess(t('user.shiftExchange.successAcceptResponse'))
      setError(false)
    },
    onError: () => {
      setError(t('user.shiftExchange.errorAcceptResponse'))
      setSuccess(false)
    },
  })

  const cancelResponse = useMutation({
    mutationFn: ({ id_request, id_response }) => request({ url: `/user/shift_exchange/request/${id_request}/response/${id_response}/respondent_cancel_response`, method: 'patch' }),
    onSuccess: () => {
      queryClient.invalidateQueries(['userExchangeDashboard'])
      setSuccess(t('user.shiftExchange.successCancelResponse'))
      setError(false)
    },
    onError: () => {
      setError(t('user.shiftExchange.errorCancelResponse'))
      setSuccess(false)
    },
  })

  const revertResponse = useMutation({
    mutationFn: ({ id_request, id_response }) => request({ url: `/user/shift_exchange/request/${id_request}/response/${id_response}/requestor_revert_response`, method: 'patch' }),
    onSuccess: () => {
      queryClient.invalidateQueries(['userExchangeDashboard'])
      setSuccess(t('user.shiftExchange.successRevertAcceptance'))
      setError(false)
    },
    onError: () => {
      setError(t('user.shiftExchange.errorRevertAccepetance'))
      setSuccess(false)
    },
  })

  return (
    <>
      {error ? <Alert text={error} /> : null}
      {success ? <Alert text={success} success /> : null}
      <div className="my-6 mx-2 min-w-[45%] flex flex-col gap-4">
        {confirmRejectOpen ? <ConfirmReject open={confirmRejectOpen} setOpen={setConfirmRejectOpen} id_request={idRequest} id_response={idResponse} rejectResponse={rejectResponse} /> : null}
        {/* MY REQUESTS */}
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-teal-500 px-4 py-2 text-left text-sm font-medium text-white hover:bg-teal-400 focus:outline-none focus-visible:ring focus-visible:ring-teal-500 focus-visible:ring-opacity-75">
                <span>{t('user.shiftExchange.myPendingRequests')}</span>
                <ChevronUpIcon
                  className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="sm:px-4 px-1 pb-2 sm:text-sm text-xs text-gray-500" id="myExchanges-requests">
                <div className="flex flex-row justify-between sm:text-xl text-md text-black mb">
                  <h3>{t('user.shiftExchange.shiftsIOffer')}</h3>
                  <h3>{t('user.shiftExchange.responses')}</h3>
                </div>
                {
                  Object.values(my_requests.requests).every(req => req.length === 0) ? (
                    <p className="mt-6 text-center text-gray-600">{t('user.shiftExchange.emptyRequests')}</p>
                  ) : null
                }
                {
                  Object.entries(requests).map(([id_itr, requests]) => (
                    requests.map(({
                      candidate_responses, reason, status, id_shift, shift_name, start_time, id_request, allow_accepts, allow_trades, ...rest_request
                    }) => {
                      const startTime = new Date(start_time)
                      return (
                        <div className="flex flex-row mt-5 items-center pb-4 border-b border-gray-500">
                          <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 w-[45%] sm:mr-4">
                            <div className="flex sm:flex-row flex-col justify-between items-center">
                              <div>
                                <div className="flex flex-row items-start gap-1">
                                  <CalendarDaysIcon className="h-4 mt-0.5" />
                                  <p>
                                    {startTime.toLocaleString(undefined, {
                                      day: '2-digit',
                                      month: 'short',
                                      timeZone: 'GMT',
                                    })}
                                  </p>
                                </div>
                                <div className="flex flex-row items-start gap-1">
                                  <MapPinIcon className="h-4" />
                                  <p>
                                    {' '}
                                    {shift_name}
                                  </p>
                                </div>
                              </div>
                              <button
                                type="button"
                                className="mt-2 sm:mt-0 inline-flex w-full my-auto justify-center items-center rounded-md bg-white px-3 py-2 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-blue-400 hover:bg-blue-400/50 sm:ml-3 sm:w-auto"
                                onClick={() => cancelRequest.mutate(id_request)}
                              >
                                {t('generic.cancel')}
                              </button>
                            </div>
                            <p className="my-3">
                              <span className="font-semibold">{t('generic.justification')}</span>
                              {' '}
                              {reason}
                            </p>
                            <div className="flex flex-row gap-1">
                              {
                                allow_accepts ? (
                                  <div className="border border-yellow-600 bg-yellow-200 text-gray-600 rounded-md p-1">
                                    <ArrowPathIcon className="h-3" />
                                  </div>
                                ) : null
                              }
                              {
                                allow_trades ? (
                                  <div className="border border-purple-600 bg-purple-200 text-gray-600 rounded-md p-1">
                                    <ArrowUpTrayIcon className="rotate-90 h-3" />
                                  </div>
                                ) : null
                              }
                            </div>
                          </div>
                          <div className="flex flex-col gap-3">
                            {/* map over responses for each request */}
                            {candidate_responses.sort((a, b) => statusOrder[a.status] - statusOrder[b.status]).map(({
                              id_shift_trade, type, status, id_request, id_response, start_time, shift_name, user, ...rest_response
                            }) => (
                              <div className="flex flex-row items-center">
                                {
                                  type === 'one_for_one' ? (
                                    <>
                                      <div className="hidden sm:block">
                                        <ExchangeIcon status={status} />
                                      </div>
                                      <div className="block sm:hidden">
                                        <ExchangeIcon status={status} small />
                                      </div>
                                    </>
                                  ) : (
                                    (status === 'rejected_doctor') || (status === 'rejected_manager')
                                      ? (
                                        <>
                                          <div className="hidden sm:block">
                                            <AcceptIcon status="rejected_right" />
                                          </div>
                                          <div className="block sm:hidden">
                                            <AcceptIcon status="rejected_right" small />
                                          </div>
                                        </>
                                      )
                                      : (
                                        <>
                                          <div className="hidden sm:block">
                                            <AcceptIcon status="pending_right" />
                                          </div>
                                          <div className="block sm:hidden">
                                            <AcceptIcon status="pending_right" small />
                                          </div>
                                        </>
                                      )
                                  )
                                }
                                <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:ml-4 ml-2 my-auto">
                                  <div className="flex flex-row gap-2 items-center my-3">
                                    {type === 'one_for_one' ? (
                                      <div className="border border-yellow-600 bg-yellow-200 text-gray-600 rounded-md p-1">
                                        <ArrowPathIcon className="sm:h-5 h-3" />
                                      </div>
                                    ) : (
                                      <div className="border border-purple-600 bg-purple-200 text-gray-600 rounded-md p-1">
                                        <ArrowUpTrayIcon className="rotate-90 sm:h-5 h-3" />
                                      </div>
                                    )}
                                    <div className="flex flex-col justify-between items-start">
                                      {
                                        type === 'one_for_one' ? (
                                          <div className="flex sm:flex-row flex-col justify-between sm:gap-2">
                                            <div className="flex flex-row items-center gap-1">
                                              <CalendarDaysIcon className="h-4 mt-0.5" />
                                              <p>
                                                {new Date(start_time).toLocaleString(undefined, {
                                                  day: '2-digit',
                                                  month: 'short',
                                                  timeZone: 'GMT',
                                                })}
                                              </p>
                                            </div>
                                            <div className="flex flex-row items-center gap-1">
                                              <MapPinIcon className="h-4" />
                                              <p>
                                                {' '}
                                                {shift_name}
                                              </p>
                                            </div>
                                          </div>
                                        ) : null
                                      }
                                      <div className="flex flex-row items-center gap-1">
                                        <UserIcon className="h-4" />
                                        <p>
                                          {`${user.firstname} ${user.lastname}`}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="flex flex-col justify-between items-center">
                                    {status === 'pending_doctor' ? (
                                      <div className="flex sm:flex-row flex-col gap-3">
                                        <button
                                          type="button"
                                          className="border border-green-600 bg-green-200 inline-flex w-full my-auto justify-center items-center rounded-md px-3 py-2 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm hover:bg-green-100 sm:w-auto"
                                          onClick={() => acceptResponse.mutate({ id_request, id_response })}
                                        >
                                          {type === 'one_for_one' ? t('user.shiftExchange.trade') : t('user.shiftExchange.give')}
                                        </button>
                                        <button
                                          type="button"
                                          className="border border-red-600 bg-red-200 inline-flex w-full my-auto justify-center items-center rounded-md px-3 py-2 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm hover:bg-red-100 sm:w-auto"
                                          onClick={() => { setConfirmRejectOpen(true); setIdRequest(id_request); setIdResponse(id_response) }}
                                        >
                                          {t('generic.reject')}
                                        </button>
                                      </div>
                                    ) : status === 'pending_manager' ? (
                                      <div className="flex flex-col gap-2">
                                        <button
                                          type="button"
                                          className="inline-flex w-full my-auto justify-center items-center rounded-md bg-white px-3 py-2 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-blue-400 hover:bg-blue-400/50 sm:w-auto"
                                          onClick={() => revertResponse.mutate({ id_request, id_response })}
                                        >
                                          {t('user.shiftExchange.revertAccept')}
                                        </button>
                                      </div>
                                    ) : null}
                                  </div>
                                  {status === 'pending_manager' ? (
                                    <p className="text-xs text-orange-400 mt-2">{t('generic.shiftExchange.pendingManager')}</p>
                                  ) : status === 'rejected_manager' ? (
                                    <p className="text-xs text-red-400">{t('generic.shiftExchange.rejectedManager')}</p>
                                  ) : status === 'rejected_doctor' ? (
                                    <p className="text-xs text-red-400">{t('generic.shiftExchange.rejectedYou')}</p>
                                  ) : status === 'canceled_system' ? (
                                    <p className="text-xs text-red-400">{t('generic.shiftExchange.canceledSystem')}</p>
                                  ) : null}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      )
                    })))
                }
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {/* MY RESPONSES */}
        <Disclosure defaultOpen>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-teal-500 px-4 py-2 text-left text-sm font-medium text-white hover:bg-teal-400 focus:outline-none focus-visible:ring focus-visible:ring-teal-500 focus-visible:ring-opacity-75">
                <span>{t('user.shiftExchange.myPendingResponses')}</span>
                <ChevronUpIcon
                  className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="sm:px-4 px-1 pt-2 pb-2 sm:text-sm text-xs text-gray-500" id="myExchanges-responses">
                <div className="flex flex-row justify-between sm:text-xl text-xs text-black">
                  <h3>{t('user.shiftExchange.shiftsIOffer')}</h3>
                  <h3>{t('user.shiftExchange.shiftsIRequest')}</h3>
                </div>
                {
                  Object.values(my_requests.requests).every(req => req.length === 0) ? (
                    <p className="mt-6 text-center text-gray-600">{t('user.shiftExchange.emptyResponses')}</p>
                  ) : null
                }
                {
                  Object.entries(responses).map(([id_itr, responses]) => (
                    responses.sort((a, b) => statusOrder[a.status] - statusOrder[b.status]).map(({
                      request, id_response, id_shift_trade, type, start_time, shift_name, status, ...rest
                    }) => (
                      <div className="flex flex-row items-center mt-5">
                        <div className={classNames(type === 'one_for_one' ? 'border-slate-200' : 'border-slate-400 border-dashed', 'border shadow-md rounded-lg py-3 sm:px-4 px-1 w-[45%]  flex items-center')}>
                          <div className="flex flex-row justify-between items-center">
                            {type === 'one_for_one' ? (
                              <div className="flex flex-col justify-between items-start mr-3">
                                <div className="flex flex-row items-start gap-1">
                                  <CalendarDaysIcon className="h-4 mt-0.5" />
                                  <p>
                                    {new Date(start_time).toLocaleString(undefined, {
                                      day: '2-digit',
                                      month: 'short',
                                      timeZone: 'GMT',
                                    })}
                                  </p>
                                </div>
                                <div className="flex flex-row items-start gap-1">
                                  <MapPinIcon className="h-4" />
                                  <p>{shift_name}</p>
                                </div>
                              </div>
                            ) : (
                              null
                            )}
                            {(status === 'pending_manager') || (status === 'pending_doctor') ? (
                              <button
                                type="button"
                                className="inline-flex w-full my-auto justify-center items-center rounded-md bg-white px-3 py-2 sm:text-sm text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-blue-400 hover:bg-blue-400/50 sm:w-auto"
                                onClick={() => cancelResponse.mutate({ id_request: request.id_request, id_response })}
                              >
                                {t('generic.cancel')}
                              </button>
                            ) : null}
                          </div>
                        </div>
                        {
                          type === 'one_for_one' ? (
                            <>
                              <div className="hidden sm:block">
                                <ExchangeIcon status={status} />
                              </div>
                              <div className="block sm:hidden">
                                <ExchangeIcon status={status} small />
                              </div>
                            </>
                          ) : (
                            status === 'rejected_doctor' || status === 'rejected_manager' || status === 'canceled_system'
                              ? (
                                <>
                                  <div className="hidden sm:block">
                                    <AcceptIcon status="rejected_left" />
                                  </div>
                                  <div className="block sm:hidden">
                                    <AcceptIcon status="rejected_left" small />
                                  </div>
                                </>
                              )
                              : (
                                <>
                                  <div className="hidden sm:block">
                                    <AcceptIcon status="pending_left" />
                                  </div>
                                  <div className="block sm:hidden">
                                    <AcceptIcon status="pending_left" small />
                                  </div>
                                </>
                              )
                          )
                        }
                        <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 w-[45%] sm:mr-4 flex items-center">
                          <div className="flex flex-col justify-between items-start">
                            <div className="flex flex-row gap-2 items-center my-3">
                              {type === 'one_for_one' ? (
                                <div className="border border-yellow-600 bg-yellow-200 text-gray-600 rounded-md p-1">
                                  <ArrowPathIcon className="sm:h-5 h-3" />
                                </div>
                              ) : (
                                <div className="border border-purple-600 bg-purple-200 text-gray-600 rounded-md p-1">
                                  <ArrowUpTrayIcon className="-rotate-90 sm:h-5 h-3" />
                                </div>
                              )}
                              <div className="flex flex-col justify-between items-start">
                                <div className="flex sm:flex-row flex-col justify-between sm:gap-2">
                                  <div className="flex flex-row items-center gap-1">
                                    <CalendarDaysIcon className="h-4 mt-0.5" />
                                    <p>
                                      {new Date(request.start_time).toLocaleString(undefined, {
                                        day: '2-digit',
                                        month: 'short',
                                        timeZone: 'GMT',
                                      })}
                                    </p>
                                  </div>
                                  <div className="flex flex-row items-center gap-1">
                                    <MapPinIcon className="h-4" />
                                    <p>
                                      {' '}
                                      {request.shift_name}
                                    </p>
                                  </div>
                                </div>
                                {/* ) : null
                              } */}
                                <div className="flex flex-row items-center gap-1">
                                  <UserIcon className="h-4" />
                                  <p>
                                    {`${request.user.firstname} ${request.user.lastname}`}
                                  </p>
                                </div>
                              </div>
                            </div>
                            {status === 'pending_manager' ? (
                              <p className="text-xs text-orange-400">{t('generic.shiftExchange.pendingManager')}</p>
                            ) : status === 'pending_doctor' ? (
                              <p className="text-xs text-orange-400">{t('generic.shiftExchange.pendingDoctor')}</p>
                            ) : status === 'rejected_manager' ? (
                              <p className="text-xs text-red-400">{t('generic.shiftExchange.rejectedManager')}</p>
                            ) : status === 'rejected_doctor' ? (
                              <p className="text-xs text-red-400">{t('generic.shiftExchange.rejectedDoctor')}</p>
                            ) : status === 'canceled_system' ? (
                              <p className="text-xs text-red-400">{t('generic.shiftExchange.canceledSystem')}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ))))
                }
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        {/* COMPLETED */}
        <Disclosure id="myExchanges-completed">
          {({ open }) => (
            <div>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-teal-500 px-4 py-2 text-left text-sm font-medium text-white hover:bg-teal-400 focus:outline-none focus-visible:ring focus-visible:ring-teal-500 focus-visible:ring-opacity-75">
                <span>{t('generic.shiftExchange.completed')}</span>
                <ChevronUpIcon
                  className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="sm:px-4 px-1 pt-2 pb-2 text-xs sm:text-sm text-gray-500">
                <div className="flex flex-row justify-between sm:text-xl text-md text-black">
                  <h3>{t('user.shiftExchange.myShifts')}</h3>
                  <h3>{t('user.shiftExchange.shiftsIGave')}</h3>
                </div>
                {Object.values(my_requests.completed.requests).every(req => req.length === 0)
                  && Object.values(my_requests.completed.responses).every(res => res.length === 0) ? (
                  <p className="mt-6 text-center text-gray-600">{t('user.shiftExchange.emptyCompleted')}</p>
                ) : null}
                {/* User's completed requests */}
                {Object.entries(completed.requests).map(([id_itr, completed_requests]) => (
                  completed_requests.map(({
                    approved_response, id_shift, start_time, shift_name, ...rest
                  }) => (
                    approved_response.type === 'one_for_one'
                      ? (
                        <div className="justify-between mt-5 items-center grid grid-cols-[2fr_1fr_2fr]">
                          <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                            <div className="flex flex-col justify-between items-start">
                              <div className="flex flex-row items-start gap-1">
                                <CalendarDaysIcon className="h-4 mt-0.5" />
                                <p>
                                  {new Date(approved_response.start_time).toLocaleString(undefined, {
                                    day: '2-digit',
                                    month: 'short',
                                    timeZone: 'GMT',
                                  })}
                                </p>
                              </div>
                              <div className="flex flex-row items-start gap-1">
                                <MapPinIcon className="h-4" />
                                <p>{approved_response.shift_name}</p>
                              </div>
                            </div>
                          </div>
                          <div className="hidden sm:block mx-auto">
                            <ExchangeIcon status="approved" />
                          </div>
                          <div className="block sm:hidden mx-auto">
                            <ExchangeIcon status="approved" small />
                          </div>
                          <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                            <div className="flex flex-col justify-between items-start">
                              <div className="flex flex-row items-start gap-1">
                                <CalendarDaysIcon className="h-4 mt-0.5" />
                                <p>
                                  {new Date(start_time).toLocaleString(undefined, {
                                    day: '2-digit',
                                    month: 'short',
                                    timeZone: 'GMT',
                                  })}
                                </p>
                              </div>
                              <div className="flex flex-row items-start gap-1">
                                <MapPinIcon className="h-4" />
                                <p>{shift_name}</p>
                              </div>
                              <div className="flex flex-row items-center gap-1">
                                <UserIcon className="h-4" />
                                <p>
                                  {`${approved_response.user.firstname} ${approved_response.user.lastname}`}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                      : (
                        <div className="justify-between mt-5 items-center grid grid-cols-[2fr_1fr_2fr]">
                          <div className="border border-slate-400 border-dashed shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4 h-full flex items-center" />
                          <div className="hidden sm:block mx-auto">
                            <AcceptIcon status="completed_right" />
                          </div>
                          <div className="block sm:hidden mx-auto">
                            <AcceptIcon status="completed_right" small />
                          </div>
                          {/* shift that I gave away */}
                          <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                            <div className="flex flex-col justify-between items-start">
                              <div className="flex flex-row items-start gap-1">
                                <CalendarDaysIcon className="h-4 mt-0.5" />
                                <p>
                                  {new Date(start_time).toLocaleString(undefined, {
                                    day: '2-digit',
                                    month: 'short',
                                    timeZone: 'GMT',
                                  })}
                                </p>
                              </div>
                              <div className="flex flex-row items-start gap-1">
                                <MapPinIcon className="h-4" />
                                <p>
                                  {' '}
                                  {shift_name}
                                </p>
                              </div>
                              <div className="flex flex-row items-center gap-1">
                                <UserIcon className="h-4" />
                                <p>
                                  {`${approved_response.user.firstname} ${approved_response.user.lastname}`}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  ))))}
                {/* User's completed responses */}
                {Object.entries(completed.responses).map(([id_itr, completed_responses]) => (
                  completed_responses.map(({
                    approved_response, request, id_shift_trade, type, start_time, shift_name, status, ...rest
                  }) => (
                    // approved, or rejected/canceled one-for-one REVIEWWWW TODO
                    type === 'one_for_one'
                      ? (
                        <div className="justify-between mt-5 items-center grid grid-cols-[2fr_1fr_2fr]">
                          <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                            <div className="flex flex-col justify-between items-start">
                              <div className="flex flex-row items-start gap-1">
                                <CalendarDaysIcon className="h-4 mt-0.5" />
                                <p>
                                  {new Date(request.start_time).toLocaleString(undefined, {
                                    day: '2-digit',
                                    month: 'short',
                                    timeZone: 'GMT',
                                  })}
                                </p>
                              </div>
                              <div className="flex flex-row items-start gap-1">
                                <MapPinIcon className="h-4" />
                                <p>
                                  {' '}
                                  {request.shift_name}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="hidden sm:block">
                            <ExchangeIcon status={status} />
                          </div>
                          <div className="block sm:hidden">
                            <ExchangeIcon status={status} small />
                          </div>
                          <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                            <div className="flex flex-col justify-between items-start">
                              <div className="flex flex-row items-start gap-1">
                                <CalendarDaysIcon className="h-4 mt-0.5" />
                                <p>
                                  {new Date(start_time).toLocaleString(undefined, {
                                    day: '2-digit',
                                    month: 'short',
                                    timeZone: 'GMT',
                                  })}
                                </p>
                              </div>
                              <div className="flex flex-row items-start gap-1">
                                <MapPinIcon className="h-4" />
                                <p>
                                  {' '}
                                  {shift_name}
                                </p>
                              </div>
                              <div className="flex flex-row items-center gap-1">
                                <UserIcon className="h-4" />
                                <p>
                                  {`${request.user.firstname} ${request.user.lastname}`}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                      // approved, or rejected/canceled one-for-zero
                      : (
                        <div className="justify-between mt-5 items-center grid grid-cols-[2fr_1fr_2fr]">
                          <div className="border border-slate-200 shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4">
                            <div className="flex flex-col justify-between items-start">
                              <div className="flex flex-row items-start gap-1">
                                <CalendarDaysIcon className="h-4 mt-0.5" />
                                <p>
                                  {new Date(request.start_time).toLocaleString(undefined, {
                                    day: '2-digit',
                                    month: 'short',
                                    timeZone: 'GMT',
                                  })}
                                </p>
                              </div>
                              <div className="flex flex-row items-start gap-1">
                                <MapPinIcon className="h-4" />
                                <p>
                                  {' '}
                                  {request.shift_name}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="hidden sm:block">
                            <AcceptIcon status="completed_left" />
                          </div>
                          <div className="block sm:hidden">
                            <AcceptIcon status="completed_left" small />
                          </div>
                          <div className="border border-slate-400 border-dashed shadow-md rounded-lg py-3 sm:px-4 px-1 sm:mr-4 h-full flex items-center">
                            <div className="flex flex-row items-center gap-1">
                              <UserIcon className="h-4" />
                              <p>
                                {`${request.user.firstname} ${request.user.lastname}`}
                              </p>
                            </div>
                          </div>
                        </div>
                      )
                  ))))}
              </Disclosure.Panel>
            </div>
          )}
        </Disclosure>
      </div>
    </>
  )
}

export default UserShiftExchangeMyRequests
