/* eslint-disable jsx-a11y/control-has-associated-label */
import { Popover } from '@headlessui/react'
import { ChevronDownIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'
import React from 'react'

import ImbalanceBar from './ImbalanceBar'

export function GroupStatsTable({
  users, statsPerUser, baseShifts, selectedShifts, toggleShiftSelection, userImbalances,
}) {
  const { t } = useTranslation()

  const groupedUsers = React.useMemo(() => Object.entries(users).reduce((acc, [userId, userInfo]) => {
    const { role } = userInfo
    if (!acc[role]) {
      acc[role] = []
    }
    acc[role].push({ userId, ...userInfo })
    return acc
  }, {}), [users])

  const headerRowsClassName = 'py-4 px-1.5 text-left text-sm font-semibold border-b border-r text-center'
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-4 overflow-x-scroll max-h-[75vh]">
        <table className="min-w-full max-w-none border-collapse">
          <thead>
            <tr className="bg-white sticky top-0 z-0">
              <th className={headerRowsClassName} />
              <th className={`${headerRowsClassName} text-teal-600 bg-teal-600/5`}>
                {t('manager.stats.totalShifts')}
              </th>
              <th className={`${headerRowsClassName} bg-yellow-600/5`}>
                <p className="text-yellow-600 font-semibold">{t('manager.stats.shiftsPerSection')}</p>
                <div className="grid grid-flow-col gap-1">
                  {Object.entries(baseShifts)
                    .map(([id, { name, acronym }]) => <p key={id}>{acronym || name}</p>)}
                </div>
              </th>
              <th className={`${headerRowsClassName} bg-blue-600/5`}>
                <p className="text-blue-600 font-semibold">{t('manager.stats.shiftsPerDay')}</p>
                <div className="grid grid-flow-col gap-1">
                  <p>L</p>
                  <p>M</p>
                  <p>X</p>
                  <p>J</p>
                  <p>V</p>
                  <p>S</p>
                  <p>D</p>
                </div>
              </th>
              <th className={`${headerRowsClassName} bg-purple-600/5 text-purple-600 font-semibold`}>
                {t('manager.stats.shortForVacations')}
              </th>
              <th className={`${headerRowsClassName} bg-fuchsia-600/5 text-fuchsia-600 font-semibold`}>
                {t('manager.stats.shortForEvents')}
              </th>
              <th className={`${headerRowsClassName} bg-gray-600/5`}>
                {t('manager.stats.shortForPoints')}
              </th>
              <th className={`${headerRowsClassName} bg-gray-600/5 relative`}>
                <Tooltip id="imbalance" place="top" className="max-w-48 absolute top-0 z-10" />
                <InformationCircleIcon className="absolute top-1 left-3 h-5 w-5 flex-shrink-0 text-gray-400 hover:text-gray-500" aria-hidden="true" data-tooltip-id="imbalance" data-tooltip-content={t('manager.stats.imbalanceInfo')} />
                <Popover as="div" className="relative inline-block text-left">
                  <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                    <div className="flex flex-col items-center gap-1">
                      <div className="flex flex-row">
                        <span>{t('generic.imbalance')}</span>
                        <ChevronDownIcon
                          className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </div>
                      {Object.keys(baseShifts).length === selectedShifts.size ? (
                        <span className="rounded bg-gray-200 px-1.5 py-0.5 text-xs font-semibold tabular-nums z-0 text-gray-700">
                          {t('generic.all')}
                        </span>
                      ) : null}
                    </div>
                  </Popover.Button>
                  <Popover.Panel
                    transition
                    className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <form className="space-y-4">
                      {Object.entries(baseShifts).map(([id, { name, acronym }]) => (
                        <div key={id} className="flex items-center">
                          <input
                            id={`filter-shifts-${id}`}
                            name={`shift-${id}[]`}
                            defaultValue={id}
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                            checked={selectedShifts.has(id)}
                            onChange={() => toggleShiftSelection(id)}
                          />
                          <label
                            className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                          >
                            {acronym || name}
                          </label>
                        </div>
                      ))}
                    </form>
                  </Popover.Panel>
                </Popover>
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(groupedUsers).map(([role, users]) => (
              <React.Fragment key={role}>
                {users.map(({ userId, name, role }, idx) => {
                  const isLastOfGroup = idx === users.length - 1
                  const {
                    statsPerShift,
                    statsPerSlot,
                    events,
                    vacations,
                    negativePercentage,
                    positivePercentage,
                  } = statsPerUser[userId] || {}

                  const orderedStatsPerShift = Object.keys(baseShifts)?.map(
                    id => statsPerShift?.find(stat => stat.idShift === id)?.value || 0,
                  )
                  return (
                    <tr key={userId} className={`divide-y ${isLastOfGroup ? 'border-b-2 border-b-black' : ''}`}>
                      <td className="sticky bg-white left-0 py-2 pl-4 pr-3 text-right text-sm font-semibold border-b border-r">
                        <Link to={`/manager/team/personnel/${userId}`} state={{ name, role, previous: 'stats' }}>
                          <p>{name}</p>
                          <p className="text-xs font-normal">{role}</p>
                        </Link>
                      </td>
                      <td className="border-r text-center px-1.5 bg-teal-600/5">
                        {statsPerShift?.map(shift => shift.value).reduce((acc, val) => acc + val, 0)}
                      </td>
                      <td className="border-r bg-yellow-600/5">
                        <div className="grid text-center" style={{ gridTemplateColumns: `repeat(${Object.keys(baseShifts).length}, minmax(60px, 1fr))` }}>
                          {orderedStatsPerShift?.map(stat => (
                            <p>{stat}</p>
                          ))}
                        </div>
                      </td>
                      <td className="border-r bg-blue-600/5">
                        <div className="grid grid-flow-col text-center px-1.5 gap-1">
                          {statsPerSlot?.map(stat => (
                            <p key={`${stat.value}-${stat.name}`}>{stat.value}</p>
                          ))}
                        </div>
                      </td>
                      <td className="border-r text-center px-1.5 bg-purple-600/5">{vacations}</td>
                      <td className="border-r text-center px-1.5 bg-fuchsia-600/5">{events}</td>
                      <td className="border-r px-1.5 bg-gray-600/5">
                        <div className="flex flex-row justify-around gap-2">
                          <p className="text-green-600">{positivePercentage}</p>
                          <p className="text-red-600">{negativePercentage}</p>
                        </div>
                      </td>
                      <td className="border-r px-1.5 bg-gray-600/5">
                        <ImbalanceBar
                          value={userImbalances[userId]?.toFixed(2)}
                          maxValue={Math.max(...Object.values(userImbalances).map(Math.abs))}
                        />
                      </td>
                    </tr>
                  )
                })}
              </React.Fragment>
            ))}
          </tbody>
        </table>

      </div>
    </div>
  )
}
