/* eslint-disable jsx-a11y/anchor-is-valid */
import { LockClosedIcon } from '@heroicons/react/24/outline'
import { useQuery } from '@tanstack/react-query'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { requireApproved, requireLoggedIn } from '../../utils/checks'
import { classNames, parseIterationDates } from '../../utils/helpers'
import { getIterationsHistoryQuery } from '../../utils/queries'

export const userIterationsHistoryLoader = queryClient => async () => {
  const user = await requireLoggedIn(queryClient)
  await requireApproved(user)
  const query = getIterationsHistoryQuery()
  return queryClient.getQueryData(query.queryKey)
    ?? (await queryClient.fetchQuery(query))
}

export default function UserChainsHistory() {
  const { data: itrs } = useQuery(getIterationsHistoryQuery())

  const { t } = useTranslation()

  const status_classes = {
    configuration: ['text-blue-800 bg-blue-50 ring-blue-600/20', t('generic.itrStatuses.configuration')],
    'failed-algo': ['text-red-800 bg-red-50 ring-red-600/20', t('generic.itrStatuses.failedAlgo')],
    'running-algo': ['text-yellow-800 bg-yellow-50 ring-yellow-600/20', t('generic.itrStatuses.runningAlgo')],
    verification: ['text-violet-800 bg-violet-50 ring-violet-600/20', t('generic.itrStatuses.verification')],
    published: ['text-teal-800 bg-teal-50 ring-teal-600/20', t('generic.itrStatuses.published')],
    closed: ['text-stone-800 bg-stone-50 ring-stone-600/20', t('generic.itrStatuses.closed')],
    archived: ['text-stone-800 bg-stone-50 ring-stone-600/20', t('generic.itrStatuses.archived')],
  }

  return (
    <div className="space-y-6 md:mt-16 px-2 sm:px-4">
      <div className="flex flex-row justify-between">
        <h3 className="text-2xl font-semibold leading-6 text-gray-900 my-4">{t('manager.chains.history')}</h3>
      </div>
      <ul className="mt-2">
        {itrs.map(chain => (
          <div className="border-b border-b-gray-400/50">
            <p className="text-blue-600 font-bold mt-4">{chain.chain_name}</p>
            {chain.itrs.map(itr => (
              <li key={chain.id_chain} className="flex items-center justify-between gap-x-6 py-3">
                <div className="min-w-0">
                  <div className="flex items-start gap-x-3">
                    <p className="text-sm font-semibold leading-6 text-gray-900">{parseIterationDates(itr.start_day, itr.end_day, itr.itr_type)}</p>
                    <p
                      className={classNames(
                        status_classes[itr.status][0],
                        'rounded-md whitespace-nowrap mt-0.5 px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                      )}
                    >
                      {status_classes[itr.status][1]}
                    </p>
                  </div>
                  {/*
                  <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                    <p className="whitespace-nowrap">
                      Created on <time dateTime={itr.created}>{itr.created}</time>
                    </p>
                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    <p className="truncate">Type {itr.itr_type}</p>
                  </div> */}
                </div>
                <div className="flex flex-none items-center gap-x-4">
                  {
                    !itr.locked || itr.status === 'published'
                      ? (
                        <Link
                          to={`../iteration/${itr.id_itr}`}
                          className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                        >
                          {t('manager.chains.seeItr')}
                          <span className="sr-only">
                            ,
                            {itr.name}
                          </span>
                        </Link>
                      ) : (
                        <Link
                          to="#"
                          className="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block"
                        >
                          <LockClosedIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
                        </Link>
                      )
                  }

                </div>
                {/* <div className="flex w-0 flex-1">
                    {
                      itr.locked && itr.status !== 'published'
                        ? (
                          <button
                            disabled
                            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                          >
                            <LockClosedIcon className="h-5 w-5 text-red-600" aria-hidden="true" />
                            {t('user.iterations.locked')}
                          </button>
                        )
                        : (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <Link
                            to={itr.status === 'published' ? `/user/${itr.id_itr}/results` : `/user/${itr.id_itr}/preferences`}
                            className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                            state={{
                              itr_type: itr.itr_type,
                              start_day: itr.start_day,
                              end_day: itr.end_day,
                            }}
                          >
                            {
                              itr.status === 'configuration'
                                ? (
                                  <>
                                    <InboxArrowDownIcon className="h-5 w-5 text-blue-600 " aria-hidden="true" />
                                    {t('user.iterations.recordPreferences')}
                                  </>
                                )
                                : (
                                  <>
                                    <ChartBarIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
                                    {t('user.iterations.checkResults')}
                                  </>
                                )
                            }
                          </Link>
                        )
                    }
                  </div> */}
              </li>
            ))}
          </div>
        ))}
      </ul>
    </div>
  )
}
