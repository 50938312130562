import { Dialog, Transition } from '@headlessui/react'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { Fragment, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Alert from '../../../../components/FormFeedback/Alert'

export default function EditService({
  open,
  setOpen,
  shift,
  setSuccess,
  allShifts,
  eliminateShift,
  editShift,
  error,
  roles,
  setError,
}) {
  const cancelButtonRef = useRef(null)
  const [name, setName] = useState(shift?.name || '')
  const [description, setDescription] = useState(shift?.description || '')
  const [acronym, setAcronym] = useState(shift?.acronym || '')

  const { t } = useTranslation()

  const [checkedRoles, setcheckedRoles] = useState(shift.role_reqs.map(role_req => role_req.id))

  const handleCheckboxChange = roleId => {
    if (checkedRoles.includes(roleId)) {
      // Person is already checked, remove from the checkedRoles array
      setcheckedRoles(checkedRoles.filter(id => id !== roleId))
    } else {
      // Person is not checked, add to the checkedRoles array
      setcheckedRoles([...checkedRoles, roleId])
    }
  }

  const saveChanges = async () => {
    setSuccess(null)
    const clean_name = name.trim()
    if (clean_name === '' || description.trim() === '' || acronym.trim() === '') {
      setError(t('manager.servicesConfig.emptyFields'))
      return
    }

    if (acronym.length > 5) {
      setError(t('manager.servicesConfig.acronymLength'))
      return
    }

    // Avoid renaming to an already existing shift's name.
    if (allShifts.some(existing_shift => (existing_shift.name === clean_name) && (existing_shift.id_shift != shift.id))) {
      setError(t('manager.servicesConfig.shiftNameTaken'))
      setSuccess(null)
      return
    }

    const data = {
      shift_id: shift.id,
      shift_name: clean_name,
      description,
      shift_acronym: acronym,
      id_user_roles: checkedRoles,
    }
    await editShift.mutateAsync(data)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilSquareIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div className="my-4 text-center sm:mt-0 sm:ml-4 sm:text-left w-full mx-auto">
                    <Dialog.Title as="h3" className="text-lg mb-5 mt-2 text-blue-600 font-semibold leading-6">
                      {t('manager.servicesConfig.editShift')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="isolate -space-y-px rounded-md shadow-sm">
                        <div className="relative rounded-md rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label htmlFor="name" className="block text-xs font-medium text-gray-900">
                            {t('generic.firstName')}
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder={shift?.name}
                            value={name}
                            onChange={e => setName(e.target.value)}
                            maxLength={60}
                          />
                        </div>
                        <div className="relative rounded-md rounded-t-none rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label htmlFor="job-title" className="block text-xs font-medium text-gray-900">
                            {t('generic.description')}
                          </label>
                          <input
                            type="text"
                            name="description"
                            id="description"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder={shift?.description}
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                            maxLength={60}
                          />
                        </div>
                        <div className="relative rounded-md rounded-t-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label htmlFor="job-title" className="block text-xs font-medium text-gray-900">
                            {t('manager.servicesConfig.acronymPlaceholder')}
                          </label>
                          <input
                            type="text"
                            name="acronym"
                            id="acronym"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder={shift?.acronym}
                            value={acronym}
                            onChange={e => setAcronym(e.target.value)}
                          />
                        </div>
                      </div>
                      <fieldset className="mt-4">
                        <legend className="text-base font-semibold text-gray-900 mt-2 px-3">
                          {t('manager.servicesConfig.roles')}
                        </legend>
                        <div className="mt-2 border-b border-t border-gray-200 px-3 grid grid-cols-2">
                          {roles.map(person => (
                            <div key={person.id} className="relative flex items-start">
                              <div className="min-w-0 flex-1 text-sm leading-6">
                                <label htmlFor={`person-${person.id}`} className="select-none font-medium text-gray-900">
                                  {person.name}
                                </label>
                              </div>
                              <div className="ml-3 flex h-6 items-center">
                                <input
                                  id={`person-${person.id}`}
                                  name={`person-${person.id}`}
                                  type="checkbox"
                                  checked={checkedRoles.includes(person.id)}
                                  onChange={() => handleCheckboxChange(person.id)}
                                  className="h-4 w-4 mr-1 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </div>
                {error ? <Alert success={false} text={error} /> : null}
                <div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4 justify-between">
                  <div>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                      onClick={() => { saveChanges() }}
                    >
                      {t('generic.save')}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      {t('generic.cancel')}
                    </button>
                  </div>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500 sm:ml-3 sm:mt-0 sm:w-auto"
                    onClick={() => { eliminateShift.mutate(shift.id) }}
                  >
                    {t('generic.eliminate')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
