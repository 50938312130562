import React from 'react'
import { useTranslation } from 'react-i18next'
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride'
import { useNavigate } from 'react-router-dom'

import { useTutorial } from '../utils/TutorialContext'

function Tooltip({
  backProps, continuous, index, isLastStep, primaryProps, skipProps, step, tooltipProps, closeProps,
}) {
  const { t } = useTranslation()
  const joyrideLocale = {
    back: t('tutorials.back'),
    close: t('tutorials.close'),
    last: t('tutorials.last'),
    next: t('tutorials.next'),
    skip: t('tutorials.skip'),
  }

  const { currentTutorial } = useTutorial()

  const content = typeof step.content === 'object' ? step.content.message : step.content
  const props = typeof step.content === 'object' ? step.content.customProps.split(' ') : []

  return (
    <div
      {...tooltipProps}
      className="bg-white pb-6 px-6 pt-3 max-w-md min-w-[290px] rounded-md overflow-hidden shadow-lg flex flex-col"
    >
      <button
        {...skipProps}
        className="text-lg text-gray-600 hover:text-gray-800 place-self-end"
        aria-label={joyrideLocale.close}
      >
        &times;
      </button>
      <div className="mb-4 p-2">
        {step.title && (
          <h3 className="text-lg text-primary mb-4">
            {step.title}
          </h3>
        )}
        {content && <div>{t(`tutorials.${currentTutorial.id}.${content}`)}</div>}
      </div>
      <div className="bg-primary-100 p-2 flex justify-end items-center">
        <div className="flex gap-2">
          {index > 0 && !props.includes('hideBack') && (
            <button {...backProps} className="text-sm py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-500">
              {joyrideLocale.back}
            </button>
          )}
          {
            !props.includes('hideNext') && (
              <button {...primaryProps} className="text-sm py-2 px-4 bg-blue-600 text-white rounded hover:bg-blue-500">
                {joyrideLocale[continuous ? 'next' : 'close']}
              </button>
            )
          }

        </div>
      </div>
    </div>
  )
}

function TutorialComponent() {
  const {
    isRunning,
    currentTutorial,
    setStepIndex,
    stepIndex,
    completeTutorial, // You might add this method to handle completion
  } = useTutorial()

  const navigate = useNavigate()

  // Handling Joyride callbacks
  const handleJoyrideCallback = data => {
    const {
      action, status, index, type,
    } = data
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      completeTutorial(currentTutorial.id)
    } else if ([ACTIONS.NEXT, ACTIONS.PREV].includes(action) && [EVENTS.STEP_AFTER].includes(type)) {
      const nextStep = index + (action === ACTIONS.PREV ? -1 : 1)
      const nextTutorial = currentTutorial.steps[nextStep]
      if (nextTutorial?.route) {
        navigate(nextTutorial.route)
      }
      if (nextTutorial?.action) {
        nextTutorial.action()
      }
      setStepIndex(nextStep)
    }
  }

  const { t } = useTranslation()

  const joyrideLocale = {
    back: t('tutorials.back'),
    close: t('tutorials.close'),
    last: t('tutorials.last'),
    next: t('tutorials.next'),
    skip: t('tutorials.skip'),
  }

  const steps = () => {
    let tutorialSteps = currentTutorial?.steps || []

    if (currentTutorial?.params?.existingSections) {
      // Filter out steps for creating your first section
      tutorialSteps = tutorialSteps.filter(step => step.id !== 'createFirstShift' && step.id !== 'createShift')
    }

    if (currentTutorial?.params?.existingSlotSubsets) {
      tutorialSteps = tutorialSteps.filter(step => step.id !== 'createFirstSubsetSlot' && step.id !== 'createSlotSubset')
    }

    return tutorialSteps
  }

  return (
    <Joyride
      continuous
      scrollToFirstStep
      steps={steps()}
      run={isRunning}
      stepIndex={stepIndex}
      callback={data => handleJoyrideCallback(data)}
      styles={{
        options: {
          zIndex: 1000,
          primaryColor: 'rgb(37, 99, 235)',
          spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
        },
      }}
      locale={joyrideLocale}
      tooltipComponent={Tooltip}
    />
  )
}

export default TutorialComponent
