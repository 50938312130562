import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function SignUpDivider() {
  const { t } = useTranslation()

  const tiers = [
    {
      id: 'manager',
      text: t('auth.signUpDivider.managerText'),
      buttonText: t('auth.signUpDivider.managerButtonText'),
    },
    {
      id: 'user',
      text: t('auth.signUpDivider.userText'),
      buttonText: t('auth.signUpDivider.userButtonText'),
    },
  ]

  return (
    <div className="isolate overflow-hidden bg-gradient-to-b from-blue-600 to-teal-400">
      <div className="mx-auto max-w-7xl px-6 pb-80 pt-24 text-center sm:pt-32 lg:px-8">
        <div className="mx-auto max-w-4xl">
          <p className="mt-12 text-3xl font-bold tracking-tight text-white sm:text-5xl">
            {t('auth.signUpDivider.introText')}
          </p>
        </div>
      </div>
      <div className="flow-root bg-white pb-24 sm:pb-32">
        <div className="-mt-72 md:-mt-60">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
              {tiers.map(tier => (
                <div
                  key={tier.id}
                  className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                >
                  <div>
                    <div className="mt-4 flex items-baseline gap-x-2">
                      <p className="sm:text-5xl text-3xl font-semibold tracking-tight m-auto text-center text-gray-900">{tier.text}</p>
                    </div>
                  </div>
                  <Link
                    to={tier.id}
                    aria-describedby={tier.id}
                    className="mt-8 block rounded-md bg-teal-500 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                  >
                    {tier.buttonText}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
