import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function CTA() {
  const { t } = useTranslation()

  return (
    <div className="bg-gradient-to-r from-teal-600 to-teal-400">
      <div className="mx-auto max-w-7xl px-8 py-12 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {t('landing.cta.title')}
          <br />
          {t('landing.cta.subtitle1')}
          {' '}
          <span className="text-gray-700">{t('generic.free')}</span>
          {' '}
          {t('landing.cta.subtitle2')}
        </h2>
        <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
          <Link
            to="signup"
            className="rounded-md bg-blue-600 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            {t('landing.cta.action')}
          </Link>
        </div>
      </div>
    </div>
  )
}
