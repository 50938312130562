/* eslint-disable max-len */
import { useTranslation } from 'react-i18next'

import InputField from './QuantityInput'

function TableTabs({
  role, dispatch, locked,
}) {
  const { t } = useTranslation()

  return (
    <div className="flex flex-row border rounded-lg shadow-md z-50 bg-white">
      <nav className="isolate flex flex-col divide-y divide-gray-200 shadow cursor-pointer whitespace-nowrap" aria-label="Tabs">
        <button
          id="pointsInputs"
          className="text-gray-900 rounded-lg group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-center text-sm font-medium focus:z-10"
        >
          <span>{t('manager.rolesConfig.pointsAndWildcards')}</span>
          <span
            aria-hidden="true"
            className="bg-teal-500 absolute inset-y-0 right-0 w-0.5"
          />
        </button>
      </nav>
      <div className="flex flex-row items-center">
        <div
          className="px-3 py-4 text-sm text-gray-500 border-r dark:border-neutral-300"
        >
          <p className="mb-1">{t('manager.rolesConfig.points')}</p>
          <InputField
            value={role.base_points}
            dispatch={dispatch}
            property="base_points"
            roleId={role.id}
            disabled={!locked}
          />
        </div>
        <div
          className="px-3 py-4 text-sm text-gray-500 border-r dark:border-neutral-300"
        >
          <p className="mb-1">{t('manager.rolesConfig.wildcards')}</p>
          <InputField
            value={role.jokers}
            dispatch={dispatch}
            property="jokers"
            roleId={role.id}
            disabled={!locked}
          />
        </div>
      </div>
    </div>
  )
}

export default TableTabs
