import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import {
  Navigate, useOutletContext, useParams,
} from 'react-router-dom'

import { request } from '../../utils/axios'

export default function FailedAlgo() {
  const { id } = useParams()
  const queryClient = useQueryClient()
  const [status, locked, errors, infeasible] = useOutletContext()

  const { t } = useTranslation()

  const goBackToConfigMutation = useMutation({
    mutationFn: () => request({ url: `/manager/itrs/${id}/flow/failed_to_config`, method: 'post' }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['iteration', id] })
      queryClient.invalidateQueries({ queryKey: ['status', id] })
    },
  })

  return (
    <div className="flex flex-col content-center justify-center items-center my-16">
      {status === 'configuration' ? <Navigate to="../configuration" replace /> : null}
      <p className="text-4xl text-center mt-12 font-semibold">{t('manager.failedAlg.text')}</p>
      <p className="text-2xl text-center mb-12">{t('manager.failedAlg.text2')}</p>
      <div className="border border-red-500 p-6 rounded-lg mb-12 bg-red-50 mx-12">
        {errors ? errors.map(error => (
          <div className="flex flex-row items-start text-left mt-3 gap-3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-red-600">
              <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
            </svg>
            <p>{error}</p>
          </div>
        )) : t('manager.failedAlg.noErrors')}
        {infeasible?.map(comb => (
          <div className="flex flex-row items-center mt-3 gap-3 ml-5">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-amber-500">
              <path strokeLinecap="round" strokeLinejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
            </svg>
            <p>{comb}</p>
          </div>
        ))}
      </div>
      <button
        type="button"
        className="block w-64 rounded-md bg-blue-500 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        onClick={goBackToConfigMutation.mutate}
        disabled={goBackToConfigMutation.isLoading}
      >
        {t('manager.failedAlg.backToConfig')}
      </button>
      {/* <Logo className="h-64 block" /> */}
    </div>
  )
}
