import { useTranslation } from "react-i18next"

/* eslint-disable max-len */
export default function History() {
  const { t } = useTranslation()
  return (
    <div className="bg-white">
      <div className="mx-auto mt-36 mb-12 flex md:flex-row flex-col max-w-screen xl:px-36 md:px-12 sm:px-8 md:gap-12">
        {/* <div className="md:w-1/2 self-center w-auto">
          <div className="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0 self-center">
            <img
              className="bg-gray-50"
              src="https://youshift-docs.s3.eu-west-3.amazonaws.com/everyone.jpeg"
              alt="Team"
            />
          </div>
        </div> */}
        <div className="max-w-4xl mx-auto">
          <p className="text-base font-semibold leading-7 text-blue-600">{t('landing.history.title')}</p>
          <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('landing.history.subtitle')}</h1>
          <div className="mt-10 max-w-xl text-base leading-7 text-gray-700 lg:max-w-none text-justify">
            <p>
              {t('landing.history.1')}
            </p>
            <p className="mt-8">
              {t('landing.history.2')}
            </p>
            <p className="mt-8">
              {t('landing.history.3')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
