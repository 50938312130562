/* eslint-disable max-len */
export default function Stats() {
  // const { t } = useTranslation()
  // return (
  //   <div className="bg-white pt-12 sm:pt-20">
  //     <div className="mx-auto max-w-7xl px-12">
  //       <div className="mx-auto max-w-4xl lg:text-center">
  //         <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
  //           {t('landing.stats.title')}
  //           {' '}
  //           <span className="text-teal-600">{t('landing.stats.title2')}</span>
  //         </p>
  //         <p className="mt-6 text-xl leading-8 text-gray-600">
  //           {t('landing.stats.subtitle')}
  //         </p>
  //       </div>
  //       <div className="mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
  //         <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-blue-300 p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
  //           <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">250.000</p>
  //           <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
  //             <p className="text-lg font-semibold tracking-tight text-gray-900">médicos</p>
  //             <p className="mt-2 text-base leading-7 text-gray-600">
  //               Han mejorado su vida gracias a YouShift
  //             </p>
  //           </div>
  //         </div>
  //         <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-700 p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-32">
  //           <p className="flex-none text-3xl font-bold tracking-tight text-white">$8.9 billones</p>
  //           <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
  //             <p className="text-lg font-semibold tracking-tight text-white">
  //               de Euros
  //             </p>
  //             <p className="mt-2 text-base leading-7 text-gray-400">
  //               se ahorra un hospital de media al empezar a usar YouShift
  //             </p>
  //           </div>
  //         </div>
  //         <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-teal-500 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
  //           <p className="flex-none text-3xl font-bold tracking-tight text-white">97%</p>
  //           <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
  //             <p className="text-lg font-semibold tracking-tight text-white">de preferencias</p>
  //             <p className="mt-2 text-base leading-7 text-blue-200">
  //               respetadas por nuestro algoritmo.
  //             </p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // )
}
