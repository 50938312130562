import { Switch } from '@headlessui/react'
import { CursorArrowRippleIcon } from '@heroicons/react/24/outline'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

import { bgColor, textColor } from '../../../utils/constants'

export default function Day() {
  const [
    data, allShifts, setSelectedParticipant, setSelectedSection, setSelectedSlot, setOpen,
  ] = useOutletContext()
  const [showReqs, setShowReqs] = useState(false)

  const textColors = {}
  const bgColors = {}

  const { t } = useTranslation()

  Object.entries(data.shifts).forEach(([key], index) => {
    textColors[key] = textColor[index % textColor.length]
    bgColors[key] = bgColor[index % bgColor.length]
  })

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const scrollContainerRef = useRef(null)

  const parsedRoleReqs = () => {
    // roleReqs = {idRole: {idShift: {min: 0, max: 0}}
    const rolesReqs = {}
    data.itr_prefs.forEach(user => {
      const idRole = user.user.role.id
      if (!rolesReqs[idRole]) {
        rolesReqs[idRole] = {}
        allShifts.shifts.forEach(shift => {
          const roleReqs = shift.role_reqs.find(e => e.id === idRole)
          if (roleReqs) {
            rolesReqs[idRole][shift.id_shift] = {
              min: roleReqs.min_req,
              max: roleReqs.max_req,
            }
          }
        })
      }
    })
    return rolesReqs
  }

  const roleReqs = parsedRoleReqs()

  const parseWeekDay = start => {
    const weekdays = [t('calendars.sunday'), t('calendars.monday'), t('calendars.tuesday'), t('calendars.wednesday'), t('calendars.thursday'), t('calendars.friday'), t('calendars.saturday')]

    const date = new Date(start)
    const weekday = weekdays[date.getUTCDay()]
    const day = date.getUTCDate()

    return `${weekday} ${day}`
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex flex-row justify-between mt-8 mb-6 align-middle">
        <Switch.Group as="div" className="flex items-center gap-1">
          <Switch
            checked={showReqs}
            onChange={() => setShowReqs(!showReqs)}
            className={classNames(
              showReqs ? 'bg-blue-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2',
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                showReqs ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
              )}
            />
          </Switch>
          <Switch.Label as="span" className="text-sm flex ml-3 flex-row">
            <span className="font-medium text-gray-900">
              {t('manager.iterationVerification.showReqs')}
            </span>
          </Switch.Label>
        </Switch.Group>
        <div className="flex flex-row items-center gap-1">
          <CursorArrowRippleIcon className="text-teal-600 w-4" />
          <p className="text-gray-600 text-sm">{t('manager.iterationVerification.shiftReassignmentPersonExplanation')}</p>
        </div>
      </div>
      <div className="mt-4 overflow-x-auto h-[65vh]" ref={scrollContainerRef}>
        <table className="min-w-full">
          <tbody>
            {data.itr_prefs.sort((a, b) => `${a.user.firstname} ${a.user.lastname}`.localeCompare(`${b.user.firstname} ${b.user.lastname}`)).map(user => {
              // User details for easier access
              const { firstname, lastname, role } = user.user
              const userRoleReqs = roleReqs[user.id_user_role]
              return (
                <tr key={user?.id_user} className="divide-y">
                  <th
                    className="sticky border-r border-b w-1/4 left-0 z-10 py-4 cursor-pointer pl-4 pr-3 text-right text-sm font-semibold"
                    onClick={() => {
                      setSelectedParticipant(user?.id_user)
                      setSelectedSlot(null)
                      setSelectedSection(null)
                      setOpen(true)
                    }}
                  >
                    {
                      user.volunteer ? (<span className="mx-2 px-1 py-1 bg-black text-white font-bold rounded-xl text-xs w-4 h-4">V</span>
                      ) : null
                    }
                    {`${firstname} ${lastname}`}
                    <p className="text-xs font-normal">{role?.name}</p>
                    {showReqs ? (Object.keys(userRoleReqs).map(shift => {
                      const { min, max } = userRoleReqs[shift]
                      const shiftObj = allShifts?.shifts?.find(s => s?.id_shift == shift)
                      const acronym = shiftObj?.acronym || shiftObj?.name
                      return <p className="font-light text-xs" key={shift}>{`${acronym}: ${min}-${max}`}</p>
                    })) : null}
                  </th>
                  {
                    data.slots.map(slot => {
                      const userSlot = slot.shift_outputs[user.id_user]
                      const idShift = userSlot?.id_shift
                      const shift = data.shifts[idShift]
                      return (
                        userSlot && userSlot?.active
                          ? (
                            <td
                              className="px-4 py-2 cursor-pointer"
                              key={slot.id_slot}
                              onClick={() => {
                                setSelectedParticipant(user.id_user)
                                setSelectedSlot(slot.id_slot)
                                setSelectedSection(idShift)
                                setOpen(true)
                              }}
                            >
                              <div className="flex flex-col mx-auto text-center">
                                <p className="text-gray-700">{parseWeekDay(slot.start)}</p>
                                <p className={`${textColors[idShift]} ${bgColors[idShift]} p-1.5 rounded-md`}>{shift.acronym || shift.name}</p>
                              </div>
                            </td>
                          )
                          : null
                      )
                    })
                  }
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
