import { Dialog, Transition } from '@headlessui/react'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Fragment, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Alert from '../../../../components/FormFeedback/Alert'
import { request } from '../../../../utils/axios'

export default function CreateSlotSubset({
  open,
  setOpen,
  setSuccess,
  edit,
  nameToEdit,
  idToEdit,
  next,
  // selectNewSlotSubset,
  setselectedSlotSubset,
}) {
  const cancelButtonRef = useRef(null)
  const [name, setName] = useState(nameToEdit)
  const { id } = useParams()
  const [error, setError] = useState(null)

  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const createslotSubset = async () => request({ url: `/manager/itrs/${id}/slot_subsets/new`, method: 'post', data: { name } })
  const editslotSubsetName = async () => request({ url: `/manager/itrs/${id}/slot_subsets/${idToEdit}/edit_name`, method: 'patch', data: { name } })
  const deleteslotSubset = async () => request({ url: `/manager/itrs/${id}/slot_subsets/${idToEdit}/delete`, method: 'delete', data: { id: idToEdit } })

  const createslotSubsetMutation = useMutation({
    mutationFn: createslotSubset,
    onSuccess: slotSubset => {
      queryClient.invalidateQueries(['slotSubsets'])
      queryClient.invalidateQueries(['itrRoles', id])
      setOpen(false)
      // dispatch({ type: 'SET_REQS', payload: parseSlotSubsetReqs() })
      // selectNewSlotSubset(slotSubset.id_slot_subset)
      setSuccess(t('manager.slotSubsets.createSlotSubsetSuccess'))
      next('manager_iteration_configuration')
    },
    onError: () => {
      setError(t('generic.error'))
      setSuccess(null)
    },
  })

  const editslotSubsetMutation = useMutation({
    mutationFn: editslotSubsetName,
    onSuccess: () => {
      queryClient.invalidateQueries(['slotSubsets'])
      setOpen(false)
      setSuccess(t('manager.slotSubsets.editSlotSubsetSuccess'))
    },
    onError: () => {
      setError(t('generic.error'))
      setSuccess(null)
    },
  })

  const deleteslotSubsetMutation = useMutation({
    mutationFn: deleteslotSubset,
    onSuccess: () => {
      queryClient.invalidateQueries(['slotSubsets'])
      queryClient.invalidateQueries(['itrRoles', id])
      setOpen(false)
      setSuccess(t('manager.slotSubsets.deleteSlotSubsetSuccess'))
      setselectedSlotSubset(null)
    },
    onError: () => {
      setError(t('generic.error'))
      setSuccess(null)
    },
  })

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel id="createSlotSubset" className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilSquareIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div className="my-4 text-center sm:mt-0 sm:ml-4 sm:text-left w-full mx-auto">
                    <Dialog.Title as="h3" className="text-lg mb-5 mt-2 text-blue-600 font-semibold leading-6">
                      {edit ? t('manager.slotSubsets.edit') : t('manager.slotSubsets.add')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="isolate -space-y-px rounded-md shadow-sm">
                        <div className="relative rounded-md rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label htmlFor="name" className="block text-xs font-medium text-gray-900">
                            {t('generic.name')}
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder={t('generic.name')}
                            value={name}
                            onChange={e => setName(e.target.value)}
                            maxLength={60}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {error ? <Alert success={false} text={error} /> : null}
                <div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4 flex flex-row justify-between">
                  <div>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                      onClick={() => { nameToEdit ? editslotSubsetMutation.mutate() : createslotSubsetMutation.mutate() }}
                    >
                      {t('generic.save')}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      {t('generic.cancel')}
                    </button>
                  </div>
                  {
                    nameToEdit
                      ? (
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:w-auto disabled:bg-red-200"
                          onClick={() => deleteslotSubsetMutation.mutate()}
                        >
                          {t('generic.eliminate')}
                        </button>
                      )
                      : null
                  }
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
