/* eslint-disable react/no-array-index-key */
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import EventSquare from '../../../components/Calendars/EventSquare'
import { bgColor, textColor } from '../../../utils/constants'

function WeeklyRecordedData({
  data,
}) {
  const { t } = useTranslation()

  const comments = data.itr_prefs.filter(user => user.comment !== null)
    .map(user => (
      {
        comment: user.comment,
        name: `${user.user.firstname} ${user.user.lastname}`,
      }
    ))

  const parseWeekDay = start => {
    const weekdays = [t('calendars.sunday'), t('calendars.monday'), t('calendars.tuesday'), t('calendars.wednesday'), t('calendars.thursday'), t('calendars.friday'), t('calendars.saturday')]

    const date = new Date(start)
    const weekday = weekdays[date.getUTCDay()]
    const day = date.getUTCDate()

    return `${weekday} ${day}`
  }

  const textColors = {}
  const bgColors = {}

  Object.entries(data.shifts).forEach(([key], index) => {
    textColors[key] = textColor[index % textColor.length]
    bgColors[key] = bgColor[index % bgColor.length]
  })

  const scrollContainerRef = useRef(null)

  const scrollRight = () => {
    const scrollContainer = scrollContainerRef.current
    scrollContainer.scrollLeft += 100
  }

  const scrollLeft = () => {
    const scrollContainer = scrollContainerRef.current
    scrollContainer.scrollLeft -= 100
  }

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {comments.length > 0 ? (
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75">
                <span>{t('generic.comments')}</span>
                <ChevronUpIcon
                  className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                <ul>
                  {comments.map((comment, index) => (
                    <li key={index}>
                      <strong>
                        {comment.name}
                        :
                      </strong>
                      {' '}
                      {comment.comment}
                    </li>
                  ))}
                </ul>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ) : null}
      <div className="flex flex-row justify-between mb-6 align-middle mt-3">
        <div className="flex flex-row items-center justify-end gap-2">
          <button
            className="text-gray font-bold py-0 px-0 rounded mt-1"
            onClick={scrollLeft}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 bg-blue-500 text-white rounded">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </button>
          <button
            className="text-gray font-bold py-0 px-0 rounded mt-1"
            onClick={scrollRight}
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 bg-blue-500 text-white rounded">
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </button>
        </div>
      </div>
      <div className="mt-4 overflow-x-auto h-[65vh]" ref={scrollContainerRef}>
        <table className="min-w-full">
          <tbody>
            {/* DATES (first row) */}
            <tr className="sticky top-0 z-10">
              <th
                className="sticky left-0 z-10 bg-blue-100 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
              />
              {data.slot_prefs.map(slot => (
                <td
                  key={slot.id_slot}
                  className={classNames(slot.start.slice(0, 3) === 'Sun' ? 'border-r' : '', 'whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm font-medium text-gray-900 border-y border-gray-300 bg-white')}
                >
                  {parseWeekDay(slot.start)}
                </td>
              ))}
            </tr>
            {/* USERS rows */}
            {
              data.itr_prefs.sort((a, b) => `${a.user.firstname} ${a.user.lastname}`.localeCompare(`${b.user.firstname} ${b.user.lastname}`)).map(person => (
                <tr>
                  <th
                    className="sticky left-0 z-10 bg-gray-100 bg-opacity-75 py-4 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                    key={person.id_user}
                  >
                    {`${person.user.firstname} ${person.user.lastname}`}
                  </th>
                  {
                    data.slot_prefs.map(slot => {
                      const pref = slot.slot_prefs[person.id_user]
                      return (
                        <td
                          key={pref?.id_slot_pref}
                          className={classNames(slot.start.slice(0, 3) === 'Sun' ? 'border-r' : '', 'whitespace-nowrap mx-auto text-center py-2 text-gray-900 border-b border-gray-200')}
                        >
                          <div className="flex flex-col gap-3">
                            <EventSquare
                              e={pref.preference}
                              shift_acr={data?.shifts?.find(s => s.id_shift === pref.other_shift_id)?.acronym}
                              points={pref.points}
                              justPoints
                              small
                            />
                          </div>
                        </td>
                      )
                    })
                  }
                </tr>
              ))
            }
          </tbody>
        </table>
        {
          !data.itr_prefs.length
            ? (
              <div className="text-center mt-10">
                <svg
                  className="mx-auto h-12 w-12 text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
                <h2 className="mt-2 text-base font-semibold leading-6 text-gray-900">{t('manager.recordedData.emptyStateTitle')}</h2>
                <p className="mt-1 text-sm text-gray-500">{t('manager.recordedData.emptyStateSubtitle')}</p>
              </div>
            ) : null
        }
      </div>
    </div>
  )
}

export default WeeklyRecordedData
