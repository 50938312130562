import { useQuery } from '@tanstack/react-query'

import { classNames } from '../../utils/helpers'
import { getOrgsQuery } from '../../utils/queries'

export const orgsLoader = queryClient => async () => {
  const query = getOrgsQuery()
  return queryClient.getQueryData(query.queryKey)
    ?? (await queryClient.fetchQuery(query))
}

export default function AllOrgs() {
  const { data } = useQuery(getOrgsQuery())

  const orgs = data?.slice().sort((a, b) => {
    // First, sort by country
    if (a.country < b.country) return -1
    if (a.country > b.country) return 1

    // Then, sort by state
    if (a.state < b.state) return -1
    if (a.state > b.state) return 1

    // Then, sort by state
    if (a.province < b.province) return -1
    if (a.province > b.province) return 1

    // Finally, sort by city
    if (a.city < b.city) return -1
    if (a.city > b.city) return 1

    return 0 // If all are equal
  })

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <table className="min-w-full border-separate border-spacing-0">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                  >
                    Country
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    State
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter"
                  >
                    Province
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                  >
                    City
                  </th>
                  <th
                    scope="col"
                    className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-3 pr-4 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                  >
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {orgs && orgs.map((org, idx) => (
                  <tr key={org.id_org}>
                    <td className={classNames(idx !== orgs.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8')}>
                      {org.name}
                    </td>
                    <td className={classNames(idx !== orgs.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>
                      {org.country}
                    </td>
                    <td className={classNames(idx !== orgs.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>
                      {org.state}
                    </td>
                    <td className={classNames(idx !== orgs.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>
                      {org.province}
                    </td>
                    <td className={classNames(idx !== orgs.length - 1 ? 'border-b border-gray-200' : '', 'whitespace-nowrap px-3 py-4 text-sm text-gray-500')}>
                      {org.city}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
