import { useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import logo from '../../assets/Logo/logo_lq.png'
import Alert from '../../components/FormFeedback/Alert'
import { useAppOptions } from '../../utils/AppOptionsContext'
import { useAuth } from '../../utils/globalContext'
import { changeLanguage } from '../../utils/i18n'
import RecoverPasswordPopUp from './RecoverPasswordPopUp'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const { setToken } = useAuth()
  const [error, setError] = useState(false)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const { appOptions, loading: loadingAppOptions, error: errorAppOptions } = useAppOptions()
  const queryClient = useQueryClient()

  const { t } = useTranslation()

  const loginAction = async event => {
    try {
      event.preventDefault()
      queryClient.clear()
      setLoading(true)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
        email,
        password,
      })
      const { token, managerLevel, language } = response.data
      setToken(token)
      setError(false)

      // Set user's preferred language.
      changeLanguage(language)

      if (managerLevel >= 1 && managerLevel < 90) {
        navigate('/manager/dashboard')
      } else if (managerLevel >= 90) {
        navigate('/admin/dashboard')
      } else {
        navigate('/user')
      }
    } catch (error) {
      setLoading(false)
      switch (error.response.data.type) {
        case 'USERNAME_OR_PASSWORD_INCORRECT':
          setError(t('auth.login.incorrectCredentials'))
          break
        case 'USER_NOT_VERIFIED':
          setError(t('auth.login.unverifiedUser'))
          break
        case 'MAIL_NOT_VERIFIED':
          setError(t('auth.login.unverifiedMail'))
          break
        default:
          setError(t('generic.error'))
          break
      }
    }
  }

  const [open, setOpen] = useState(false)

  return (
    <>
      <RecoverPasswordPopUp open={open} setOpen={setOpen} />
      <div className="flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div className="mb-4">
            <img
              className="h-12 w-auto"
              src={logo}
              alt="YouShift"
            />
            <h2 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">{t('generic.login')}</h2>
            <p className="mt-2 text-sm text-gray-600">
              {t('auth.login.or')}
              {' '}
              <Link to="/signup" className="font-medium text-blue-600 hover:text-blue-500">
                {t('auth.login.signup')}
              </Link>
            </p>
          </div>
          {error ? <Alert text={error} /> : null}
          <div className="mt-8">
            <div className="mt-6">
              <form className="space-y-6" onSubmit={loginAction}>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('generic.email')}
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="space-y-1">
                  <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                    {t('generic.password')}
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">

                  <div className="text-sm">
                    <button type="button" onClick={() => setOpen(true)} className="font-medium text-blue-600 hover:text-blue-500">
                      {t('auth.login.forgotPassword')}
                    </button>
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-blue-600 py-2 px-3 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >
                    {loading
                      ? (
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                        </svg>
                      ) : null}
                    <p>{t('generic.login')}</p>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
