function ImbalanceBar({ value, maxValue }) {
  return (
    <div className="flex justify-center">
      <div className="w-12 flex items-center" style={{ justifyContent: 'flex-end' }}>
        {value < 0 && <p className="text-xxs">{value}</p>}
        {value < 0 && <div className="h-5 bg-red-600" style={{ width: `${(value / -maxValue) * 48}px` }} />}
      </div>
      <div className="h-5 border-r border-r-black" />
      <div className="w-12 flex items-center">
        {value > 0 && <div className="h-5 bg-green-600" style={{ width: `${(value / maxValue) * 48}px` }} />}
        {value > 0 && <p className="text-xxs">{value}</p>}
      </div>
    </div>
  )
}

export default ImbalanceBar
