import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import { useMutation } from '@tanstack/react-query'

import { request } from '../../utils/axios'

export default function ShaveItr({ open, setOpen, setSuccess }) {
  const [itrId, setItrId] = useState('')
  const [lastIncludedDay, setLastIncludedDay] = useState('')

  const shaveItrMutation = useMutation(
    () => request({
      url: `/manager/itrs/${itrId}/shave_last_slots`,
      method: 'post',
      data: { last_included_day: lastIncludedDay },
    }),
    {
      onSuccess: () => {
        setSuccess('ITR shaved successfully!')
        setOpen(false)
      },
      onError: error => {
        console.error('Error shaving ITR:', error)
      },
    }
  )

  const handleShaveItr = () => {
    if (!itrId || !lastIncludedDay) {
      console.warn('Both ITR ID and date must be provided.')
      return
    }

    shaveItrMutation.mutate()
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    Shave ITR
                  </Dialog.Title>
                  <div className="mt-2">
                    <label className="block text-sm font-medium text-gray-700">
                      ITR ID
                    </label>
                    <input
                      type="number"
                      className="mt-1 w-full border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      value={itrId}
                      onChange={e => setItrId(e.target.value)}
                      placeholder="Enter ITR ID"
                    />
                    <label className="block text-sm font-medium text-gray-700 mt-4">
                      Last Included Day
                    </label>
                    <input
                      type="date"
                      className="mt-1 w-full border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                      value={lastIncludedDay}
                      onChange={e => setLastIncludedDay(e.target.value)}
                      placeholder="Select a date"
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    onClick={handleShaveItr}
                  >
                    Shave ITR
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
