import { InlineWidget } from 'react-calendly'
import { Link } from 'react-router-dom'

import logo from '../assets/Logo/logo_lq.png'

export default function Waitlist() {
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-12 sm:py-12 lg:px-8">
      <div className="text-center w-full">
        <img
          className="h-24 w-auto my-0 mx-auto"
          src={logo}
          alt="YouShift"
        />
        <p className="text-base font-semibold text-green-600">¡Bienvenido a YouShift!</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">¿Cómo podemos ayudarte?</h1>
        <h2 className="mt-6 text-base leading-7 text-gray-600">Nos encantaría hablar contigo para conocer las necesidades de tu servicio y crear un plan a medida.</h2>
        <p className="font-semibold">Programa una llamada con nuestro equipo:</p>
        <div className="lg:-mt-10">
          <InlineWidget url="https://calendly.com/you-shift/contacto" height="1000px" />
        </div>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/"
            className="rounded-md bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
          >
            Ir al inicio
          </Link>
          <Link to="/contact" className="text-sm font-semibold text-gray-900">
            Contacta con nosotros
            <span aria-hidden="true">&rarr;</span>
          </Link>
        </div>
      </div>
    </main>
  )
}
