import { PlusIcon } from '@heroicons/react/20/solid'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext, useParams } from 'react-router-dom'

import Alert from '../../../../components/FormFeedback/Alert'
import { request } from '../../../../utils/axios'
import { requireApproved, requireManager } from '../../../../utils/checks'
import { incompatibilitiesQuery, itrRolesQuery, shiftsQuery } from '../../../../utils/queries'
import AddIncompGroup from './AddIncompGroup'

export const incompatibilitiesLoader = queryClient => async ({ params }) => {
  const user = await requireManager(queryClient)
  await requireApproved(user)
  const incompQuery = incompatibilitiesQuery(params.id)
  return (
    queryClient.getQueryData(incompQuery.queryKey) ?? (await queryClient.fetchQuery(incompQuery))
  )
}

export default function Incompatibilities() {
  const { id } = useParams()
  const { data, isLoading } = useQuery(incompatibilitiesQuery(id))
  const { data: shifts } = useQuery(shiftsQuery(id))
  const incomp_groups = data?.incomp_groups
  const { data: itrRoles } = useQuery(itrRolesQuery(id))
  const participants = itrRoles.itr_prefs

  const { t } = useTranslation()

  function parseParticipants(input) {
    const result = {}

    input.forEach(item => {
      const roleName = item.user.role.name
      const userObj = {
        id: item.user.id,
        fullName: `${item.user.firstname} ${item.user.lastname}`,
      }

      if (!Object.prototype.hasOwnProperty.call(result, roleName)) {
        result[roleName] = []
      }

      if (item.partaker) {
        result[roleName].push(userObj)
      }
    })

    return result
  }

  const [open, setOpen] = useState(false)
  const [group, setGroup] = useState(null)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const queryClient = useQueryClient()

  const getErrorDetails = (idUser, idShifts, participants, shifts) => {
    const participant = participants.find(participant => participant.user.id === idUser)
    const name = participant ? `${participant.user.firstname} ${participant.user.lastname}` : undefined
    const affectedShifts = idShifts
      .map(idShift => shifts.shifts.find(shift => shift.id_shift === idShift)?.name)
      .filter(Boolean)
      .join(', ')

    return { name, affectedShifts }
  }

  const addIncompGroup = useMutation({
    mutationFn: params => request({ url: `/manager/itrs/${id}/incomp/new`, method: 'post', data: params }),
    onSuccess: (data, variables) => {
      setSuccess(t('manager.incomp.successCreate'))
      setOpen(false)
      setError(false)
      queryClient.invalidateQueries({ queryKey: ['incompatibilities', id] })
    },
    onError: error => {
      const { type, id_user: idUser, id_shifts: idShifts } = error.response.data.error

      switch (type) {
        case 'USER_NOT_IN_SHIFTS': {
          const { name, affectedShifts } = getErrorDetails(idUser, idShifts, participants, shifts)

          const errorMessage = (name && affectedShifts)
            ? t('manager.incomp.errorCreateIncompNotInShifts', { name, affectedShifts })
            : t('manager.incomp.errorCreateIncompNotInShiftsGeneric')

          setError(errorMessage)
          break
        }

        default:
          setError(t('manager.incomp.errorCreate'))
      }
      setSuccess(false)
    },
  })

  const editIncompGroup = useMutation({
    mutationFn: ({ id_incomp, ...rest }) => request({ url: `/manager/itrs/${id}/incomp/${id_incomp}`, method: 'patch', data: rest }),
    onSuccess: (data, variables) => {
      setSuccess(t('manager.incomp.successEdit'))
      setOpen(false)
      setError(false)
      queryClient.invalidateQueries({ queryKey: ['incompatibilities', id] })
    },
    onError: error => {
      const { type, id_user: idUser, id_shifts: idShifts } = error.response.data.error

      switch (type) {
        case 'USER_NOT_IN_SHIFTS': {
          const { name, affectedShifts } = getErrorDetails(idUser, idShifts, participants, shifts)

          const errorMessage = (name && affectedShifts)
            ? t('manager.incomp.errorEditIncompNotInShifts', { name, affectedShifts })
            : t('manager.incomp.errorEditIncompNotInShiftsGeneric')

          setError(errorMessage)
          break
        }

        default:
          setError(t('manager.incomp.errorEdit'))
      }
      setSuccess(false)
    },
  })

  const deleteIncompGroup = useMutation({
    mutationFn: ({ id_incomp }) => request({ url: `/manager/itrs/${id}/incomp/${id_incomp}`, method: 'delete' }),
    onSuccess: (data, variables) => {
      setSuccess(t('manager.incomp.successEliminate'))
      setOpen(false)
      setError(false)
      queryClient.invalidateQueries({ queryKey: ['incompatibilities', id] })
    },
    onError: () => {
      setSuccess(false)
      setError(t('manager.incomp.errorEliminate'))
    },
  })

  const context = useOutletContext()
  // const locked = context ? context[0] : false

  // revisar el handling de los dos casos y sus subcasos: no hay incomp y estamos en preview/config, si hay incomp y estamos en preview o en config

  return (
    <>
      {open
        ? (
          <AddIncompGroup
            participants={parseParticipants(participants)}
            open={open}
            setOpen={setOpen}
            addIncompGroup={addIncompGroup}
            editIncompGroup={editIncompGroup}
            deleteIncompGroup={deleteIncompGroup}
            setSuccess={setSuccess}
            error={error}
            setError={setError}
            group={group}
            shifts={shifts.shifts}
          />
        ) : null}
      {success
        ? (
          <div className="mt-3">
            <Alert success text={success} />
          </div>
        ) : null}
      {!isLoading && (incomp_groups.length
        ? (
          <div className="lg:mx-10">
            {
              context ? (
                <button
                  type="button"
                  className="block rounded-md bg-blue-600 py-2 px-3 mt-6 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  onClick={() => { setOpen(true); setGroup(null) }}
                >
                  {t('manager.incomp.createNew')}
                </button>
              ) : null
            }
            {incomp_groups.map(group => (
              <div className="overflow-hidden rounded-lg bg-white shadow mt-6">
                <div className="px-4 py-5 sm:px-6">
                  <div className="flex flex-row gap-2 text-blue-600 font-semibold">
                    <p>{group.name}</p>
                    <button
                      onClick={() => { setGroup(group); setOpen(true) }}
                      disabled={!context}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-gray-600">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                      </svg>
                    </button>
                    {' | '}
                    {group.type === 'single_group_incomp'
                      ? (
                        <p className="text-gray-400 font-normal text-sm">{t('manager.incomp.singleGroup')}</p>
                      ) : (
                        <p className="text-gray-400 font-normal text-sm">{t('manager.incomp.crossGroup')}</p>
                      )}
                  </div>
                  <p className="mt-2 text-gray-500">
                    {t('manager.incomp.participatingSections')}
                    {group.id_shifts.map(id_shift => shifts.shifts.find(shift => shift.id_shift == id_shift)?.name).join(', ')}
                  </p>
                  {group.type === 'single_group_incomp' ? (
                    <p className="mt-2 text-gray-500">
                      {t('manager.incomp.groupInfo', { number: group.users.length, max: group.max_simult, min: group.min_simult })}
                    </p>
                  ) : (
                    <p className="mt-2 text-gray-500">
                      {t('manager.incomp.crossGroupInfo')}
                    </p>
                  )}
                </div>
                {
                  group.type === 'single_group_incomp' ? (
                    <div className="bg-gray-50 px-4 py-5 sm:p-6">
                      {group.users.length
                        ? (
                          <div className="flex flex-row gap-8 flex-wrap">
                            {group.users.map(person => (
                              <div className="bg-teal-400/20 text-gray-900 py-4 px-3 whitespace-nowrap rounded-md">
                                {`${person.firstname} ${person.lastname}`}
                              </div>
                            ))}
                          </div>
                        )
                        : t('manager.incomp.noParticipants')}
                    </div>
                  ) : (
                    <div className=" px-4 py-5 sm:p-6">
                      {group.group_1_users.length && group.group_2_users.length
                        ? (
                          <div className="grid grid-cols-2 gap-12">
                            <p className="absolute p-2 w-8 h-8 text-center rounded-2xl bg-teal-600 text-xs  font-semibold text-white">A</p>
                            <div className="flex flex-row flex-wrap gap-8 p-3 rounded-md bg-gray-50">
                              {group.group_1_users.map(person => (
                                <div className="bg-teal-400/20 text-gray-900 py-4 px-3 whitespace-nowrap rounded-md">
                                  {`${person.firstname} ${person.lastname}`}
                                </div>

                              ))}
                            </div>
                            <div>
                              <p className="absolute p-2 w-8 h-8 text-center rounded-2xl bg-blue-600 text-xs font-semibold text-white">B</p>
                              <div className="flex flex-row flex-wrap gap-8 p-3 rounded-md bg-gray-50">
                                {group.group_2_users.map(person => (
                                  <div className="bg-blue-400/20 text-gray-900 py-4 px-3 whitespace-nowrap rounded-md">
                                    {`${person.firstname} ${person.lastname}`}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )
                        : t('manager.incomp.noParticipants')}
                    </div>
                  )
                }
              </div>
            ))}
          </div>
        )
        : (
          context ? (
            <div className="text-center mt-24">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181" />
              </svg>
              <h3 className="mt-2 text-sm font-semibold text-gray-900">{t('manager.incomp.createPrompt')}</h3>
              <p className="text-sm text-gray-500 text-whitespace max-w-lg my-4 mx-auto align-center">{t('manager.incomp.definition')}</p>
              <div className="mt-6">
                <button
                  type="button"
                  className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  onClick={() => { setOpen(true); setGroup(null) }}
                >
                  <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                  {t('manager.incomp.newGroup')}
                </button>
              </div>
            </div>
          ) : <p className="text-center">{t('manager.incomp.noGroups')}</p>
        ))}
    </>
  )
}
