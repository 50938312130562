/* eslint-disable max-len */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { PencilIcon, PlusIcon } from '@heroicons/react/24/outline'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useOutletContext, useParams } from 'react-router-dom'

import Alert from '../../../../components/FormFeedback/Alert'
import { useAppOptions } from '../../../../utils/AppOptionsContext'
import { useTutorial } from '../../../../utils/TutorialContext'
import { request } from '../../../../utils/axios'
import { requireApproved, requireManager } from '../../../../utils/checks'
import { classNames } from '../../../../utils/helpers'
import { itrRolesQuery, slotSubsetsQuery } from '../../../../utils/queries'
import CreateSlotSubset from './CreateSlotSubset'
import SlotSubsetReqs, { UpdateMinMaxSlotSubsetReqs } from './SlotSubsetReqs'

const { v4: uuidv4 } = require('uuid')

export const slotSubsetLoader = queryClient => async ({ params }) => {
  const user = await requireManager(queryClient)
  await requireApproved(user)
  const query = slotSubsetsQuery(params.id)
  return (
    queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
  )
}

export default function SlotSubsetsConfig() {
  const { id } = useParams()
  const { data } = useQuery(slotSubsetsQuery(id))

  const { appOptions } = useAppOptions()

  const adjustWeekDay = slots => {
    const weekdayAbbreviations = {
      Mon: 0,
      Tue: 1,
      Wed: 2,
      Thu: 3,
      Fri: 4,
      Sat: 5,
      Sun: 6,
    }

    const startWeekDay = slots[0].start.substring(0, 3)
    const endWeekDay = slots[slots.length - 1].start.substring(0, 3)
    const startCount = weekdayAbbreviations[startWeekDay]
    const endCount = 6 - weekdayAbbreviations[endWeekDay]
    const fillersStart = Array(startCount).fill(null).map(() => ({
      id_slot: uuidv4(),
      start: '',
    }))

    const fillersEnd = Array(endCount).fill(null).map(() => ({
      id_slot: uuidv4(),
      start: '',
    }))

    const adjustedShiftsNeeds = [...fillersStart, ...slots, ...fillersEnd]
    return adjustedShiftsNeeds
  }

  const context = useOutletContext()

  const slotSubsets = data?.slot_subsets
  const slots = adjustWeekDay(data?.itr_slots)
  const itrRoles = data?.itr_roles

  const { data: allRoles } = useQuery(itrRolesQuery(id))
  const roles = useMemo(() => {
    const rolesMap = {}
    if (allRoles) {
      allRoles.roles.forEach(role => {
        const { id, name } = role
        const num_users = allRoles.itr_roles[id]?.num_users || 0 // Fallback to 0 if not found
        rolesMap[id] = { name, num_users }
      })
    }
    return rolesMap
  }, [allRoles])

  const [selectedSlotSubset, setselectedSlotSubset] = useState(Object.keys(slotSubsets)[0] || null)
  const [selectedSlots, setSelectedSlots] = useState(new Set())
  const [open, setOpen] = useState(false)
  const [edit, setEdit] = useState(false)
  const [nameToEdit, setNameToEdit] = useState(null)
  const [idToEdit, setIdToEdit] = useState(null)
  const [showSaveButton, setShowSaveButton] = useState(false)

  const queryClient = useQueryClient()

  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    // Update selectedSlots
    const newSelectedSlots = new Set(slotSubsets[selectedSlotSubset]?.slots)
    setSelectedSlots(newSelectedSlots)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSlotSubset])

  const handleSlotClick = id => {
    if (context) {
      setSelectedSlots(prevSelectedSlots => {
        const updatedSlots = new Set(prevSelectedSlots)
        if (updatedSlots.has(id)) {
          updatedSlots.delete(id)
        } else {
          updatedSlots.add(id)
        }
        const selectedSlotsSet = new Set(slotSubsets[selectedSlotSubset]?.slots)
        const condition = updatedSlots.size === selectedSlotsSet.size
          && Array.from(updatedSlots).every(item => selectedSlotsSet.has(item))
        setShowSaveButton(!condition)
        return updatedSlots
      })
    }
  }

  const parseSlotSubsetReqs = () => {
    const slotSubsetReqs = {}

    // Iterate over each role in the original object
    Object.entries(itrRoles).forEach(([userRoleId, { slot_subset_reqs }]) => {
      // Iterate over each slot subset requirement
      Object.values(slot_subset_reqs).forEach(({
        id_slot_subset, id_slot_subset_req, max_req, min_req,
      }) => {
        // Initialize the slot subset object if it doesn't exist
        if (!slotSubsetReqs[id_slot_subset]) {
          slotSubsetReqs[id_slot_subset] = {}
        }

        // Add the user role details under the slot subset
        slotSubsetReqs[id_slot_subset][userRoleId] = { id_slot_subset_req, max_req, min_req }
      })
    })

    return slotSubsetReqs
  }

  function slotSubsetReqsReducer(state, action) {
    if (action.type !== 'SET_REQS') {
      setShowSaveButton(true)
    }
    switch (action.type) {
      case 'SET_REQS': {
        return action.payload
      }
      case 'EDIT_SPECIFIC_MIN_REQ': {
        const { id_slot_subset, userRoleId, minReq } = action.payload
        if (state[id_slot_subset] && state[id_slot_subset][userRoleId]) {
          const updatedState = { ...state }
          const currentMaxReq = updatedState[id_slot_subset][userRoleId].max_req
          // Ensure values are >= 0, adjust both min_req and max_req if trying to increase min_req beyond current max_req
          const newMinReq = Math.max(0, minReq)
          if (newMinReq >= currentMaxReq) {
            updatedState[id_slot_subset][userRoleId] = {
              ...updatedState[id_slot_subset][userRoleId],
              min_req: newMinReq,
              max_req: newMinReq, // Increase max_req to match the new min_req, ensuring it's also >= 0
            }
          } else {
            updatedState[id_slot_subset][userRoleId].min_req = newMinReq
          }
          return updatedState
        }
        return state
      }

      case 'EDIT_SPECIFIC_MAX_REQ': {
        const { id_slot_subset: slotId, userRoleId: roleId, maxReq } = action.payload
        if (state[slotId] && state[slotId][roleId]) {
          const updatedState = { ...state }
          const currentMinReq = updatedState[slotId][roleId].min_req
          // Ensure values are >= 0, adjust both max_req and min_req if trying to decrease max_req below current min_req
          const newMaxReq = Math.max(0, maxReq)
          if (newMaxReq <= currentMinReq) {
            updatedState[slotId][roleId] = {
              ...updatedState[slotId][roleId],
              max_req: newMaxReq,
              min_req: newMaxReq, // Decrease min_req to match the new max_req, ensuring it's also >= 0
            }
          } else {
            updatedState[slotId][roleId].max_req = newMaxReq
          }
          return updatedState
        }
        return state
      }

      case 'EDIT_ALL_MIN_REQS': {
        const { incrementMin } = action.payload
        // Create a deep copy for the nested update
        const updatedStateMin = {
          ...state,
          [selectedSlotSubset]: Object.entries(state[selectedSlotSubset] || {}).reduce((acc, [role, reqs]) => {
            const updatedMinReq = Math.max(0, reqs.min_req + incrementMin)
            // Ensure max_req remains >= updated min_req
            const updatedMaxReq = Math.max(updatedMinReq, reqs.max_req)
            acc[role] = { ...reqs, min_req: updatedMinReq, max_req: updatedMaxReq }
            return acc
          }, {}),
        }
        return updatedStateMin
      }

      case 'EDIT_ALL_MAX_REQS': {
        const { incrementMax } = action.payload
        // Similarly, create a deep copy for the nested update
        const updatedStateMax = {
          ...state,
          [selectedSlotSubset]: Object.entries(state[selectedSlotSubset] || {}).reduce((acc, [role, reqs]) => {
            const updatedMaxReq = Math.max(0, reqs.max_req + incrementMax)
            // Ensure min_req remains <= updated max_req
            const updatedMinReq = Math.min(updatedMaxReq, reqs.min_req)
            acc[role] = { ...reqs, max_req: updatedMaxReq, min_req: updatedMinReq }
            return acc
          }, {}),
        }
        return updatedStateMax
      }

      default:
        return state
    }
  }
  const [slotSubsetReqs, dispatch] = useReducer(slotSubsetReqsReducer, parseSlotSubsetReqs())

  // const selectNewSlotSubset = id => {
  //   if (slotSubsetReqs[id]) {
  //     setselectedSlotSubset(id)
  //   }
  // }

  useEffect(() => {
    dispatch({ type: 'SET_REQS', payload: parseSlotSubsetReqs() })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const editSlotSubset = useMutation(idSlotSubset => request(
    {
      url: `manager/itrs/${id}/slot_subsets/${idSlotSubset}/edit_subset_slot`,
      method: 'patch',
      data: {
        slots: Array.from(selectedSlots),
        slot_subset_reqs: Object.entries(slotSubsetReqs[selectedSlotSubset]).map(([id_user_role, { max_req, min_req }]) => ({
          id_user_role: parseInt(id_user_role, 10),
          min_req,
          max_req,
        })),
      },
    },
  ), {
    onSuccess: () => {
      queryClient.invalidateQueries(['slotSubsets', id])
      setOpen(false)
      setSuccess(t('manager.slotSubsets.editSlotSubsetSuccess'))
      setError(null)
      setShowSaveButton(false)
    },
    onError: error => {
      setError(t('generic.error'))
    },
  })

  const {
    next, setStepIndex, isRunning, currentTutorial, stepId,
  } = useTutorial()

  return (
    <div id="slotSubsets">
      {open ? (
        <CreateSlotSubset
          open={open}
          setOpen={setOpen}
          edit={edit}
          nameToEdit={nameToEdit}
          idToEdit={idToEdit}
          setSuccess={setSuccess}
          setselectedSlotSubset={setselectedSlotSubset}
          next={next}
        />
      ) : null}
      {success ? (
        <Alert success text={success} />
      ) : null}
      {error ? (
        <Alert text={error} />
      ) : null}
      {
        !Object.keys(slotSubsets).length ? (
          context ? (
            <div className="text-center mt-24" id="createFirstSubsetSlot">
              <svg
                className="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z" />
              </svg>
              <h3 className="mt-2 text-sm font-semibold text-gray-900">{t('manager.slotSubsets.createFirstGroup')}</h3>
              <p className="text-sm text-gray-500 text-whitespace max-w-lg my-4 mx-auto align-center">{t('manager.slotSubsets.createFirstGroupDefinition')}</p>
              <div className="mt-6">
                <button
                  type="button"
                  className="inline-flex items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  onClick={() => {
                    setIdToEdit(null)
                    setNameToEdit(null)
                    setEdit(false)
                    setOpen(true)
                    next('manager_iteration_configuration')
                  }}
                >
                  <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
                  {t('manager.slotSubsets.create')}
                </button>
              </div>
            </div>
          ) : <p className="text-center">{t('manager.slotSubsets.noSlotSubsets')}</p>
        ) : (
          <>
            <RadioGroup
              value={String(selectedSlotSubset)}
              onChange={e => {
                setShowSaveButton(false)
                setselectedSlotSubset(e)
              }}
            >
              <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-3 sm:gap-x-4 self-start items-start">
                {Object.keys(slotSubsets).map(slotSubsetId => (
                  <RadioGroup.Option
                    key={slotSubsetId.id}
                    value={slotSubsetId}
                    className={({ active, checked }) => classNames(
                      active || checked ? 'border-blue-600 ring-1 ring-blue-600' : 'border-gray-300',
                      'relative flex flex-col cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none hover:border-blue-600',
                    )}
                  >
                    {({ checked, active }) => (
                      <>
                        <div className="flex flex-row justify-between">
                          <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                            {slotSubsets[slotSubsetId].name}
                          </RadioGroup.Label>
                          <div className="flex flex-row justify-center items-center md:gap-2">
                            <button
                              onClick={() => {
                                setNameToEdit(slotSubsets[slotSubsetId].name)
                                setIdToEdit(slotSubsetId)
                                setEdit(true)
                                setOpen(true)
                              }}
                              className="text-gray-600"
                              disabled={!context}
                            >
                              <PencilIcon className={classNames(!checked ? 'hidden' : '', 'h-5 w-5 hover:text-blue-600')} />
                            </button>
                            <CheckCircleIcon
                              className={classNames(!checked ? 'hidden' : '', 'h-5 w-5 text-blue-600')}
                              aria-hidden="true"
                            />
                          </div>
                          <span
                            className={classNames(
                              active ? 'border' : 'border-2',
                              checked ? 'border-blue-600' : 'border-transparent',
                              'pointer-events-none absolute -inset-px rounded-lg',
                            )}
                            aria-hidden="true"
                          />
                        </div>
                        {(
                          showSaveButton && checked
                        )
                          ? (
                            <button
                              type="button"
                              className="block rounded-md mt-4 bg-blue-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                              // onClick={saveChanges}
                              disabled={!context}
                              onClick={() => editSlotSubset.mutate(slotSubsetId)}
                              id="saveChanges"
                            >
                              {t('generic.saveChanges')}
                            </button>
                          ) : null}
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
                {
                  Object.keys(slotSubsets).length < (appOptions?.app_config?.max_subset_slots || 3) && context ? (
                    <button
                      className="relative flex cursor-pointer rounded-lg border text-start bg-blue-600 p-4 shadow-sm focus:outline-none hover:bg-blue-500"
                      onClick={() => {
                        setIdToEdit(null)
                        setNameToEdit(null)
                        setEdit(false)
                        setOpen(true)
                      }}
                    >
                      <div className="flex flex-row items-center">
                        <PlusIcon className="h-6 w-6 text-white" aria-hidden="true" />
                        <span className="flex flex-1">
                          <span className="flex flex-col">
                            <span className="block text-sm font-medium text-white">
                              {t('manager.slotSubsets.create')}
                            </span>
                          </span>
                        </span>
                        <span
                          className="pointer-events-none absolute -inset-px rounded-lg"
                          aria-hidden="true"
                        />
                      </div>
                    </button>
                  ) : null
                }
              </div>
            </RadioGroup>
            {
              selectedSlotSubset ? (
                <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col mt-5" id="calendar">
                  <div className="hidden sticky top-0 z-10 lg:grid grid-cols-7 gap-px border-b border-gray-300 bg-gray-200 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
                    <div className="bg-white py-2">
                      <Trans i18nKey="calendars.mon">
                        L
                        <span className="sr-only sm:not-sr-only">unes</span>
                      </Trans>
                    </div>
                    <div className="bg-white py-2">
                      <Trans i18nKey="calendars.tue">
                        M
                        <span className="sr-only sm:not-sr-only">artes</span>
                      </Trans>
                    </div>
                    <div className="bg-white py-2">
                      <Trans i18nKey="calendars.wed">
                        M
                        <span className="sr-only sm:not-sr-only">iércoles</span>
                      </Trans>
                    </div>
                    <div className="bg-white py-2">
                      <Trans i18nKey="calendars.thu">
                        J
                        <span className="sr-only sm:not-sr-only">ueves</span>
                      </Trans>
                    </div>
                    <div className="bg-white py-2">
                      <Trans i18nKey="calendars.fri">
                        V
                        <span className="sr-only sm:not-sr-only">iernes</span>
                      </Trans>
                    </div>
                    <div className="bg-white py-2">
                      <Trans i18nKey="calendars.sat">
                        S
                        <span className="sr-only sm:not-sr-only">ábado</span>
                      </Trans>
                    </div>
                    <div className="bg-white py-2">
                      <Trans i18nKey="calendars.sun">
                        D
                        <span className="sr-only sm:not-sr-only">omingo</span>
                      </Trans>
                    </div>
                  </div>
                  <div className="flex bg-gray-200 text-xs leading-6 text-gray-700 lg:flex-auto">
                    <div className={`w-full grid grid-cols-7 grid-rows-${Math.ceil(slots.length / 7)} gap-px`}>
                      {slots.map(slot => (
                        <button
                          key={slot.id_slot}
                          className={classNames(
                            slot.start ? 'bg-white' : 'bg-gray-50 text-gray-500',
                            selectedSlots.has(slot.id_slot) ? 'border-blue-600' : 'border-transparent',
                            'border relative py-2 px-3 flex flex-col justify-around',
                          )}
                          disabled={!slot.start || !context}
                          onClick={() => handleSlotClick(slot.id_slot)}
                        >
                          <time
                            dateTime={slot.start}
                          >
                            {slot.start ? slot.start.slice(5, 7) : ''}
                          </time>
                          <CheckCircleIcon
                            className={classNames(selectedSlots.has(slot.id_slot) ? '' : 'invisible', 'h-8 w-8 text-blue-600 m-auto')}
                            aria-hidden="true"
                          />
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              ) : null
            }
            {
              selectedSlotSubset ? (
                <div id="subsetSlotReqs">
                  <div className="flex flex-row mt-6 mb-2 gap-2 text-gray-800">
                    <p className="font-semibold">{t('manager.slotSubsets.subsetSlotReqs', { group: slotSubsets[selectedSlotSubset].name })}</p>
                  </div>
                  <div className="flex flex-row items-center justify-center gap-4">
                    <ul className="my-6 flex flex-row gap-6 flex-wrap justify-center">
                      {slotSubsetReqs[selectedSlotSubset] && Object.entries(slotSubsetReqs[selectedSlotSubset]).map(([role, reqs]) => (
                        <li key={role} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                          <div className="flex w-full items-center justify-between space-x-6 p-6">
                            <div className="flex-1 truncate">
                              <div className="flex items-center space-x-3">
                                <h3 className="truncate text-sm font-medium text-gray-900">{roles[role].name}</h3>
                              </div>
                              <p className="mt-1 truncate text-sm">{`${roles[role].num_users} participantes`}</p>
                            </div>
                          </div>
                          <div className="flex justify-center">
                            <SlotSubsetReqs
                              slotSubsetReqs={{ ...reqs, userRoleId: role, id_slot_subset: selectedSlotSubset }}
                              dispatch={dispatch}
                              disabled={!context}
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                    <UpdateMinMaxSlotSubsetReqs dispatch={dispatch} disabled={!context} />
                  </div>
                </div>
              ) : null
            }
          </>
        )
      }
    </div>
  )
}
