/* eslint-disable no-console */
import axios from 'axios'

const client = axios.create(
  {
    baseURL: process.env.REACT_APP_API_URL,
  },
)

export const request = ({ ...options }) => {
  const userToken = localStorage.getItem('token')
  if (userToken) {
    client.defaults.headers.common.Authorization = `Bearer ${userToken}`
  }
  const onSuccess = response => response.data
  const onError = error => {
    throw error
  }
  return client(options).then(onSuccess).catch(onError)
}

client.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('token')
      // Redirect to login page
      window.location.href = '/login'
    }
    return Promise.reject(error)
  },
)
