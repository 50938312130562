import { Dialog, Transition } from '@headlessui/react'
import {
  Document,
  PDFViewer,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

export default function ExportToPDF({ open, setOpen, data }) {
  const { outputs, name } = data

  const { t } = useTranslation()

  const stylespdf = StyleSheet.create({
    page: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      alignContent: 'center',
      paddingTop: 2,
      paddingBottom: 10,
      fontSize: '14px',
    },
    title: {
      fontSize: '20px',
      padding: 25,
      textAlign: 'center',
      fontWeight: 'bold',
    },
    date: {
      width: '20%',
      display: 'inline-block',
      color: 'black',
      textAlign: 'left',
      padding: 10,
      borderBottom: '1px solid black',
      borderRight: '1px solid black',
    },
    slot: {
      width: '80%',
      display: 'inline-block',
      color: 'black',
      textAlign: 'left',
      padding: 10,
      borderBottom: '1px solid black',
      flexDirection: 'column',
    },
    shift: {
      display: 'block',
      width: '100%',
      marginTop: '3px',
      marginBottom: '3px',
      fontSize: '14px',
      lineHeight: '2px',
    },
  })

  const parseWeekDay = start => {
    const weekdays = [
      t('calendars.sunday'),
      t('calendars.monday'),
      t('calendars.tuesday'),
      t('calendars.wednesday'),
      t('calendars.thursday'),
      t('calendars.friday'),
      t('calendars.saturday'),
    ]

    const date = new Date(start)
    const weekday = weekdays[date.getUTCDay()]
    const day = date.getUTCDate()

    return `${weekday} ${day}`
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block" />
                <PDFViewer width="100%" height="500px" showToolbar>
                  <Document pageMode="fullScreen" style={stylespdf.page}>
                    <Page size="A4" orientation="landscape">
                      <Text style={stylespdf.title}>{name}</Text>
                      {outputs.map(d => (
                        <View width="100" style={stylespdf.page} wrap={false}>
                          <Text style={stylespdf.date}>
                            {parseWeekDay(d.start)}
                          </Text>
                          <View width="80" style={stylespdf.slot}>
                            {Object.entries(d.slot_outputs).map(
                              ([shiftId, shift]) => (
                                <Text style={stylespdf.shift}>
                                  {`${shift[0].shift_name} -- `}
                                  {shift.map(s => `${s.name},   `)}
                                </Text>
                              ),
                            )}
                          </View>
                        </View>
                      ))}
                    </Page>
                  </Document>
                </PDFViewer>
                <div className="mt-3 text-right">
                  <button
                    type="button"
                    className="w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                  >
                    {t('generic.closeButton')}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
