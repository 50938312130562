/* eslint-disable react/jsx-indent */
/* eslint-disable max-len */

import {
  ArrowPathRoundedSquareIcon, BackwardIcon, CalculatorIcon, HandRaisedIcon,
} from '@heroicons/react/24/outline'

export const textColor = ['text-teal-600', 'text-violet-600', 'text-red-600', 'text-sky-600', 'text-lime-600', 'text-yellow-600', 'text-cyan-600', 'text-orange-600', 'text-fuchsia-600', 'text-stone-600', 'text-green-600', 'text-pink-600']
export const bgColor = ['bg-teal-100', 'bg-violet-100', 'bg-red-100', 'bg-sky-100', 'bg-lime-100', 'bg-yellow-100', 'bg-cyan-100', 'bg-orange-100', 'bg-fuchsia-100', 'bg-stone-100', 'bg-green-100', 'bg-pink-100']
export const textColorRGBA = [
  'rgba(20, 184, 166, 1)', // teal-600
  'rgba(139, 92, 246, 1)', // violet-600
  'rgba(239, 68, 68, 1)', // red-600
  'rgba(14, 165, 233, 1)', // sky-600
  'rgba(132, 204, 22, 1)', // lime-600
  'rgba(234, 179, 8, 1)', // yellow-600
  'rgba(6, 182, 212, 1)', // cyan-600
  'rgba(249, 115, 22, 1)', // orange-600
  'rgba(217, 70, 239, 1)', // fuchsia-600
  'rgba(163, 154, 145, 1)', // stone-600
  'rgba(5, 150, 105, 1)', // green-600
  'rgba(236, 72, 153, 1)', // pink-600
]

export const bgColorRGBA = [
  'rgba(240, 253, 250, 1)', // teal-100
  'rgba(245, 243, 255, 1)', // violet-100
  'rgba(254, 242, 242, 1)', // red-100
  'rgba(240, 249, 255, 1)', // sky-100
  'rgba(247, 254, 231, 1)', // lime-100
  'rgba(254, 249, 231, 1)', // yellow-100
  'rgba(236, 252, 253, 1)', // cyan-100
  'rgba(255, 247, 237, 1)', // orange-100
  'rgba(253, 244, 255, 1)', // fuchsia-100
  'rgba(250, 250, 250, 1)', // stone-100
  'rgba(240, 253, 244, 1)', // green-100
  'rgba(253, 242, 248, 1)', // pink-100
]
export const preferencesInformation = {
  title: 'Preferencias',
  text:
    <p className="text-sm text-gray-500">
      En esta pantalla, podrás indicar tus preferencias a través de nuestro sistema de puntos.
      <ul className="my-2">
        <li className="mb-2">
          <span className="text-green-400 font-semibold">Puntos: </span>
          utiliza puntos positivos para los días que
          {' '}
          <span className="font-semibold">sí</span>
          {' '}
          quieras hacer guardia y puntos negativos para días que
          {' '}
          <span className="font-semibold">no</span>
          {' '}
          quieras.
        </li>
        <li className="mb-2">
          <span className="text-yellow-400 font-semibold">Comodín: </span>
          utiliza tu comodín para asegurar que no tienes guardia el día indicado.
        </li>
        <li className="mb-2">
          <span className="text-fuchsia-400 font-semibold">Otros eventos: </span>
          utiliza esta opción para indicar si tienes un evento que te impida hacer guardia. Esto puede ser un congreso o un seminario, por ejemplo.
        </li>
        <li className="mb-2">
          <span className="text-blue-400 font-semibold">Otros servicios: </span>
          utiliza esta opción para indicar si tienes alguna guardia preasignada, ya sea en algún día festivo o en otra sección o servicio en el mismo u otro hospital.
        </li>
        <li className="mb-2">
          <span className="text-purple-400 font-semibold">Vacaciones: </span>
          utiliza esta opción para indicar si tienes vacaciones.
        </li>
      </ul>
      <p className="mt-6">
        <span className="font-semibold">IMPORTANTE:</span>
        {' '}
        En caso de marcar que tienes otro evento o servicio, deberás justificarlo y será verificado por tu gestor.
      </p>
    </p>,
}

export const iconTypes = {
  algorithm: {
    bgColor: 'bg-blue-600',
    Icon: CalculatorIcon,
  },
  preassigned: {
    bgColor: 'bg-green-600',
    Icon: BackwardIcon,
  },
  reassigned: {
    bgColor: 'bg-purple-600',
    Icon: HandRaisedIcon,
  },
  exchanged: {
    bgColor: 'bg-yellow-600',
    Icon: ArrowPathRoundedSquareIcon,
  },
}

// TODO: translate
export const infeasibilityDeltas = {
  c1: 'C1 (tener guardia)',
  c11: 'C 11',
  c101: 'dobletes (C 101)',
  c1001: 'día sí, no, no, sí (C 1001)',
  c10101: 'tripletes (C 10101)',
  c101Flav1: 'dobletes (C 101 Flav1)',
  c101Flav2: 'dobletes (C 101 Flav2)',
  c1001Flav1: 'día sí, no, no, sí (C 1001 Flav1)',
  c10101Flav1: 'tripletes (C 10101 Flav1)',
  sinkOtherShift: 'las guardias en "Otro Servicio"',
  previouslyAssignedShiftSlot: 'las guardias preasignadas',
  organizationReqsMin: 'las guardias mínimas que puede hacer una persona en un rol',
  organizationReqsMax: 'las guardias máximas que puede hacer una persona en un rol',
  organizationReqsMaxVolunteer: 'las guardias máximas que puede hacer un rol contando voluntarios',
  minSubsetSlots: 'las guardias mínimas de fin de semana',
  maxSubsetSlots: 'las guardias máximas de fin de semana',
  workerMax: 'la distribución igualitaria de los puntos no respetados',
  freedays: 'las libranzas',
  vacations: 'las vacaciones',
  other_events: 'los otros eventos',
  shiftNeedsMin: 'las necesidades mínimas de alguna sección',
  shiftNeedsMax: 'las necesidades máximas de alguna sección',
  incompatibleUser: 'grupos de incompatibilidad',
  shiftClusterReqsMin: 'las guardias mínimas de un conjunto de secciones',
  shiftClusterReqsMax: 'las guardias máximas de un conjunto de secciones',
  crossGroupIncompatibility: 'las incompatibilidades entre grupos',
}

export const square = {
  width: '45px',
  height: '45px',
  backgroundColor: 'rgba(0, 50, 236, 0.05)',
  borderRadius: '0.25rem',
  border: '1px solid rgba(0, 50, 236, 1)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  fontSize: '1rem',
}
