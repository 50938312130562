/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
import { useTranslation } from 'react-i18next'
import { NumericFormat } from 'react-number-format'

import { square } from '../../utils/constants'

export default function ServicesCounter({ disabled, needs, dispatch }) {
  const { max_need, min_need, id_slot } = needs

  const changePoints = (increment, min) => {
    let ctPoints = min ? Number(min_need) : Number(max_need)
    if (increment) {
      ctPoints++
      dispatch({ type: min ? 'EDIT_MIN_NEED' : 'EDIT_MAX_NEED', id_slot, [min ? 'minNeed' : 'maxNeed']: ctPoints })
    } else {
      ctPoints--
      dispatch({ type: min ? 'EDIT_MIN_NEED' : 'EDIT_MAX_NEED', id_slot, [min ? 'minNeed' : 'maxNeed']: ctPoints })
    }
  }

  return (
    <div className="flex gap-2">
      {/* min selector */}
      <div className="flex flex-col items-center">
        <button onClick={() => changePoints(true, true)} disabled={disabled}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
          </svg>
        </button>
        <NumericFormat
          value={min_need}
          valueIsNumericString
          isAllowed={values => {
            const { value } = values
            return value >= 0
          }}
          disabled={disabled}
          onValueChange={values => dispatch({ type: 'EDIT_MIN_NEED', id_slot, minNeed: parseInt(values.value, 10) })}
          style={square}
        />
        <button onClick={() => changePoints(false, true)} disabled={disabled}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
          </svg>
        </button>
      </div>
      {/* max selector */}
      <div className="flex flex-col items-center">
        <button onClick={() => changePoints(true, false)} disabled={disabled}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
          </svg>
        </button>
        <NumericFormat
          value={max_need}
          isAllowed={values => {
            const { value } = values
            return value >= 0
          }}
          disabled={disabled}
          onValueChange={values => dispatch({ type: 'EDIT_MAX_NEED', id_slot, maxNeed: parseInt(values.value, 10) })}
          style={square}
        />
        <button onClick={() => changePoints(false, false)} disabled={disabled}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
          </svg>
        </button>
      </div>
    </div>
  )
}

export function UpdateMinMaxServices({ dispatch }) {
  const { t } = useTranslation()
  return (
    <div className="flex flex-row">
      <div className="flex flex-col ml-2 items-center">
        <p>{t('generic.min')}</p>
        <button
          className=" text-gray font-bold py-0 px-0  "
          onClick={() => dispatch({ type: 'EDIT_ALL_MIN_NEED', increment: 1 })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 bg-blue-300 rounded "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 15.75l7.5-7.5 7.5 7.5"
            />
          </svg>
        </button>
        <button
          className="text-gray font-bold py-0 px-0 rounded mt-1"
          onClick={() => dispatch({ type: 'EDIT_ALL_MIN_NEED', increment: -1 })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 bg-blue-300 rounded"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </button>
      </div>
      <div className="flex flex-col ml-2 items-center">
        <p>{t('generic.max')}</p>
        <button
          className=" text-gray font-bold py-0 px-0  "
          onClick={() => dispatch({ type: 'EDIT_ALL_MAX_NEED', increment: 1 })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 bg-blue-300 rounded "
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.5 15.75l7.5-7.5 7.5 7.5"
            />
          </svg>
        </button>
        <button
          className="text-gray font-bold py-0 px-0 rounded mt-1"
          onClick={() => dispatch({ type: 'EDIT_ALL_MAX_NEED', increment: -1 })}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6 bg-blue-300 rounded"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </button>
      </div>
    </div>
  )
}
