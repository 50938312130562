import { Dialog, Transition } from '@headlessui/react'
import { PencilSquareIcon } from '@heroicons/react/24/outline'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Fragment, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { request } from '../../utils/axios'
import Alert from '../FormFeedback/Alert'

export default function EditRoles({
  open, setOpen, role, setSuccess, personnel,
}) {
  const cancelButtonRef = useRef(null)
  const [name, setName] = useState(role?.name || '')
  const [description, setDescription] = useState(role?.description || '')
  const [sensitive_info, setSensitiveInfo] = useState(role?.sensitive_info || '')
  const [error, setError] = useState(false)

  const { t } = useTranslation()

  const queryClient = useQueryClient()
  const emptyState = async () => {
    setError(null)
    setOpen(false)
    setName('')
    setDescription('')
    setSensitiveInfo('')
  }
  const createRole = async data => request({ url: '/manager/personnel/role/create', method: 'post', data })
  const editRole = async (id, data) => request({ url: `/manager/personnel/role/${id}`, method: 'patch', data })
  const deleteRole = async id => request({ url: `/manager/personnel/role/${id}`, method: 'delete' })

  const createRoleMutation = useMutation({
    mutationFn: data => createRole(data),
    onSuccess: () => {
      queryClient.invalidateQueries(['personnel'])
      setSuccess(t('manager.team.editRoles.successAdd', { name }))
      emptyState()
    },
    onError: error => {
      setError(t('generic.error'))
      setSuccess(null)
    },
  })

  const editRoleMutation = useMutation({
    mutationFn: ([id, data]) => editRole(id, data),
    // mutationFn: (id, data) => role ? editRole(id,data) : createRole(id),
    onSuccess: () => {
      queryClient.invalidateQueries(['personnel'])
      setSuccess(t('manager.team.editRoles.successEdit', { name }))
      emptyState()
    },
    onError: error => {
      setError(t('generic.error'))
      setSuccess(null)
    },
  })

  const deleteRoleMutation = useMutation({
    mutationFn: deleteRole,
    onSuccess: () => {
      queryClient.invalidateQueries(['personnel'])
      setSuccess(t('manager.team.editRoles.successEdit', { name }))
      emptyState()
    },
    onError: error => {
      setError(t('generic.error'))
      setSuccess(null)
    },
  })

  const handleCreateEdit = async () => {
    const clean_name = name.trim()
    if (clean_name === '') {
      setError(t('manager.team.editRoles.emptyError'))
      setSuccess(null)
      return
    } if (clean_name.length > 20) {
      setError(t('manager.team.editRoles.nameError'))
      setSuccess(null)
      return
    }

    // Editing an existing role.
    if (role) {
      // Check that we are not switching to an already existing name
      if (personnel.some(item => item.name === clean_name && item.id !== role.id)) {
        setError(t('manager.team.editRoles.roleError'))
        setSuccess(null)
        return
      }
      const params = [role.id, { name: clean_name, description, sensitive_info }]
      editRoleMutation.mutate(params)
    }
    // Creating a new role
    else {
      // Check that we are not creating with an already existing name
      if (personnel.some(item => item.name === clean_name)) {
        setError(t('manager.team.editRoles.roleError'))
        setSuccess(null)
        return
      }
      const params = { name: clean_name, description, sensitive_info }
      createRoleMutation.mutate(params)
    }
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PencilSquareIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div className="my-4 text-center sm:mt-0 sm:ml-4 sm:text-left w-full mx-auto">
                    <Dialog.Title as="h3" className="text-lg mb-5 mt-2 text-blue-600 font-semibold leading-6">
                      {role ? t('manager.team.editRoles.editRole') : t('manager.team.editRoles.createRole')}
                      <p className="text-xs font-normal text-gray-600">{t('manager.team.editRoles.definition')}</p>
                    </Dialog.Title>
                    <div className="mt-2">
                      <div className="isolate -space-y-px rounded-md shadow-sm">
                        <div className="relative rounded-md rounded-b-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label htmlFor="name" className="block text-xs font-medium text-gray-900">
                            {t('generic.firstName')}
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder={t('manager.team.editRoles.namePlaceholder')}
                            value={name}
                            onChange={e => setName(e.target.value)}
                          />
                        </div>
                        <div className="relative rounded-md rounded-t-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label htmlFor="job-title" className="block text-xs font-medium text-gray-900">
                            {t('manager.team.editRoles.description')}
                          </label>
                          <input
                            type="text"
                            name="description"
                            id="description"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder={t('manager.team.editRoles.descriptionPlaceholder')}
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                          />
                        </div>
                        <div className="relative rounded-md rounded-t-none px-3 pt-2.5 pb-1.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-blue-600">
                          <label htmlFor="job-title" className="block text-xs font-medium text-gray-900">
                            {t('manager.team.editRoles.privateInfo')}
                          </label>
                          <input
                            type="text"
                            name="sensitive_info"
                            id="sensitive_info"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            placeholder={t('manager.team.editRoles.privateInfoPlaceholder')}
                            value={sensitive_info}
                            onChange={e => setSensitiveInfo(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {error ? <Alert success={false} text={error} /> : null}
                <div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4 justify-between">
                  <div>
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                      onClick={handleCreateEdit}
                    >
                      {t('generic.save')}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                      onClick={emptyState}
                      ref={cancelButtonRef}
                    >
                      {t('generic.cancel')}
                    </button>
                  </div>
                  {role
                    ? (
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-red-500 sm:ml-3 sm:mt-0 sm:w-auto"
                        onClick={() => deleteRoleMutation.mutate(role.id)}
                      >
                        {t('generic.eliminate')}
                      </button>
                    ) : null}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
