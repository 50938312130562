import React, { createContext, useContext, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import tutorials from './TutorialConfig'
import { request } from './axios'
import { useAuth } from './globalContext'

const TutorialContext = createContext()

export const fetchTutorials = () => ({
  queryKey: ['completedTutorials'],
  queryFn: () => request({ url: '/fetch_tutorial_status' }),
})

export function TutorialProvider({ children }) {
  const queryClient = useQueryClient()

  const [currentTutorial, setCurrentTutorial] = useState(null)
  const [isRunning, setIsRunning] = useState(true)
  const [stepIndex, setStepIndex] = useState(0)

  const { isLoggedIn, removeToken } = useAuth()

  const { data: completedTutorials, isLoading: isLoadingCompletedTutorials } = useQuery({
    ...fetchTutorials(),
    enabled: isLoggedIn,
    onError: e => {
      if (e?.response?.status === 401) {
        removeToken()
        queryClient.clear()
      }
    },
  })

  const { mutate: markTutorialAsCompleted } = useMutation(
    tutorialId => request({ method: 'POST', url: '/log_tutorial_journey', data: { tutorial_journey: tutorialId } }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['completedTutorials'])
      },
    },
  )

  // Start a specific tutorial by ID
  const startTutorial = async (tutorialId, params) => {
    if (!completedTutorials) {
      try {
        // Explicitly fetch or re-fetch the completedTutorials data
        await queryClient.fetchQuery(fetchTutorials().queryKey, fetchTutorials().queryFn)
      } catch (error) {
        // Handle any errors, e.g., by showing an error message
        console.error('Failed to fetch completed tutorials', error)
        return // Exit the function if the fetch fails
      }
    }
    if (tutorials[tutorialId] && !completedTutorials[tutorialId]) {
      setCurrentTutorial({ id: tutorialId, steps: tutorials[tutorialId], params })
      setStepIndex(0)
      setIsRunning(true)
    }
  }

  const next = tutorialId => {
    if (currentTutorial?.id === tutorialId) {
      setStepIndex(stepIndex + 1)
    }
  }

  // Stop a tutorial and mark it as completed
  const completeTutorial = tutorialId => {
    markTutorialAsCompleted(tutorialId)
    setIsRunning(false)
    setStepIndex(0)
    setCurrentTutorial(null)
  }

  const stepId = currentTutorial?.steps[stepIndex]?.id

  return (
    <TutorialContext.Provider value={{
      isRunning,
      currentTutorial,
      startTutorial,
      setStepIndex,
      stepIndex,
      stepId,
      completeTutorial,
      next,
    }}
    >
      {children}
    </TutorialContext.Provider>
  )
}

export const useTutorial = () => useContext(TutorialContext)

export default TutorialContext
