import { animated } from 'react-spring'

function AnimatedDiv({ children, style, ...props }) {
  const isChrome = true
  return isChrome ? (
    <animated.div style={style} {...props}>
      {children}
    </animated.div>
  ) : (
    <div {...props}>
      {children}
    </div>
  )
}

export default AnimatedDiv
