import { Dialog, Transition } from '@headlessui/react'
import { PlusIcon } from '@heroicons/react/24/outline'
import { useMutation } from '@tanstack/react-query'
import {
  Fragment, useReducer, useRef, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Alert from '../../components/FormFeedback/Alert'
import { request } from '../../utils/axios'

export default function NewItr({
  open,
  setOpen,
  itrId,
  itrType,
  itrEnd,
}) {
  const cancelButtonRef = useRef(null)
  const [error, setError] = useState(false)

  const end = new Date(itrEnd)

  // if itrType is month:
  const year = end.getUTCFullYear()
  const month = end.getUTCMonth() + 1
  const formattedMonth = `${year}-${month.toString().padStart(2, '0')}`

  // if itrType is custom
  const dayAfterEnd = new Date(end)
  dayAfterEnd.setUTCDate(end.getUTCDate())

  const cyear = dayAfterEnd.getUTCFullYear()
  const cmonth = dayAfterEnd.getUTCMonth() + 1 // Adding 1 to the month since it's zero-based
  const day = dayAfterEnd.getUTCDate()

  const formattedDate = `${cyear}-${cmonth.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`

  const formReducer = (state, action) => {
    switch (action.type) {
      case 'SET_FIELD':
        return { ...state, [action.field]: action.value }
      case 'SWITCH_TYPE':
        if (action.value === 'MONTH') {
          return {
            ...state,
            type: 'MONTH',
            // month: '',
            // start_day: '',
            // end_day: '',
          }
        }
        return {
          ...state,
          type: 'CALENDAR',
          // start_day: '',
          // end_day: '',
          // month: '',
        }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(formReducer, {
    type: itrType,
    month: itrType.toUpperCase() === 'MONTH' ? formattedMonth : '',
    start_day: formattedDate,
    end_day: '',
  })

  const handleInputChange = event => {
    if (event.target.type === 'date') {
      const dateString = event.target.valueAsDate
        ? event.target.valueAsDate.toISOString().substring(0, 10)
        : ''
      dispatch({
        type: 'SET_FIELD',
        field: event.target.name,
        value: dateString,
      })
    } else if (event.target.name === 'type') {
      dispatch({
        type: 'SWITCH_TYPE',
        value: event.target.value,
      })
    } else {
      dispatch({
        type: 'SET_FIELD',
        field: event.target.name,
        value: event.target.value,
      })
    }
  }

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { isLoading, isSuccess, mutate } = useMutation({
    mutationFn: params => request({ url: `manager/itrs/${itrId}/flow/next_itr`, method: 'post', data: params }),
    onSuccess: res => {
      navigate(`/manager/iteration/${res.id_itr}`)
    },
    onError: () => {
      setError(t('manager.chains.errorCreating'))
    },
  })

  const createItr = event => {
    event.preventDefault()
    const params = state.type.toUpperCase() === 'MONTH' ? {
      itr_type: state.type.toUpperCase(),
      month: state.month,
    } : {
      itr_type: state.type.toUpperCase(),
      start_day: state.start_day,
      end_day: state.end_day,
    }
    mutate(params)
  }

  function getCurrentMonth() {
    const currentDate = new Date()
    const year = currentDate.getFullYear()
    const month = String(currentDate.getUTCMonth() + 1).padStart(2, '0')
    return `${year}-${month}`
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <PlusIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                  </div>
                  <div className="my-4 text-center sm:mt-0 sm:ml-4 sm:text-left w-full mx-auto">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Crear siguiente iteración en el reparto
                    </Dialog.Title>
                    {error
                      ? <Alert text={error} />
                      : null}
                    <form onSubmit={createItr}>
                      <div className="mt-5 space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:border-t sm:pb-0">
                        <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6 justify-center">
                          <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900 self-center">
                            {t('manager.chains.period')}
                          </label>
                          <select
                            id="type"
                            name="type"
                            value={state.type}
                            onChange={handleInputChange}
                            className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                          >
                            <option value="MONTH">{t('generic.monthly')}</option>
                            <option value="CALENDAR">{t('generic.custom')}</option>
                          </select>
                        </div>
                        {state.type.toUpperCase() === 'MONTH' ? (
                          <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6 justify-center">
                            <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900 self-center">
                              {t('manager.chains.selectMonth')}
                            </label>
                            <input
                              type="month"
                              id="month"
                              name="month"
                              value={state.month}
                              onChange={handleInputChange}
                              min={getCurrentMonth()}
                              className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        ) : (
                          <>
                            <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6 justify-center">
                              <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900 self-center">
                                {t('manager.chains.selectStartDate')}
                              </label>
                              <input
                                type="date"
                                id="start_day"
                                name="start_day"
                                value={state.start_day}
                                max={state.end_day}
                                onChange={handleInputChange}
                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                            <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:py-6 justify-center">
                              <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900 self-center">
                                {t('manager.chains.selectEndDate')}
                              </label>
                              <input
                                type="date"
                                id="end_day"
                                name="end_day"
                                value={state.end_day}
                                onChange={handleInputChange}
                                min={state.start_day}
                                className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </>
                        )}
                      </div>
                      <div className="mt-5 sm:mt-4 sm:ml-10 sm:flex sm:pl-4">
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md disabled:bg-blue-200 bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                          disabled={isLoading || isSuccess || state.name === '' || (state.type.toUpperCase() === 'MONTH' && state.month === '') || (state.type.toUpperCase() === 'CALENDAR' && (state.start_day === '' || state.end_day === ''))}
                        >
                          {isLoading || isSuccess
                            ? (
                              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                              </svg>
                            ) : null}
                          Crear iteración
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:ml-3 sm:mt-0 sm:w-auto"
                          onClick={() => setOpen(false)}
                          ref={cancelButtonRef}
                        >
                          {t('generic.cancel')}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
