import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'

export default function Privacy() {
  const { t } = useTranslation()

  return (
    <div className="bg-white px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700 text-justify">
        <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          {t('privacy.title')}
        </h1>
        <p className="mt-6 leading-8">
          {t('privacy.content1')}
        </p>
        <div className="mt-10 max-w-2xl">
          <ul className="mt-8 max-w-xl space-y-8 text-gray-600">
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
              <span>
                <strong className="font-semibold text-gray-900">{t('privacy.infoCollection')}</strong>
                {' '}
                {t('privacy.infoCollectionDetails')}
              </span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
              <span>
                <strong className="font-semibold text-gray-900">{t('privacy.infoUse')}</strong>
                {' '}
                {t('privacy.infoUseDetails')}
              </span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
              <span>
                <strong className="font-semibold text-gray-900">{t('privacy.infoSharing')}</strong>
                {' '}
                {t('privacy.infoSharingDetails')}
              </span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
              <span>
                <strong className="font-semibold text-gray-900">{t('privacy.infoSecurity')}</strong>
                {' '}
                {t('privacy.infoSecurityDetails')}
              </span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
              <span>
                <strong className="font-semibold text-gray-900">{t('privacy.consentAcceptance')}</strong>
                {' '}
                {t('privacy.consentAcceptanceDetails')}
              </span>
            </li>
            <li className="flex gap-x-3">
              <CheckCircleIcon className="mt-1 h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
              <span>
                <strong className="font-semibold text-gray-900">{t('privacy.policyChanges')}</strong>
                {' '}
                {t('privacy.policyChangesDetails')}
              </span>
            </li>
          </ul>
        </div>
        <p className="mt-8">
          {t('privacy.contactInfo')}
        </p>
        <p className="mt-8">
          {t('privacy.acceptanceConfirmation')}
        </p>
        <p className="mt-8">
          {t('privacy.trustAssurance')}
        </p>
      </div>
    </div>
  )
}
