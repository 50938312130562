import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  NavLink, Navigate, Outlet, useOutletContext, useParams,
} from 'react-router-dom'

import Alert from '../components/FormFeedback/Alert'
import ExportToPDF from '../pages/Manager/IterationVerification/ExportToPDF/ExportToPDF'
import AssignShift from '../pages/Manager/IterationVerification/ShiftAssignment/AssignShift'
import { useAppOptions } from '../utils/AppOptionsContext'
import { request } from '../utils/axios'
import { requireApproved, requireManager } from '../utils/checks'
import { classNames } from '../utils/helpers'
import {
  getOutputsQuery, postExecChecksStatusQuery, shiftsQuery, slotSubsetsQuery,
} from '../utils/queries'

export const verificationLoader = queryClient => async ({ params }) => {
  const user = await requireManager(queryClient)
  await requireApproved(user)
  queryClient.invalidateQueries({ queryKey: ['iteration', params.id] })
  const query = getOutputsQuery(params.id)
  const runQuery = queryClient.getQueryData(query.queryKey)
    ?? (await queryClient.fetchQuery(query))
  const statusQuery = postExecChecksStatusQuery(params.id)
  const runStatusQuery = queryClient.getQueryData(statusQuery.queryKey)
    ?? (await queryClient.fetchQuery(statusQuery))
  const ssQuery = slotSubsetsQuery(params.id)
  const runSlotSubsetsQuery = queryClient.getQueryData(ssQuery.queryKey)
    ?? (await queryClient.fetchQuery(ssQuery))
  return { runQuery, runStatusQuery, runSlotSubsetsQuery }
}

export default function IterationVerificationLayout() {
  const [status, locked, errors, infeasible, nameAndDate] = useOutletContext()
  const { id } = useParams()
  const { data } = useQuery(getOutputsQuery(id))
  const { data: postExecChecksStatus } = useQuery(postExecChecksStatusQuery(id))
  const { task_status: checksStatus, up_to_date: updated } = postExecChecksStatus

  const { appOptions } = useAppOptions()

  const comments = data.itr_prefs.sort((a, b) => `${a.user.firstname} ${a.user.lastname}`.localeCompare(`${b.user.firstname} ${b.user.lastname}`)).filter(user => user.comment !== null)
    .map(user => (
      {
        comment: user.comment,
        name: `${user.user.firstname} ${user.user.lastname}`,
      }
    ))

  const parseWeekDay = start => {
    const weekdays = [t('calendars.sunday'), t('calendars.monday'), t('calendars.tuesday'), t('calendars.wednesday'), t('calendars.thursday'), t('calendars.friday'), t('calendars.saturday')]

    const date = new Date(start)
    const weekday = weekdays[date.getUTCDay()]
    const day = date.getUTCDate()

    return `${weekday} ${day}`
  }

  const queryClient = useQueryClient()

  useEffect(() => {
    if (checksStatus === 'sucess') {
      queryClient.invalidateQueries({ queryKey: ['iteration', id] })
    }
  }, [checksStatus, id, queryClient])

  const { data: allShifts } = useQuery(shiftsQuery(id))
  const { data: { slot_subsets: slotSubsets } } = useQuery(slotSubsetsQuery(id))

  const { t } = useTranslation()

  const tabs = [
    { name: t('manager.iterationLayouts.day'), href: 'day' },
    { name: t('manager.iterationLayouts.person'), href: 'person' },
    { name: t('manager.iterationLayouts.global'), href: 'all' },
    { name: t('manager.iterationLayouts.summary'), href: 'summary' },
    // { name: 'Fin de semana / Incompatibilidades', href: 'incompatibilidades', current: false },
  ]

  const [selectedParticipant, setSelectedParticipant] = useState(null)
  const [selectedSection, setSelectedSection] = useState(null)
  const [selectedSlot, setSelectedSlot] = useState(null)
  const [dataExport, setDataExport] = useState(null)

  const [open, setOpen] = useState(false)
  const [openExport, setOpenExport] = useState(false)
  const [success, setSuccess] = useState(false)

  const runChecks = useMutation({
    mutationFn: () => request({
      url: `manager/itrs/${id}/flow/run_post_exec_checks`,
      method: 'post',
    }),
    onSuccess: () => {
      // set error set succes??
      queryClient.invalidateQueries({ queryKey: ['postExecStatus', id] })
    },
    onError: () => {
    },
  })

  return (
    <>
      {status === 'verification' ? <Navigate to="../verification" replace /> : null}
      {
        open ? (
          <AssignShift
            open={open}
            setOpen={setOpen}
            setSuccess={setSuccess}
            slots={data.slots}
            itrPrefs={data.itr_prefs}
            shifts={data.shifts}
            participant={selectedParticipant}
            slot={selectedSlot}
            section={selectedSection}
          />
        ) : null
      }
      {
        openExport ? (
          <ExportToPDF open={openExport} setOpen={setOpenExport} data={dataExport} />
        ) : null
      }
      <div className="flex flex-row justify-end">
        {/* solo si los tests no estan al dia! otherwise decir q estan al dia.
        y si estan running poll y block screen */}
        {
          updated ? (
            <p className="p-2.5 my-3">
              {t('manager.shiftAssignment.postExecChecksUpToDate')}
            </p>
          ) : (
            <button
              className="rounded-md bg-teal-500 p-2.5 text-white my-3 flex self-end font-medium disabled:bg-teal-500/10"
              disabled={!appOptions.app_config.allow_post_exec_executions}
              onClick={() => runChecks.mutate()}
            >
              {t('manager.shiftAssignment.runPostExecChecks')}
            </button>
          )
        }
      </div>
      {!appOptions.app_config.allow_post_exec_executions ? <p className="text-end text-xs text-gray-600">{t('manager.iterationVerification.postExecChecksDisabled')}</p> : null}
      {success ? <Alert success text={success} /> : null}
      {comments.length > 0 ? (
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-blue-100 px-4 py-2 text-left text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75">
                <span>{t('generic.comments')}</span>
                <ChevronUpIcon
                  className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                <ul>
                  {comments.map((comment, index) => (
                    <li key={index}>
                      <strong>
                        {comment.name}
                        :
                      </strong>
                      {' '}
                      {comment.comment}
                    </li>
                  ))}
                </ul>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ) : null}
      <nav className="isolate flex divide-x rounded-lg shadow mb-6 mt-3" aria-label="Tabs">
        {tabs.map((tab, tabIdx) => (
          <NavLink
            key={tab.name}
            to={tab.href}
            className={classNames(
              '[&.active]:text-gray-900 [&.active]:border-b-2 [&.active]:border-solid [&.active]:border-b-blue-500 text-gray-500 hover:text-gray-700',
              tabIdx === 0 ? 'rounded-l-lg' : '',
              tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
              'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10',
            )}
          >
            <span>{tab.name}</span>
          </NavLink>
        ))}
      </nav>
      {
        (checksStatus === 'running' || checksStatus === 'enqueued') && (
          <div className="fixed top-0 w-full h-full bg-white bg-opacity-80 flex flex-col gap-3 justify-center items-center z-50">
            <svg className="animate-spin h-16 w-16 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-50" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
              <path className="opacity-100" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
            <p>{t('manager.shiftAssignment.runningPostExecChecks')}</p>
          </div>
        )
      }
      <Outlet context={[
        data, allShifts, setSelectedParticipant, setSelectedSection,
        setSelectedSlot, setOpen, setOpenExport, setDataExport, setSuccess, slotSubsets, nameAndDate,
      ]}
      />
    </>
  )
}
