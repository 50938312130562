/* eslint-disable no-unused-vars */
import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext, useParams } from 'react-router-dom'

import Alert from '../../../../components/FormFeedback/Alert'
import { request } from '../../../../utils/axios'
import SearchBar from '../../../../utils/searchBar'
import TableTabs from './TableTabs'

function RolesTable({
  roles, selectedRoles, participants,
  selectedParticipants, setSelectedParticipants,
  setSelectedRoles, dispatch, filteredRoles,
  setFilteredRoles, roles_itr, setRoles_itr,
  managedGroups,
}) {
  const { id } = useParams()

  const queryClient = useQueryClient()

  const { t } = useTranslation()

  const data = {
    base_points: 0,
    jokers: 0,
  }

  const managerOwnsRole = useCallback(role => managedGroups.includes(role.id_group), [managedGroups])

  const [error, setError] = useState(false)

  const includeRoleMutation = useMutation({
    mutationFn: idRole => request({ url: `/manager/itrs/${id}/itr_roles/${idRole}`, method: 'post', data }),
    onMutate: variables => {
      const idRole = variables
      const previousValue = selectedRoles
      const newValue = [...previousValue, String(idRole)]
      setSelectedRoles(newValue)
      return { previousValue, newValue }
    },
    onSuccess: (data, variables, context) => {
      setFilteredRoles(roles)
      queryClient.invalidateQueries(['itrRoles', id])
      queryClient.invalidateQueries({ queryKey: ['shifts', id] })
      queryClient.invalidateQueries({ queryKey: ['incompatibilities', id] })
      queryClient.invalidateQueries({ queryKey: ['slotSubsets', id] })
      queryClient.invalidateQueries({ queryKey: ['shiftClusters', id] })
    },
    onError: (error, variables, context) => {
      setSelectedRoles(context.previousValue)
      setError(t('manager.rolesConfig.errorInclude'))
    },
  })

  const unincludeRoleMutation = useMutation({
    mutationFn: idRole => request({ url: `/manager/itrs/${id}/itr_roles/${idRole}`, method: 'delete' }),
    onSuccess: (data, variables, context) => {
      const idRole = variables
      setSelectedRoles(selectedRoles.filter(r => r !== String(idRole)))
      dispatch({ type: 'RESET_PROPERTIES', payload: idRole })
      queryClient.invalidateQueries(['itrRoles', id])
      queryClient.invalidateQueries({ queryKey: ['shifts', id] })
      queryClient.invalidateQueries({ queryKey: ['incompatibilities', id] })
      queryClient.invalidateQueries({ queryKey: ['slotSubsets', id] })
      queryClient.invalidateQueries({ queryKey: ['shiftClusters', id] })
    },
    onError: error => {
      setError(t('manager.rolesConfig.errorInclude'))
    },
  })

  const includeParticipant = useMutation({
    mutationFn: idUser => request({ url: `/manager/itrs/${id}/user/${idUser}/include`, method: 'post' }),
    onSuccess: (_, variables) => {
      setSelectedParticipants(prevState => ({
        ...prevState,
        [variables]: 1,
      }))
    },
  })

  const removeParticipant = useMutation({
    mutationFn: idUser => request({ url: `/manager/itrs/${id}/user/${idUser}/remove`, method: 'post' }),
    onSuccess: (_, variables) => {
      setSelectedParticipants(prevState => ({
        ...prevState,
        [variables]: 0,
      }))
    },
  })

  const saveChangesMutation = useMutation(data => request({ url: `/manager/itrs/${id}/itr_roles`, method: 'patch', data }), {
    onSuccess: () => {
      setRoles_itr(roles)
      queryClient.invalidateQueries(['itrRoles', id])
      queryClient.invalidateQueries({ queryKey: ['shifts', id] })
      queryClient.invalidateQueries({ queryKey: ['incompatibilities', id] })
      queryClient.invalidateQueries({ queryKey: ['slotSubsets', id] })
      queryClient.invalidateQueries({ queryKey: ['shiftClusters', id] })
    },
    onError: error => error,
  })

  const saveChanges = async () => {
    const updatedRoles = []

    function isEqual(obj1, obj2) {
      return JSON.stringify(obj1) === JSON.stringify(obj2)
    }

    // Iterate over each role in roles and rolesItr
    for (let i = 0; i < roles.length; i++) {
      const role = roles[i]
      const itrRole = roles_itr[i]

      if (!isEqual(role, itrRole)) {
        // If the role has different values from its initial state, add it to the data object
        updatedRoles.push({
          id_user_role: itrRole.id,
          base_points: role.base_points,
          jokers: role.jokers,
        })
      }
    }
    saveChangesMutation.mutate({ data: updatedRoles })
  }

  const context = useOutletContext()
  const locked = context ? context[0] : false

  const included = role => selectedRoles.includes(String(role.id))
  const participates = participant => selectedParticipants[participant.id]

  return (
    <>
      <div className="flex items-center">
        <div className="flex-1">
          <SearchBar
            data={roles}
            setFilteredData={setFilteredRoles}
            placeholder={t('manager.rolesConfig.placeholder')}
            noResultsMessage={t('manager.rolesConfig.noResults')}
            filterFunction={
              (item, searchTerm) => item.role.toLowerCase().includes(searchTerm.toLowerCase())
            }
          />
        </div>
        <div className="my-4 sm:mt-0 sm:ml-16">
          {roles_itr !== roles
            ? (
              <button
                type="button"
                className="block rounded-md bg-blue-600 py-2 px-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                onClick={saveChanges}
              >
                {t('generic.saveChanges')}
              </button>
            ) : <p className="p-3 rounded-xl">{t('generic.savedChanges')}</p>}
        </div>
      </div>
      {
        error ? <Alert text={error} /> : null
      }
      {!locked && context
        ? <p className="text-center mx-auto mb-3 text-red-600">{t('manager.rolesConfig.locked')}</p>
        : null}
      {filteredRoles.length > 0 && (
        filteredRoles
          .slice()
          .sort((a, b) => {
            const aSelected = selectedRoles.includes(String(a.id))
            const bSelected = selectedRoles.includes(String(b.id))
            if (aSelected && !bSelected) {
              return -1
            }
            if (!aSelected && bSelected) {
              return 1
            }
            const aOwned = managerOwnsRole(a)
            const bOwned = managerOwnsRole(b)
            if (aOwned && !bOwned) {
              return -1
            }
            if (!aOwned && bOwned) {
              return 1
            }
            return 0
          })
          .map(role => (
            <div className="flex flex-row justify-center" id="rolesTable">
              <div className="relative px-4 sm:px-6">
                {included(role) && (
                  <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
                )}
                <label className="relative inline-flex items-center cursor-pointer align-middle">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={included(role)}
                    onChange={e => {
                      if (e.target.checked) {
                        includeRoleMutation.mutate(role.id)
                      } else {
                        unincludeRoleMutation.mutate(role.id)
                      }
                    }}
                    disabled={!locked}
                  />
                  <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                </label>
              </div>
              <Disclosure as="div" key={role.id} className="mb-2 w-full" defaultOpen={included(role)}>
                {({ open, close }) => (
                  <>
                    <Disclosure.Button disabled={!included(role)} className={`flex w-full z-0 justify-between rounded-lg ${!managerOwnsRole(role) && !included(role) ? 'bg-gray-100 hover:bg-gray-50' : 'bg-blue-100 hover:bg-blue-200 disabled:hover:bg-blue-200'} px-4 py-2 text-left text-sm font-medium text-blue-900  focus:outline-none focus-visible:ring focus-visible:ring-blue-500/75`}>
                      <div className="flex flex-row items-center gap-4">
                        <div>
                          <p className="font-medium">
                            {role.role}
                          </p>
                          <p className="text-xs">
                            {role.num_users ? t('manager.rolesConfig.numUsers', { users: role.num_users }) : null}
                          </p>
                        </div>
                      </div>
                      <ChevronUpIcon
                        className={`${open ? 'rotate-180 transform' : ''} h-5 w-5 text-blue-500`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                      {included(role) ? (
                        <>
                          <TableTabs role={role} dispatch={dispatch} locked={locked} />
                          <p className="mt-3 text-sm text-gray-800 font-medium">{t('manager.rolesConfig.participantsAddRemove')}</p>
                          <div className="grid grid-cols-2 mt-3">
                            {participants[role.id]?.map(participant => (
                              <div className="flex flex-row my-2">
                                <div className="relative px-4 sm:px-6">
                                  {Boolean(participates(participant)) && (
                                    <div className="absolute inset-y-0 left-0 w-0.5 bg-blue-600" />
                                  )}
                                  <label className="relative inline-flex items-center cursor-pointer align-middle">
                                    <input
                                      type="checkbox"
                                      className="sr-only peer"
                                      checked={participates(participant)}
                                      onChange={e => {
                                        if (e.target.checked) {
                                          includeParticipant.mutate(participant.id)
                                        } else {
                                          removeParticipant.mutate(participant.id)
                                        }
                                      }}
                                      disabled={!locked}
                                    />
                                    <div className="w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600" />
                                  </label>
                                </div>
                                <p>{participant.name}</p>
                              </div>
                            ))}
                          </div>
                        </>
                      ) : null}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          )))}
    </>
  )
}

export default RolesTable
