import {
  BuildingOfficeIcon, UserIcon,
  UsersIcon,
} from '@heroicons/react/20/solid'
import { useTranslation } from 'react-i18next'

const features = [
  {
    id: 'item1',
    icon: UserIcon,
  },
  {
    id: 'item2',
    icon: UsersIcon,
  },
  {
    id: 'item3',
    icon: BuildingOfficeIcon,
  },
]

export default function Features() {
  // Use useTranslation hook to access translations
  const { t } = useTranslation()

  return (
    <div className="bg-white pt-18 sm:pt-24">
      <div className="mx-auto max-w-7xl px-12">
        <p className="mt-12 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:text-center">
          {t('landing.features.title')}
        </p>
        <p className="mt-6 text-xl leading-8 text-gray-600 lg:text-center">
          {t('landing.features.subtitle')}
        </p>
        <div className="mx-auto mt-8 mb-12 max-w-2xl sm:mt-16 lg:mt-16 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map(feature => (
              <div key={feature.id} className="flex flex-col">
                <dt className="flex items-center gap-x-3 font-semibold leading-7 text-gray-900 text-2xl">
                  <feature.icon className="h-5 w-5 flex-none text-blue-600" aria-hidden="true" />
                  {t(`landing.features.items.${feature.id}.name`)}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="flex-auto">{t(`landing.features.items.${feature.id}.description`)}</p>
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
