import { Switch } from '@headlessui/react'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { requireApproved, requireManager } from '../../../utils/checks'
import { slotPrefsQuery } from '../../../utils/queries'
import PeopleRecordedData from './PeopleRecordedData'
import WeeklyRecordedData from './WeeklyRecordedData'

export const recordedDataLoader = queryClient => async ({ params }) => {
  const user = await requireManager(queryClient)
  await requireApproved(user)
  const query = slotPrefsQuery(params.id)
  return (
    queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query))
  )
}

export default function RecordedDataConfig() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  const [weekly, setWeekly] = useState(true)

  const { id } = useParams()
  const { data } = useQuery(slotPrefsQuery(id))

  const { t } = useTranslation()

  return (
    <div className="lg:flex lg:h-full lg:flex-col">
      <div className="flex flex-col items-center">
        <div className="pb-6 flex flex-row align-end mt-8 mx-auto gap-1">
          <Switch.Group as="div" className="flex items-center gap-3">
            <Switch.Label as="span" className="text-sm flex flex-row">
              <span className="font-medium text-gray-900">{t('manager.recordedData.perPerson')}</span>
            </Switch.Label>
            <Switch
              checked={weekly}
              onChange={setWeekly}
              className={classNames(
                weekly ? 'bg-blue-600' : 'bg-teal-500',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2',
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  weekly ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
                )}
              />
            </Switch>
            <Switch.Label as="span" className="text-sm flex flex-row">
              <span className="font-medium text-gray-900">{t('manager.recordedData.perDays')}</span>
            </Switch.Label>
          </Switch.Group>
        </div>
      </div>
      {weekly ? (
        <WeeklyRecordedData
          data={data}
        />
      ) : (
        <PeopleRecordedData
          itrPrefs={data.itr_prefs}
          slotPrefs={data.slot_prefs}
          shifts={data.shifts}
        />
      )}
    </div>
  )
}
